<template>
	<div>
		<div class="bar" :style="{ right: flag ? '0rem' : '-1.5rem' }">
			<ul>
				<li @click="flag_twoChange"><img src="@/assets/images/LuckyOrnament/rightpop/265.gif" alt="" /></li>
				<li @click="$router.push('/personalUser')"><img src="@/assets/function_icon/Personal Center.png" alt="" /></li>
				<!-- <li @click="$router.push('/MyPromotion')"><img src="@/assets/function_icon/Promotion management.png" alt="" /></li> -->
				<li @click="$router.push('/MyLog')"><img src="@/assets/function_icon/journal.png" alt="" /></li>
				<li @click="$router.push('/myBack')"><img src="@/assets/function_icon/knapsack.png" alt="" /></li>
				<!-- APP下载 -->
				<li @click="down_app"><img src="@/assets/function_icon/dowm.png" alt="" /></li>
				<!-- <li @click="windowOpenG"><img src="@/assets/function_icon/qq.png" alt="" /></li> -->
				<li v-clipboard:copy="qq_group_chat_number" v-clipboard:success="copy_qq_group" v-clipboard:error="onError">
							  <img src="@/assets/function_icon/qq.png" alt="" />
				</li>
				<li>
					<el-tooltip placement="left" :visible-arrow="false" popper-class="tool" effect="light">
						<!-- 2023-3-9 客服qq改为复制号码-->
						<!-- <div slot="content">
							<div v-for="(item, index) in qq_service" :key="index" class="hoverimgbox" @click="windowOpen(item)">{{ item.title }} 复制</div>
						</div> -->
						<div slot="content">
							<div
							v-for="(item, index) in qq_service"
							:key="index"
							class="hoverimgbox"
							v-clipboard:copy="item.qq_number" v-clipboard:success="copy"
							  v-clipboard:error="onError"
							@click="windowOpen(item)">
							QQ {{ item.title }} 复制
							</div>
						</div>
						<div><img src="@/assets/function_icon/customer service.png" alt="" /></div>
					</el-tooltip>
				</li>
			</ul>
			<div class="btn" @click="show()">
				<!-- {{ flag ? '>' : '<' }} -->
				<img src="@/assets/images/l.png" :class="flag ? 'rot' : ''" />
			</div>
		</div>
		<div class="red_bgc" v-if="flag_two">
			<div class="box">
				<div class="title">福利中心</div>
				<div class="close" @click="hidde_tow()">X</div>
				<div class="left hnk">
					<ul>
						<li @click="num = 1" :class="num == 1 ? 'bgc' : ''">红包福利</li>
						<li @click="num = 2" :class="num == 2 ? 'bgc' : ''">活动专区</li>
						<li @click="num = 3" :class="num == 3 ? 'bgc' : ''">拼手气</li>
					</ul>
				</div>
				<div class="right hnk">
					<div style="display: flex;  align-items: center;">
						<input class="hnk" type="text" placeholder="输入红包口令" v-model="password" />
						<button class="hnk" @click="click">领取红包</button>
					</div>

					<div class="ale">{{ text }}</div>

					<div class="red_one1" v-if="num == 1">
						<div class="red_content" v-for="(item, index) in red_code_data" :key="index">
							<span>{{ item.player_nike_name }}</span>
							获得了
							<span>{{ item.price }}</span>
							红包
						</div>
					</div>
					<div class="red_one" v-if="num == 2"><div class="red_content">暂无活动红包</div></div>
					<div class="red_one" v-if="num == 3"><div class="red_content">暂无手气红包</div></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { get_red, get_contact_us } from '@/api/index/personalCenter';
import { get_receiving_records, get_down_app_url } from '@/api/index/TopUp';
export default {
	data() {
		return {
			flag: false,
			num: 1,
			password: '',
			text: '',
			flag_two: false,
			red_code_data: [],
			qq_service: [],
			qq_group_chat_number: ''
		};
	},

	created() {
		if (sessionStorage.getItem('n7_token')) {
			this.$store.dispatch('getPlayerApi');
		}
	},

	mounted() {
		get_contact_us().then(res => {
			//console.log(res)
			this.qq_group_chat_number = res.data.qq_group_chat_number;
			this.qq_service = res.data.qq_service;
		});
	},

	methods: {
		copy() {
		  this.$message.success('复制成功')
		},
		copy_qq_group() {
		  this.$message.success('QQ群聊复制成功')
		},
		onError() {
		  this.$message.error('复制失败')
		},
		flag_twoChange() {
			this.get_receiving_record();
			this.flag_two = true;
			var mo = function(e) {
				e.preventDefault();
			};
			document.body.style.overflow = 'hidden';
			document.addEventListener('touchmove', mo, false); //禁止页面滑动
		},
		down_app() {
			this.$router.push('/download');
		},
		windowOpenG() {
			//群聊
			if (navigator.userAgent.indexOf('UCBrowser') > -1) {
				alert('UC浏览器暂不支持跳转,请更换浏览器');
			} else {
				//console.log(this.qq_group_chat_number)
				window.open('mqqapi://card/show_pslcard?src_type=internal&version=1&uin=' + this.qq_group_chat_number + '&card_type=group&source=external', '_self');
			}
		},
		show() {
			this.flag = !this.flag;
			if (this.flag) {
				document.querySelector('.bar').style.right = 0 + 'rem';
			}
		},
		hidde_tow() {
			this.password = '';
			this.flag_two = false;
			var mo = function(e) {
				e.preventDefault();
			};
			document.body.style.overflow = 'auto';
			document.addEventListener('touchmove', mo, true); //禁止页面滑动
		},
		click() {
			// 判断用户是否登录
			if (!sessionStorage.getItem('n7_token')) {
				this.hidde_tow();
				this.$store.dispatch('setLogSignRetrieve', true);
				return;
			}
			get_red({
				password: this.password
			}).then(res => {
				//console.log(res)
				if (res.status === 1) {
					this.$store.dispatch('getPlayerApi');
					this.get_receiving_record();
					this.$message({
						message: res.msg,
						type: 'success'
					});
					document.querySelector('.ale').style.color = 'green';
				} else {
					this.$message.error(res.msg);
					document.querySelector('.ale').style.color = 'red';
				}
			});
		},
		get_receiving_record() {
			get_receiving_records({}).then(res => {
				//console.log(res)
				if (res.status === 1) {
					this.red_code_data = res.data;
				} else {
					//this.$message.error(res.msg)
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped>
.hoverimgbox {
	border-radius: 0.1rem;
	padding: 0.1rem 0.2rem;
	font-size: 0.3rem;
	color: #000;
	margin-bottom: 0.3rem;
	background: #1ef0ed;
}

.hoverimgbox:last-child {
	margin-bottom: 0;
}

.rot {
	transform: rotate(180deg);
}

.red_one1 {
	margin-top: 0.1rem;
	color: rgb(0, 183, 255);
	text-align: left;
	font-size: 0.3rem;
}

.red_one {
	font-size: 0.1276rem;
	font-weight: 700;
	margin-top: 0.3rem;
	color: rgb(0, 183, 255);
}

.bar {
	transition: all 0.5s;
	position: fixed;
	top: 4rem;
	bottom: 0;
	margin: auto;
	z-index: 1000;

	.btn {
		// background-color: #212126;
		background: #1ef0ed;
		position: absolute;
		top: 2.5rem;
		left: -0.4rem;
		padding: 0.3rem 0.2rem;
		box-sizing: border-box;
		width: 0.4rem;
		font-size: 0.3rem;
		height: 1.6rem;
		border-radius: 0.2rem 0 0 0.2rem;
		border: 1px solid #1fc5cb;
		border-right: none;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 0.3rem;
			height: 0.3rem;
		}
	}

	ul {
		background-color: #212126;
		padding: 0.3rem 0;

		li {
			width: 1.5rem;
			margin-bottom: 0.2rem;

			img {
				width: 70%;
			}
		}
	}
}

.red_bgc {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #212126e0;
	z-index: 10000;

	.box {
		width: 10rem;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		height: 8rem;
		// background-color: red;
		background: url('@/assets/images/BoxShowPopup/background.png') no-repeat;
		background-size: 100% 100%;
		padding-top: 2rem;

		.title {
			position: absolute;
			top: 0.1rem;
			left: 0;
			right: 0;
			margin: auto;
			font-size: 0.5rem;
			font-weight: 700;
		}

		.close {
			width: 0.8rem;
			height: 0.8rem;
			line-height: 0.8rem;
			border-radius: 50%;
			position: absolute;
			top: 1rem;
			right: 0.3rem;
			font-size: 0.5rem;
			font-weight: 700;
			background-color: #1fc5cb;
		}

		.left {
			width: 2.5rem;
			height: 5.5rem;
			border-right: 0.05rem solid #1fc5cb;
			margin-right: 0.5rem;

			ul {
				// padding-right: 0.2rem;
				li {
					width: 2rem;
					padding: 0.1rem 0.2rem;
					border: 0.04rem solid #1fc5cb;
					border-radius: 0.2rem;
					margin-left: 0.2rem;
					margin-bottom: 0.3rem;
					font-size: 0.3rem;
				}
			}

			.bgc {
				background-color: #1fc5cb;
			}
		}

		.right {
			width: 5rem;
			height: 5.5rem;

			.ale {
				font-size: 0.3rem;
				// background-color: red;
				height: 0.4rem;
			}

			input {
				font-size: 0.3rem;
				width: 3.5rem;
				height: 0.7rem;
				border: none;
				border-radius: 0.1rem 0 0 0.1rem;
				padding-left: 0.15rem;
				outline: none;
			}

			button {
				height: 0.7rem;
				border: none;
				background-color: #1fc5cb;
				padding: 0 0.11rem;
				border-radius: 0 0.1rem 0.1rem 0;
				color: #fff;
				font-size: 0.3rem;
				white-space: nowrap;
			}
		}
	}
}
</style>
