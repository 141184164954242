<!-- 我的推广 -->
<template>
  <div class="MyPromotion">
     <div class="index_title">我的推广</div>
    <!-- 我的推广 -->
    <div class="back management">
      <!-- <div class="management-head">我的推广</div> -->
      <div class="managementC">
        <div class="cell">
          <div class="lefttitle">我的邀请码：</div>
          <input
            type="text"
            placeholder="请输入"
            v-model="myPayoff.invite_code"

          />
          <div class="btnbox">
            <div class="confirm one" v-clipboard:copy="myPayoff.invite_code"
            v-clipboard:success="copy"
            v-clipboard:error="onError" >复制</div>
              <!-- <div   :style="VPointer()"  class="confirm" style="margin:0">修改</div> -->
          </div>
        </div>
        <!-- <div class="bor"></div> -->
        <div class="postbox">
          <div class="lefttitle"> 我的推广链接：</div>
         <input type="text" v-model="invite_code_url " placeholder="请输入" />
          <div class="confirm" v-clipboard:copy="url + '?code=' + myPayoff.invite_code"
            v-clipboard:success="copy"
            v-clipboard:error="onError" >复制</div>
        </div>
      </div>
    </div>
    <!-- 我的收益 -->
    <div class="backIncome MyIncome">
      <div class="ados">
      <div class="income-head">我的推广</div>
      <div class="btn" @click="all_retrieve_commissionApi">全部提取</div>

      </div>
      <div class="incomeC">
        <div>推广人数</div>
        <div>邀请用户累计充值</div>
        <div>抽成比例</div>
        <div>累计收益</div>
        <div>可提取收益</div>
        <!-- <div @click="all_retrieve_commissionApi">提取</div> -->
      </div>
      <div class="incomeF">
        <div>{{ myPayoff.direct_push_num }}</div>
        <div>{{ myPayoff.direct_push_plat_price }}</div>
        <div>{{ myPayoff.commission_billie }}%</div>
        <div>{{ myPayoff.commission }}</div>
        <div>{{ myPayoff.no_commission }}</div>
      </div>
    </div>
    <div class="detail sticky-table">
      <table border="1" cellspacing="0" width="100%">
        <tr class="formH">
          <th>佣 &nbsp; 金</th>
          <th>状 &nbsp; 态</th>
          <th>时 &nbsp; 间</th>
        </tr>
        <tr class="formB" v-for="item in rowsList" :key="item.id">
          <td>{{ item.parent_player_commission }}</td>
          <td>
            {{ item.parent_player_commission_status === 1 ? '未提取' : '已提取' }}
          </td>
          <td>{{ item.update_time }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import {
  rechargeGet_list,
  retrieve_commission,
  all_retrieve_commission,
  get_extension_info
} from '@/api/index/personalCenter'
export default {
  name: 'MyPromotion',
  data() {
    return {
      page: 1,
      page_size: 15,
      page_count:0,
      url:'',
      invite_code_url:'',
      rowsList: [],
      recharge_commission_id: '', // 单一提取
      myPayoff: {} // 我的收益
    }
  },
  // props: {
  //   content: String
  // },
  // watch: {
  //   content() {
  //     if (this.content === 'MyPromotion') {
  //       this.rechargeGet_listApi()
  //       this.get_extension_infoApi()
  //     }
  //   }
  // },
  created() {
		if (sessionStorage.getItem('n7_token')) {
			this.$store.dispatch('getPlayerApi')
		}
    this.rechargeGet_listApi()
    this.get_extension_infoApi()
     this.url=window.location.origin+'/#'
  },
  mounted() {
    window.addEventListener('scroll', this.getbottom)
  },
  beforeDestroy(){
    window.removeEventListener('scroll', this.getbottom)
  },
  methods: {
    getbottom() {
      // 返回滚动条垂直滚动距离
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
      let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
      // 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      let numHeight = scrollTop + clientHeight
	 /* console.log("scrollHeight " + scrollHeight)
	  console.log("clientHeight " + clientHeight)
	  console.log("numHeight " + numHeight)
	  console.log("this.page " + this.page) */
      if (scrollHeight > clientHeight && numHeight > scrollHeight - 150) {
		  //console.log("this.page_count " + this.page_count)
        if (this.page < this.page_count) {
          //console.log(this.page , this.page_count);
          this.page++
          this.rechargeGet_listApi()
        }
      }
    },
      copy() {
      this.$message.success('复制成功')
    },
    onError() {
      this.$message.error('复制失败')
    },
    // 推广分红-列表
    rechargeGet_listApi() {
      rechargeGet_list({
        page: this.page,
        page_size: this.page_size
      }).then((res) => {
        this.total_rows = res.data.total_rows
        this.page_count = res.data.page_count
        // this.rowsList = res.data.rows
        res.data.rows.forEach(item=>{
          this.rowsList.push(item)
        })
      })
    },
    // 推广分红-单一提取
    retrieve_commissionApi() {
      retrieve_commission({
        recharge_commission_id: this.recharge_commission_id
      }).then((res) => {
        //console.log(res)
        this.rowsList = res.data.rows
        this.page_count=res.data.page_count
        //console.log(this.rowsList)
      })
    },
    // 推广分红-全部提取
    all_retrieve_commissionApi() {
      all_retrieve_commission().then((res) => {
        //console.log(res)
        if (res.status === 1) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.IDredact = false
		  this.$store.dispatch('getPlayerApi')
          this.get_player_infoApi()
		  this.initialization_page()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
	initialization_page(){
		this.page = 1
		this.page_size = 12
		this.rowsList = []
		this.rechargeGet_listApi()
	},
    // 推广分红-我的收益
    get_extension_infoApi() {
      get_extension_info({
        page: this.page,
        page_size: this.page_size
      }).then((res) => {
        this.myPayoff = res.data
        this.invite_code_url= this.url+ '/?code=' +  res.data.invite_code
      })
    }
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
@import '@/styles/variables.scss';
.ados{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn{
        width: 1.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        font-size: 0.3rem;
        color: $theme;
        font-weight: 700;
        border:1px solid $theme;
        border-radius: .3rem;
        padding: .1rem .1rem;
  }
}

// @import '';
.lefttitle{
  min-width: 2.3rem;
  max-width: 2.3rem;
  width: 2.3rem;
  text-align: left;
color: #1ef0ed;
}
.MyPromotion {
  margin-top: 20px;
  // .back {
  //   margin: 0 auto;
  //   background: url('@/assets/images/personalCenter/1.png') no-repeat;
  //   background-size: 100% 100%;
  //   width: 1000px;
  //   height: 100%;
  //   padding: 10px 0;
  //   margin-top: 10px;
  // }
  .management {
    .management-head {
      width: 120px;
      margin-right: 10px;
      color: $theme;
    }
    .managementC {
      padding: 0 .5rem;
      margin-bottom: .3rem;
       .cell {
        display: flex;
        margin-bottom: .5rem;
        padding-bottom:.1rem;
        align-items: center;
        font-size: .3rem;
        border-bottom:1px solid #1ef0ed;
         .btnbox{
          display: flex;
        }
        input {
          flex: 1;
          height:.7rem;
          background: none;
          outline: none;
          border: none;
          padding: 0 5px;
          color: #fff;
        }
        .one{
            position: relative;
            // right: .2rem;
          }
        .confirm {
          border: 2px $theme solid;
          border-radius: 20px;
          width: 1.3rem;
          height: .7rem;
          text-align: center;
          line-height: .7rem;
          margin-left: .2rem;

        }
      }
      .bor {
        width: 100%;
        height: .2rem;
        background: -webkit-linear-gradient(
          left,
          #000 -4%,
          $theme 50%,
          #000 100%
        );
      }
      .postbox {
        @extend .cell;
        margin-top: 5px;
        margin-bottom: 0;
      }
    }
  }
  .backIncome {
    // margin: 0 0 0 10px;
    // background: url('@/assets/images/personalCenter/3.png') no-repeat;
    // background-size: 100% 100%;
    width: 9.8rem;
    height: 100%;
    padding: 10px 0;
    margin: auto;
    margin-top: 10px;
  }
  .MyIncome {
    // display: flex;
    // align-items: center;
    .income-head {
      width: 2rem;
      font-size: 0.4rem;
      font-weight: 700;
      // background-color: beige;
      // margin-right: 10px;
      margin: 0.2rem 0;
      color: $theme;
    }
    .incomeC {
      margin-top: 0.4rem;
      // margin-bottom: 0.4rem;
      display: flex;
      border-bottom: 0.02rem solid $theme;
      padding-bottom: 0.3rem;
      justify-content: space-between;
      div {
        width: 1.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        font-size: 0.3rem;
        color: $theme;
        font-weight: 700;
      }
      // div:nth-child(1) {
      //   width: 85px;
      // }
      // div:nth-child(2) {
      //   width: 145px;
      // }
      // div:nth-child(3) {
      //   width: 90px;
      // }
      // div:nth-child(4) {
      //   width: 100px;
      // }
      // div:nth-child(5) {
      //   width: 100px;
      // }
      div:nth-child(6) {
        padding: 0 0.2rem;
        margin-top: 0.2rem;
        height: 0.6rem;
        border: 0.02rem $theme solid;
        border-radius: 0.3rem;
        // margin: 5px 0 0 10px;
      }
    }
    .incomeF {
      display: flex;
      padding-bottom: 0.3rem;
      justify-content: space-between;
      padding-top: 0.2rem;
      font-size: .3rem;
      // width: 8.2rem;
      border-bottom: 0.02rem solid $theme;
      div {
        width: 1.5rem;
      }
    }
  }
}
// 表格
.detail {
  margin: .1rem;
  // margin-top: 9px;
  padding-right: 9px;
  overflow-y: scroll;
  //height: 10.5rem;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: none;
    border-radius: 10px;
  }
  /* scroll轨道背景 */
  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: rgba(216, 216, 216, 0);
  }
  /* 滚动条中能上下移动的小块 */
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
    background-color: rgb(119, 117, 117);
  }
  .formH {
    th {
      height: .8rem;
      font-size: .3rem;
      border-top: 1px #808080 solid;
    }
  }
  .formB {
      font-size: .3rem;
    td {
      height: .8rem;
      &:nth-child(5) {
        color: $theme;
      }
    }
  }
}

.sticky-table th {
  // position: sticky;
  top: 0; /* 首行永远固定在头部  */
  //background-color: $hoverText; /*设置表头背景色*/
}

.sticky-table th:first-child {
  z-index: 2; /*表头的首列要在上面*/
}
</style>
