<!-- 开箱 -->
<template>
  <div class="body">
    <div :class="[{ introduced: AnimationsPopup },{ introduced: GamePlayShowModal }]"></div>
    <BodyBackground/>
    <div class="body-background">
      <img
        class="body-background-img"
        src="https://oss.N7buff.com/mobile/assets/images/cdkBox/top_bak_cdk.png"
        alt=""
      />
    </div>
    <div class="free-title">
      <img
        class="free-title-off"
        src="@/assets/images/2title.png"
        alt=""
      />
      <div
        class="free-title-off-myOne "
        @click="$router.push({path:'/GradeBox'})"
      >
        等级箱子
      </div>
      <div
        class="free-title-off-myTow free-title-off-tow"

      >
        口令箱子
      </div>
      <div class="paly-text" @click="GamePlay">玩法介绍</div>
    </div>
    <div class="InBox">
      <swiper :options="mySwiperOption" class="cdk-ul">
        <swiper-slide v-for="(item,index) in cdkBoxList" :key="index" class="cdk-li">
          <div class="cdk-li-back" @click="CaseDetailsTo(item)" :style="VPointer()">
            <div class="cdk-li-back-box">
              <img :src="item.img_active" alt=""/>
            </div>
            <div
              class="cdk-li-back-main"


            >
              <img :src="item.img_main" alt=""/>
            </div>
          </div>
          <div class="cdk-body-mainUl-li-name">{{ item.name }}</div>
        </swiper-slide>
      </swiper>
      <div class="swiper-button-prev" :style="VPointer()" slot="button-prev"></div>
      <div class="swiper-button-next" :style="VPointer()" slot="button-next"></div>
    </div>
    <div class="body-main">
      <!-- 主体物 -->
      <div class="cdk-main-openBox">
        <img class="cdk-main-openBox-box" :src="boxData.img_main" alt=""/>
        <img class="cdk-main-openBox-res" :src="boxData.img_active" alt=""/>
      </div>
      <!-- 开启按钮 -->
<!--      <img-->
<!--        @click="open"-->
<!--        class="body-main-openBut"-->
<!--        src="@/assets/images/cdk-btn.png"-->
<!--        alt=""-->
<!--        :style="VPointer()"-->
<!--      />-->
      <div class="cdk-inputBox">
        <input type="text" placeholder="请输入密钥" v-model="password" >
        <div class="cdk-inputBox-btn" @click="open()" :style="VPointer()">立即开启</div>
      </div>
      <!-- v-show="AnimationsPopup && skinsList" -->
      <BoxShowPopup
        class="BoxShowBodyOpenTake"
        v-show="AnimationsPopup && skinsList"
        :visible.sync="AnimationsPopup"
        :count="count"

        :resultList="resultList"
        :show_prize="show_prize.show"
      />

      <!-- 单开 -->
      <transverse v-if="showAnimations" :goodsList="boxData.skins" :prize_data="prize_data" @end="end" ref="transverse"
                  @finishCallback="finishCallback"></transverse>

      <!-- 多开 -->
      <portrait v-if="showAnimations" :goodsList="boxData.skins" :prize_data="prize_data" @end="end"
                @finishCallback="finishCallback" ref="portrait"></portrait>

    </div>
    <!--   玩法介绍   -->
    <div
      class="fight-body-battleground-but-ShowModal"
      v-show="GamePlayShowModal"
    >
      <GamePlay :visible.sync="GamePlayShowModal"/>
    </div>
  </div>
</template>

<script>
// import BoxShowPopup from '../../components/boxShow/BoxShowPopup.vue'

import transverse from './transverse.vue'
import portrait from './portrait.vue'

import BoxShowPopup from '../../components/cdkBoxShow/BoxShowPopup.vue'
import BodyBackground from '../../components/BodyBackground.vue'
import HomeBodyAlert from '../../components/home/Home_alert.vue'
import {open_box, get_open_box_record} from '@/api/index/boxCorrelation'
import bus from '@/utils/bus.js'
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import '@/assets/css/swiper.min.css';
import {open_cdk_box} from "@/api/index/cdk";
import GamePlay from "@/views/cdk/components/GamePlay.vue";
export default {
  name: 'BoxShowBody',
  components: {
    GamePlay,
    transverse,
    portrait,
    BoxShowPopup,
    BodyBackground,
    HomeBodyAlert,
    swiper,
    swiperSlide
  },
  data() {
    return {
      count: 1, // 箱子个数
      showAnimations: true, // 动画
      AnimationsPopup: false, // 弹出层
      //audio: new Audio(require('@/assets/audio/滚动出枪.mp3')), //过渡声音
      current: new Audio('https://oss.n7buff.com/mobile/audio/roll9.mp3'), //过程声音
      show_audio: new Audio('https://oss.n7buff.com/mobile/audio/Shoot.mp3'), //过程声音
      prize_data: {
        //奖品信息
        show: '',
        list: []
      },
      show_prize: {
        //奖品信息(展示所用，别问什么写两个)
        show: false,
        list: []
      },
      recordList: [], // 开箱历史
      resultList: [], // 结果
      skinsList: [],// 动画总数据,
      showSounds: true,
      mySwiperOption: {
        slidesPerView: 3,
        spaceBetween: 0,
        slidesPerGroup: 3,
        pagination: {
          el: '.swiper-pagination', //与slot="pagination"处 class 一致
          clickable: true, //轮播按钮支持点击
        },
        //自动播放
        // autoplay: {
        //   delay: 4000,
        //   disableOnInteraction: false
        // },
        //循环
        loop:true,
        loopFillGroupWithBlank: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      },
      activeBox: {},
      password: '',
      GamePlayShowModal: false,
    }
  },
  props: {boxData: Object, cdkBoxList:Array},
  watch: {
    cdkBoxList() {
      if (this.cdkBoxList.length > 0) {
        this.$emit('getBoxDataList', this.cdkBoxList[0].id)
        this.activeBox = this.cdkBoxList[0]
      }
    },
    // 监听弹出层的变化
    AnimationsPopup() {

      if (!this.AnimationsPopup) {
        //this.audio.pause()
      }
    }
  },
  beforeDestroy() {
    bus.$off('colseInterval')
    bus.$off('openInterval')
  },
  created() {
    // this.getOpenBoxRecordApi()
    /*   open_box({
          box_id: this.$route.query.id,
          total: this.count
        }).then((res) => {
          this.resultList = res.data
        }) */
  },
  methods: {
    // 开箱动画数据
    // async getBoxDataApi() {
    //   const res = await get_box_data({ id: this.$route.query.id })
    //   this.skinsList = res.data.skins
    //   console.log(this.skinsList)
    // },
    // 开箱记录
    // getOpenBoxRecordApi() {
    //   get_open_box_record().then((res) => {
    //     this.recordList = res.data
    //   })
    // },
    // 玩法介绍弹出
    GamePlay() {
      this.GamePlayShowModal = true
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', mo, false) //禁止页面滑动
    },
    CaseDetailsTo(item){
      this.activeBox = item
      this.$emit('getBoxDataList', item.id)
    },
    // 点击开启按钮
    open() {
      //console.log("动画开启 "+this.showAnimations)
      // 判断用户是否登录
      if (!sessionStorage.getItem('n7_token')) {
        this.$store.dispatch('setLogSignRetrieve', true)
        return
      }
      /* clearInterval(this.timer)
      return */
      open_cdk_box({
        cdk_box_id: this.activeBox.id,
        password: this.password
      }).then((res) => {
        // console.log(res.data);
        if (res.status === 1) {
          this.$store.dispatch('getPlayerApi')
          this.resultList = res.data
          if (this.showAnimations) {
            //动画开启
            this.Dijah = true
            bus.$emit('colseInterval')
            // this.current.play()
            if (this.resultList.length > 1) {
              this.$refs.portrait.Arraysplicing(this.resultList, 'portrait')
            } else {
              this.$refs.transverse.Arraysplicing(this.resultList, 'transverse')
            }
          } else {
            //动画关闭
            this.show_audio.play()
            this.$nextTick(() => {
              this.show_prize = {
                show: true,
                list: this.resultList
              }
              this.AnimationsPopup = true
            })
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 数量设置
    countImg(count) {
      this.count = count
    },
    // 声音开关
    showSound() {
      this.showSounds = !this.showSounds
    },
    // 动画开关
    showAnimation(status) {
      if (this.showAnimations) {
        this.showAnimations = false
        if (!status) {
          this.end()
        }

      } else {
        this.showAnimations = true
      }
    },
    //子组件动画结束
    end() {
      this.show_audio.play()
      //this.show_audio.play()
      //this.showAnimations = false
      this.AnimationsPopup = true
      //this.Dijah = false //电池光效复原
      this.show_prize = {
        show: true,
        list: this.prize_data.list
      }
      this.prize_data = {
        show: '',
        list: []
      }
      bus.$emit('openInterval')

    },
    finishCallback(data, str) {
      //console.log('data, str: ', data, str)
      setTimeout(() => {
        this.$nextTick(() => {
          this.prize_data = {
            show: str,
            list: data
          }
        })
      }, 550)
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/BoxShowBody.scss';
@import '@/styles/BlindBoxFight.scss';
@import '@/styles/FreeSkinBody.scss';

.introduced {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 90;
  transition: all 0.15s linear;
}

.BoxShowBodyOpenTake {
  position: fixed;
  z-index: 91;
  left: 0;
  right: 0;
  // background-color: aqua;
}
.body-background-img{
  width: 140%;
  margin-left: -20% !important;
}
.body-background{
  margin-top: 0;
  top: 44%;
  //height: 10rem;
}
.InBox{
  padding: 0 0.5rem;
  position: relative;
  width: 8.71745rem;
  margin: 0 auto;
  //margin-top: 30px;
}
.cdk-main-openBox{
  &-box {
    height: 3.8rem;
    position: absolute;
    top: 4.2rem;
    left: 0.1rem;
    right: 0;
    margin: auto;
    z-index: 1;
  }

  &-res {
    // height: 160px;
    width: 3rem;
    position: absolute;
    top: 4.2rem;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 2;
  }
}
.body-main-openBut{
  bottom: 3.3rem;
}
.cdk-inputBox{
  width: 80%;
  height: 117px;
  background: url("https://oss.N7buff.com/mobile/assets/images/cdkBox/cdk-input.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 71%;
  left: 50%;
  transform: translate(-49%, 52%);
  padding-left: 51px;
  input{
    width: 70%;
    border: none;
    background: transparent;
    font-size: 56px;
    color: #FFFFFF;
    outline: none;
    &::placeholder {
      color: #FFFFFF;
    }
  }
  .cdk-inputBox-btn{
    width: 222px;
    height: 100%;
    position: absolute;
    right: 0;
    font-size: 40px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
//  轮播图
.cdk-ul{
  margin-top: 16px;
  //padding: 0 100px;
  .cdk-li{
    display: flex;
    flex-direction: column;
    align-items: center;
    &-back {
      background: url('@/assets/images/fightImages/Photoefficiency.png') no-repeat;
      width: 3rem;
      height: 2.2rem;
      background-size: 100%;
      position: relative;
      padding-top: 40px;
      &-box {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translate(-50%, -20%);
        & > img {
          width: 2.3rem;
        }
      }
      &-main {
        & > img {
          width: 2.7rem;
        }
      }
    }
  }
}
.swiper-button-prev,.swiper-button-next{
  width: 72px;
  height: 99px;
  background-image: url("https://oss.N7buff.com/mobile/assets/images/cdkBox/swiper-left.png");
  background-size: 100%;
  top:35% ;
  left: 0;
}
.swiper-button-next{
  background-image: url("https://oss.N7buff.com/mobile/assets/images/cdkBox/swiper-right.png");
  background-size: 100%;
  top:35% ;
  left: auto;
  right: 0;
}
.free-title{
  position: relative;
}
.BoxShowBodyOpenTake {
  //position: fixed;
  //z-index: 91;
  //top: 15vh;
  //left: 0;
  // background-color: aqua;
}
.fight-body-battleground-but-ShowModal{
  z-index: 99;
  position: fixed;
  //margin-top: 10vh;
}
::v-deep .int-box {
  z-index: 99;
  margin-top: 10vh;
}

.paly-text {
  width: 240px;
  height: 100px;
  background: url("@/assets/images/GradeBox/paly.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #4EFEFE;
  cursor: pointer;
  margin-top: 18px;
  position: absolute;
  right: 0;
  top: 0;
}
</style>
