<!-- 登录 -->
<template>
  <div class="">
    <div class="login">
      <div class="close" @click="closes">
        <img src="@/assets/images/fightImages/close.png" alt="" />
      </div>
      <div class="login-box">
        <div
          :class="[
            { 'login-but': this.login == 'logIn' },
            { 'sigin-but': this.login == 'sigIn' }
          ]"
          v-if="login !== 'retrievePassword'"
        >
          <div class="login-log" @click="logins">
            登录
          </div>
          <div class="login-sig" @click="sigins">
            注册
          </div>
        </div>
        <div class="retrievePasswordTitle" v-if="login === 'retrievePassword'">
          <img src="@/assets/images/userWidget/找回密码.png" alt="" />
        </div>
        <div class="zh">
          <div
            v-if="this.login === 'logIn'"
            class="passwordLogin"
            :class="!this.cellVerification ? 't' : ''"
            @click="passwordLogin"
          >
            密码登录
          </div>
          <div
            v-if="this.login === 'logIn'"
            class="phoneLogin"
            :class="this.cellVerification ? 't' : ''"
            @click="phoneLogin"
          >
            手机验证码登录
          </div>

          <!--   <div
            v-if="this.login === 'sigIn'"
            class="phoneLogin"
            :class="this.EmailBack ? 't' : ''"
            :style="VPointer()"
            @click="EBack"
          >
            邮箱注册
          </div> -->
          <!-- <div
            v-if="this.login === 'sigIn'"
            class="passwordLogin"
            :class="!this.EmailBack ? 't' : ''"
            :style="VPointer()"
            @click="cellSig"
          >
            手机号注册
          </div> -->
          <!--   <div
            v-if="this.login === 'retrievePassword'"
            class="phoneLogin"
            :class="this.EmailBack ? 't' : ''"
            :style="VPointer()"
            @click="EBack"
          >
            邮箱验证码找回
          </div> -->
          <!--  <div
            v-if="this.login === 'retrievePassword'"
            class="passwordLogin"
            :class="!this.EmailBack ? 't' : ''"
            :style="VPointer()"
            @click="cellBack"
          >
            手机验证码找回
          </div> -->
        </div>
        <!--  -->
        <form class="elForm">
          <div
            class="cellNumber"
            v-if="
              !this.EmailBack &&
              (this.login === 'sigIn' ||
                this.login === 'logIn' ||
                this.login === 'retrievePassword')
            "
          >
            <input
              type="text"
              placeholder="请输入手机号"
              v-model="logInForm.username"
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
            <img src="@/assets/images/userWidget/手机.png" alt="" />
          </div>
          <div class="cellNumber" v-if="this.EmailBack">
            <input
              type="text"
              placeholder="请输入邮箱"
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
            <img src="@/assets/images/userWidget/邮箱.png" alt="" />
          </div>
          <!-- 登录密码 -->
          <div
            class="password"
            v-if="!cellVerification && this.login === 'logIn'"
          >
            <input
              type="password"
              placeholder="请输入密码"
              v-model="logInForm.password"
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
            <img class="a" src="@/assets/images/userWidget/锁.png" alt="" />
          </div>
          <!-- 获取登录验证码 -->
          <div
            class="password getcode"
            v-if="cellVerification && this.login === 'logIn'"
          >
            <input
              type="text"
              v-model="logInForm.code"
              placeholder="请输入验证码"
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
            <el-button
              round
              size="mini"
              class="b"
              :loading="logInLoading"
              :disabled="logInDisabled"
              :style="VPointer()"
              @click="openCode"
              ><span v-if="!logGetCode">获取验证码</span>
              <span v-else>{{ logInCountDown }}后再次获取</span>
            </el-button>
          </div>
          <!-- 获取注册验证码 -->
          <div class="password getcode" v-if="this.login === 'sigIn'">
            <input
              type="text"
              placeholder="请输入验证码"
              v-model="logInForm.code"
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
            <el-button
              round
              size="mini"
              class="b"
              :loading="sigInLoading"
              :disabled="sigInDisabled"
              :style="VPointer()"
              @click="openCodes"
              ><span v-if="!sigGetCode">获取验证码</span>
              <span v-else>{{ sigInCountDown }}后再次获取</span>
            </el-button>
          </div>
          <!-- 获取找回密码手机验证码 -->
          <div class="password getcode" v-if="this.login === 'retrievePassword'">
            <input
              type="text"
              placeholder="请输入验证码"
              v-model="logInForm.code"
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
<!--            <img class="a" src="@/assets/images/userWidget/验证码.png" alt="" />-->
            <el-button
              round
              size="mini"
              class="b"
              :loading="retrieveInLoading"
              :disabled="retrieveInDisabled"
              :style="VPointer()"
              @click="openCode"
              ><span v-if="!retrieveGetCode">获取验证码</span>
              <span v-else>{{ retrieveInCountDown }}后再次获取</span>
            </el-button>
          </div>
          <div
            class="password"
            v-if="this.login === 'retrievePassword' || this.login === 'sigIn'"
          >
            <input
              type="password"
              placeholder="请输入不超过16位的密码"
              autocomplete="off"
              v-model="logInForm.password"
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
            <img class="a" src="@/assets/images/userWidget/锁.png" alt="" />
          </div>
          <div
            class="password"
            v-if="this.login === 'retrievePassword' || this.login === 'sigIn'"
          >
            <input
              type="password"
              autocomplete="off"
              placeholder="请再次输入密码"
              v-model="logInForm.password_again"
            />
            <img
              class="a"
              src="@/assets/images/userWidget/锁.png"
              alt=""
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
          </div>
          <div class="password" v-if="this.login === 'sigIn'">
            <input
              type="text"
              placeholder="请输入昵称"
              v-model="logInForm.nike_name"
            />
            <img class="a" src="@/assets/images/userWidget/昵称.png" alt="" />
          </div>
          <div class="password" v-if="this.login === 'sigIn'">
            <input
              type="text"
              placeholder="请输入邀请码"
              v-model="logInForm.invite_code"
              oninput="value=value.replace(/[\u4e00-\u9fa5]{0,}$/g,'')"
            />
            <img class="a" src="@/assets/images/userWidget/邀请码.png" alt="" />
          </div>
        </form>
        <div class="retrievePassword" v-if="this.login === 'logIn'">
          <div class="senPwd" v-if="!this.cellVerification">
            <input type="checkbox" v-model="isSendPwd" :style="VPointer()" />
            <div>记住密码</div>
          </div>
          <span :style="VPointer()" style="font-size: .3rem;" @click="retrieveP"> 忘记密码 </span>
        </div>
        <div style="color: red;font-size: .3rem" v-if="checkedHint">请勾选用户协议和政策</div>
        <!-- <div><img src="@/assets/images/userWidget/steam.png" alt="" /></div>
        <div>steam一键登录</div> -->
        <div v-if="this.login === 'logIn'" class="enterLog">
          <div
            class="goLoginImg"
            :style="VPointer()"
            @click="LogNow"
          > 登 录 </div>
        </div>
        <div v-if="this.login === 'sigIn'" class="enterSig">
          <div
            class="goLoginImg"
            :style="VPointer()"
            @click="postRegisterApi"
          > 注 册 </div>
          <div class="goLogin" :style="VPointer()" @click="goLogin">去登录</div>
        </div>
        <div v-if="this.login === 'retrievePassword'" class="enterSig">
          <div
            class="goLoginImg"
            :style="VPointer()"
            @click="Reset_passwordApi"
          > 确 定</div>
          <div class="goLogin" :style="VPointer()" @click="goLogin">去登录</div>
        </div>
        <div class="agreement">
          <input type="checkbox" v-model="checked" :style="VPointer()" />
          我已经满18岁，并阅读同意
          <span :style="VPointer()" @click="goHelp(3)">《用户协议》</span>
          和
          <span :style="VPointer()" @click="goHelp(2)">《隐私政策》</span>
          ，承诺理性消费。
        </div>
      </div>
    </div>
    <Vcode :show="isShow" @success="onSuccess"   />

  </div>
</template>

<script>
import Vcode from 'vue-puzzle-vcode'
import { get_player } from '@/api/index/personalCenter'
import AntiShake from "@/utils/AntiShake"
import {
  postLogin,
  postRegister,
  postRegisterGet_code,
  postLoginGet_code,
  postReset_password,
  logincode_login,
get_login_code
} from '@/api/index/logIn'
import { isMobile, isSensitiveWord } from '@/utils/validate'
export default {
   components: {
    Vcode,
  },
  name: 'logIn',
  data() {
    return {
      isShow:false,
      cellVerification: false, // 手机验证码登录
      EmailBack: false, // 邮箱验证码找回
      login: 'sigIn', // 显示登录或注册
      checked: false, // 用户协议
      checkedHint: false, // 用户协议提示

      // 登录获取验证码
      logGetCode: false, // 获取验证码倒计时
      logInCountDown: null, // 验证码倒计时
      logInTimer: null, //设置计时器,
      logInLoading: false,
      logInDisabled: false,
      // 注册获取验证码
      sigGetCode: false, // 获取验证码倒计时
      sigInCountDown: null, // 验证码倒计时
      sigInTimer: null, //设置计时器,
      sigInLoading: false,
      sigInDisabled: false,
      // 获取找回密码手机验证码
      retrieveGetCode: false, // 获取验证码倒计时
      retrieveInCountDown: null, // 验证码倒计时
      retrieveInTimer: null, //设置计时器,
      retrieveInLoading: false,
      retrieveInDisabled: false,
      isSendPwd:false,
      // 登录注册
      logInForm: {
        username: '', //账号
        password: '', //密码
        password_again: '', // 二次密码
        code: '', // 验证码
        nike_name: '', // 昵称
        invite_code: '' // 邀请码
      }
    }
  },
  props: {
    homeLogin: String
  },
  watch: {
    '$store.state.logSignRetrieve'() {
      this.login = this.homeLogin
    this.getStorage()
    }
  },
  created() {
    this.getStorage()
	if(this.$route.query.code){
		this.logInForm.invite_code = this.$route.query.code
	}
  },
  methods: {
    getStorage(){
    if(localStorage.getItem('login')){
          let data=JSON.parse(localStorage.getItem('login'))
          this.logInForm.username=data.username
          this.logInForm.password=data.password
		  this.isSendPwd = true
      }
    },
    goHelp(e) {
      this.$router.push('/Helpcenter?id='+e)
      this.$store.dispatch('setLogSignRetrieve', false)
    },
     onSuccess() {
		 console.log("jieshushusuhsu " + this.cellVerification);
      if (this.cellVerification && this.login === 'logIn') {
        // 手机验证码登录
        this.logInGetCodeApi()
      } else if (this.login === 'sigIn') {
        this.sigInGetCodeApi()
      } else if (this.login === 'retrievePassword') {
        this.retrieveInGetCodeApi()
      }
      this.isShow = false
    },
    openCode() {
      if (this.logInForm.username) {
        this.isShow = true
      }else{
        this.$message({
          message:"请输入手机号",
          type:'error'
        });
      }
    },
		openCodes() {
		  if (this.logInForm.username) {
		    this.isShow = true
		  }else{
		    this.$message({
		      message:"请输入手机号",
		      type:'error'
		    });
		  }
		},
    // 获取个人余额信息
    async get_playerApi() {
      const res = await get_player()
      //console.log(res)
    },
    // 获取登录短信验证码
    logInGetCodeApi() {
      this.logInLoading = true
      get_login_code({
        username: this.logInForm.username
      }).then((res) => {
        //console.log(res.status)
        if (res.status === 1) {
          this.logInDisabled = true
          //console.log(res)
          this.logInLoading = false
          let TIME_COUNT = 60
          this.logInCountDown = TIME_COUNT
          this.logGetCode = true
          this.logInTimer = setInterval(() => {
            if (this.logInCountDown > 0 && this.logInCountDown <= TIME_COUNT) {
              this.logInCountDown--
            } else {
              clearInterval(this.logInTimer)
              this.logInTimer = null
              this.logGetCode = false
              this.logInDisabled = false
            }
          }, 1000)
        } else {
          this.$message({
          message: res.msg,
          type: 'error'
        })
          this.logInLoading = false
        }
      })
    },
    // 获取注册短信验证码
    sigInGetCodeApi() {
		console.log("122212123132")
      if (isMobile(this.logInForm.username)) {
        this.sigInLoading = true
        postRegisterGet_code({
          username: this.logInForm.username
        }).then((res) => {
          if (res.status === 1) {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.sigInDisabled = true
            //console.log(res)
            this.sigInLoading = false
            let TIME_COUNT = 60
            this.sigInCountDown = TIME_COUNT
            this.sigGetCode = true
            this.sigInTimer = setInterval(() => {
              if (
                this.sigInCountDown > 0 &&
                this.sigInCountDown <= TIME_COUNT
              ) {
                this.sigInCountDown--
              } else {
                clearInterval(this.sigInTimer)
                this.sigInTimer = null
                this.sigGetCode = false
                this.sigInDisabled = false
              }
            }, 1000)
          } else {
            this.sigInLoading = false
            this.$message({
              message: res.msg,
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请正确输入手机号',
          type: 'error'
        })
      }
    },
    // 获取找回密码手机验证码
    retrieveInGetCodeApi() {
      if (isMobile(this.logInForm.username)) {
        this.retrieveInLoading = true
        postLoginGet_code({
          username: this.logInForm.username
        }).then((res) => {
          if (res.status === 1) {
            this.$message({
              message: res.msg,
              type: 'success'
            })
            this.retrieveInDisabled = true
            //console.log(res)
            this.retrieveInLoading = false
            let TIME_COUNT = 60
            this.retrieveInCountDown = TIME_COUNT
            this.retrieveGetCode = true
            this.retrieveInTimer = setInterval(() => {
              if (
                this.retrieveInCountDown > 0 &&
                this.retrieveInCountDown <= TIME_COUNT
              ) {
                this.retrieveInCountDown--
              } else {
                clearInterval(this.retrieveInTimer)
                this.retrieveInTimer = null
                this.retrieveGetCode = false
                this.retrieveInDisabled = false
              }
            }, 1000)
          } else {
            this.retrieveInLoading = false
            this.$message({
              message: res.msg,
              type: 'error'
            })
          }
        })
      } else {
        this.$message({
          message: '请正确输入手机号',
          type: 'error'
        })
      }
    },
    // 立即登录
    LogNow:AntiShake.throttle( function() {
      console.log(this)
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      if (this.checked) {
        // 调用登录接口
        if(this.cellVerification){
          logincode_login({
            username: this.logInForm.username,
            code: this.logInForm.code
          }).then((res) => {
            if (res.status === 1) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
              loading.close()
              this.$store.dispatch('codeLogin',res.data.token)
              this.closes()
            } else {
              loading.close()
              this.$message({
                message: res.msg,
                type: 'error'
              })
            }
          })
        }else{
          postLogin({
            username: this.logInForm.username,
            password: this.logInForm.password
          }).then((res) => {
            //console.log(res)
            this.$store.dispatch('login', res)
            if (res.status === 1) {
              if(this.isSendPwd){//记住密码
                  localStorage.setItem('login',JSON.stringify({
                    username: this.logInForm.username,
                    password: this.logInForm.password
                  }))
              }else{
                localStorage.removeItem('login')
              }
              this.$message({
                message: res.msg,
                type: 'success'
              })
              loading.close()
              this.closes()
            } else {
              loading.close()
              this.$message({
                message: res.msg,
                type: 'error'
              })
            }
          })
        }
      } else {
        loading.close()
        this.checkedHint = true
        setTimeout(() => {
          this.checkedHint = false
        }, 5000)
      }
    }, 1000),
    // 立即找回密码
    Reset_passwordApi() {
      postReset_password({
        username: this.logInForm.username,
        code: this.logInForm.code,
        password: this.logInForm.password,
        password_again: this.logInForm.password_again
      }).then((res) => {
        //console.log(res)
        if (res.status === 1) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          })
        }
      })
    },
    // 立即注册
    postRegisterApi() {
      if (isSensitiveWord(this.logInForm.nike_name)) {
        if (this.checked) {
          postRegister({
            username: this.logInForm.username,
            code: this.logInForm.code,
            password: this.logInForm.password,
            nike_name: this.logInForm.nike_name,
            invite_code: this.logInForm.invite_code
          }).then((res) => {
            //console.log(res)
            if (res.status === 1) {
              this.$message({
                message: res.msg,
                type: 'success'
              })
              this.login = 'logIn'
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              })
            }
          })
        } else {
          this.checkedHint = true
          setTimeout(() => {
            this.checkedHint = false
          }, 5000)
        }
      } else {
        this.$message({
          message: '禁止输入敏感字符',
          type: 'error'
        })
      }
    },
    // 关闭
    closes() {
      this.$store.dispatch('setLogSignRetrieve', false)
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' //出现滚动条
      document.removeEventListener('touchmove', mo, false)
      // Object.assign(this.$data, this.$options.data()) // 重置data
    },
    // 手机验证码登录
    phoneLogin() {
      this.cellVerification = true
    },
    // 密码登录
    passwordLogin() {
      this.cellVerification = false
    },
    // 切换登录
    logins() {
      this.login = 'logIn'
    },
    // 切换注册
    sigins() {
      this.login = 'sigIn'
    },
    // 手机号注册
    cellSig() {
      this.EmailBack = false
    },
    // 忘记密码
    retrieveP() {
      this.login = 'retrievePassword'
    },
    // 邮箱验证码找回
    EBack() {
      this.EmailBack = true
    },
    // 手机验证码找回
    cellBack() {
      this.EmailBack = false
    },
    // 去登录
    goLogin() {
      this.login = 'logIn'
    }
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
@import '@/styles/variables';
@import '@/styles/login';
</style>
