<!-- 免费皮肤 -->
<template>
  <div class="free">
    <!-- 标题 -->
    <div class="free-title">
      <img
        class="free-title-off"
        src="@/assets/images/rollImages/3title.png"
        alt=""
      />
      <div
        class="free-title-off-myOne"
        :class="playTitle === 'rollHome' ? 'free-title-off-one' : ''"
        @click="rollHome"
      >
        Roll房
      </div>
      <div
        class="free-title-off-myTow"
        :class="playTitle === 'myRoll' ? 'free-title-off-tow' : ''"
        @click="myRoll"
      >
        我的Roll房
      </div>
      <div
        class="free-title-off-myThree"
        :class="playTitle === 'howPlay' ? 'free-title-off-three' : ''"
        @click="howPlay"
      >
        玩法介绍
      </div>
    </div>
    <!-- 搜索内容 -->
    <div class="free-title-searchBox" v-show="playTitle !== 'howPlay'">
      <input
        v-model="searchName"
        class="free-title-searchBox-search"
        placeholder="请输入要寻找的房间"
      />
      <div class="free-title-searchBox-icon" @click="searchN">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          class="bi bi-search"
          viewBox="0 0 16 16"
        >
          <path
            d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
          />
        </svg>
        <div class="free-title-searchBox-text">搜索</div>
      </div>
    </div>
    <!-- Roll房 -->
    <div class="free-home" v-show="playTitle === 'rollHome'">
      <div class="free-myRoll-state">
        <div
          class="free-myRoll-state-ing"
          :class="status === 1 ? 'free-myRoll-state-on' : ''"
          @click="statusIng"
        >
          进行中
        </div>
        <div
          class="free-myRoll-state-off"
          :class="status === 0 ? 'free-myRoll-state-on' : ''"
          @click="statusOver"
        >
          未开始
        </div>
      </div>
      <div class="a"></div>
      <ul class="listBox">
        <li v-for="item in roomList" :key="item.id">
          <div class="content" :class="item.type == 1 ? 'li_bgc1' : 'li_bgc'">
            <div class="name">{{ item.name }}</div>
            <p class="timer">
              {{
                item.status === 0
                  ? '未开始'
                  : item.status === 1
                    ? '进行中'
                    : item.status === 2
                      ? '已结束'
                      : ''
              }}
              {{ item.condition_time | formatDate }}-{{ item.run_lottery_time | formatDate }}
            </p>

            <ul>
              <li>
                <span class="hnk">
                  <img src="@/assets/images/n7_money.png" alt=""/>
                </span>
                <span>{{ item.pool_price ? item.pool_price : 0 }}</span>
              </li>
              <li>
                <span class="hnk">
                  <img src="@/assets/images/rollImages/skin-num.png" alt=""/>
                </span>
                <span>{{ item.skin_num ? item.skin_num : 0 }}</span>
              </li>
              <li>
                <span class="hnk">
                  <img src="@/assets/images/rollImages/people.png" alt=""/>
                </span>
                <span>{{ item.player_num ? item.player_num : 0 }}</span>
              </li>
            </ul>
            <!-- <div class="imglist" >
              <img :src="item.image_url" alt="" />
            </div> -->
            <div class="free-home-ul-li-item">
              <div v-for="items in item.skin_list" :key="items.id">
                <img
                  class="free-home-ul-li-item-back"
                  :src="items.rarity_thumb"
                  alt=""
                />
                <div class="free-home-ul-li-item-back">
                  <img
                    class="free-home-ul-li-item-zu"
                    :src="items.image_url"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <button class="go_rome" @click="goRoom(item.id, item.status)">
              进入房间
            </button>
          </div>
        </li>
      </ul>
    </div>
    <!-- 我的Roll房 -->
    <div class="free-myRoll" v-show="playTitle === 'myRoll'">
      <div class="free-myRoll-state">
        <div
          class="free-myRoll-state-ing"
          :class="status === 1 ? 'free-myRoll-state-on' : ''"
          :style="VPointer()"
          @click="statusIng"
        >
          进行中
        </div>
        <div
          class="free-myRoll-state-off"
          :class="status === 2 ? 'free-myRoll-state-on' : ''"
          :style="VPointer()"
          @click="statusOver"
        >
          已结束
        </div>
      </div>
      <ul class="listBox">
        <li v-for="item in joinRoomList" :key="item.id">
          <div class="content" :class="item.type == 1 ? 'li_bgc1' : 'li_bgc'">
            <div class="name">{{ item.name }}</div>
            <p class="timer">
              {{
                item.status === 0
                  ? '未开始'
                  : item.status === 1
                    ? '进行中'
                    : item.status === 2
                      ? '已结束'
                      : ''
              }}
              {{ item.condition_time | formatDate }}-{{ item.run_lottery_time | formatDate }}
            </p>

            <ul>
              <li>
                <span class="hnk">
                  <img src="@/assets/images/n7_money.png" alt=""/>
                </span>
                <span>{{ item.pool_price ? item.pool_price : 0 }}</span>
              </li>
              <li>
                <span class="hnk">
                  <img src="@/assets/images/rollImages/skin-num.png" alt=""/>
                </span>
                <span>{{ item.skin_num ? item.skin_num : 0 }}</span>
              </li>
              <li>
                <span class="hnk">
                  <img src="@/assets/images/rollImages/people.png" alt=""/>
                </span>
                <span>{{ item.player_num ? item.player_num : 0 }}</span>
              </li>
            </ul>
            <div class="free-home-ul-li-item" style="height: 1.23rem">
              <div v-for="items in item.skin_list" :key="items.id">
                <img
                  class="free-home-ul-li-item-back"
                  :src="items.rarity_thumb"
                  alt=""
                />
                <div class="free-home-ul-li-item-back">
                  <img
                    class="free-home-ul-li-item-zu"
                    :src="items.image_url"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <button class="go_rome" @click="goRoom(item.id, item.status)">
              进入房间
            </button>
          </div>
        </li>
      </ul>
    </div>
    <!-- 玩法介绍 -->
    <div class="free-howPlay" v-show="playTitle === 'howPlay'">
      <div class="a"></div>
      <div class="free-howPlay-title">ROLL房</div>
      <div v-html="play_introduction.content">
      </div>

    </div>
  </div>
</template>

<script>
import {formatDate} from '@/utils/date'
import {get_room_list, get_join_room_list, get_play_introduction} from '@/api/index/rollHome'

export default {
  name: 'FreeSkinBody',
  data() {
    return {
      playTitle: 'rollHome', // 标题
      searchName: '', // 搜索内容
      anchor: 2, // 官方&主播
      roomList: [], //ROLL房
      play_introduction: '',
      joinRoomList: [], //参与的rool房
      page: 1,
      page_size: 20,
      status: 1, // 1进行中 0 未开始 2 已结束
      total_rows: null
    }
  },
  filters: {
    formatDate(time) {
      var date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    }
  },
  created() {
    this.getRoomListApi()
  },
  mounted() {
    window.addEventListener('scroll', this.getbottom)
  },
  methods: {
    getbottom() {
      // 返回滚动条垂直滚动距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight
      // 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      let numHeight = scrollTop + clientHeight
      if (scrollHeight > clientHeight && numHeight > scrollHeight - 1) {
        if (
          this.total_rows > (this.roomList.length || this.joinRoomList.length)
        ) {
          this.page++
          if (this.playTitle == 'rollHome') {
            //Roll房
            this.getRoomListApi()
          }
          if (this.playTitle == 'myRoll') {
            //我的Roll房
            this.getJoinRoomListApi()
          }
        }
      }
    },
    // 所有rool房-进行中/未开始
    getRoomListApi() {
      get_room_list({
        page: this.page,
        page_size: this.page_size,
        status: this.status,
        name: this.searchName
      }).then((res) => {
        this.total_rows = res.data.total_rows
        this.roomList.push(...res.data.rows)
      })
    },
    // 参与的rool房-进行中/已结束
    getJoinRoomListApi() {
      get_join_room_list({
        page: this.page,
        page_size: this.page_size,
        status: this.status,
        name: this.searchName
      }).then((res) => {
        this.total_rows = res.data.total_rows
        this.joinRoomList.push(...res.data.rows)
      })
    },
    // 搜索房间
    searchN() {
      this.page = 1
      this.roomList = []
      this.roomList = []
      if (this.playTitle === 'rollHome') {
        this.getRoomListApi()
      } else {
        this.getJoinRoomListApi()
      }
    },
    //  Roll房
    rollHome() {
      this.page = 1
      this.roomList = []
      this.joinRoomList = []
      this.status = 1
      this.playTitle = 'rollHome'
      this.searchName = ''
      this.getRoomListApi()
    },
    // 我的Roll房
    myRoll() {
      // 判断用户是否登录
      if (!sessionStorage.getItem('n7_token')) {
        this.$store.dispatch('setLogSignRetrieve', true)
        return
      }
      this.page = 1
      this.roomList = []
      this.joinRoomList = []
      this.playTitle = 'myRoll'
      this.searchName = ''
      this.status = 1

      this.getJoinRoomListApi()
    },
    // 玩法介绍
    howPlay() {
      get_play_introduction().then((res) => {
        //console.log(res)
        this.play_introduction = res.data
      })
      this.playTitle = 'howPlay'
    },
    // 进入房间
    goRoom(id, status) {
      this.$router.push({
        path: '/GoRoom',
        query: {room_id: id, status: status}
      })
    },
    // 进行中
    statusIng() {
      this.page = 1
      this.roomList = []
      this.joinRoomList = []
      this.status = 1
      if (this.playTitle === 'rollHome') {
        this.searchName = ''
        this.getRoomListApi()
      } else {
        this.searchName = ''
        this.getJoinRoomListApi()
      }
    },
    // 已结束&&未开始
    statusOver() {
      this.page = 1
      this.roomList = []
      this.joinRoomList = []
      if (this.playTitle === 'rollHome') {
        this.searchName = ''
        this.status = 0
        this.getRoomListApi()
      } else {
        this.searchName = ''
        this.status = 2
        this.getJoinRoomListApi()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
@import '@/styles/FreeSkinBody.scss';

.listBox {
  min-height: 10rem;
}

.free-howPlay {
  min-height: 10rem;
}

</style>
