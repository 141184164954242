<template>
  <div v-if="visible">
    <div class="mask"></div>
    <img src="https://oss.N7buff.com/web/images/card/card-open-bg.png" :style="`${scaleBg}`" class="open-bg">
    <div class="card-openBox" >
      <div class="card-open" :style="`${scaleCss}`">
        <div
          :class="['card-item']"
          ref="cardItem"
          v-for="(item,index) in resultList" :key="index"
        >
          <div
            :class="['card', open ? 'cardAnimate' : '']"
          >

          </div>
          <div
            :class="['contxt', open ? 'contxtAnimate' : '']"
          >
            <el-tooltip class="item" effect="dark" :content="item.item_name" placement="bottom">
              <div class="card-contxt-title">{{ item.item_name }}</div>
            </el-tooltip>
            <div class="card-context-img" >
              <img :src="item.image_url">
            </div>
          </div>
        </div>
      </div>
      <div class="card-open-bottom" v-if="result && pass_num !== 5 && showBtn">
        <div class="card-open-bottom-btn" @click="continueChallenge()">继续挑战</div>
        <div class="card-open-bottom-btn" @click="receivePassSkin">结束挑战</div>
      </div>
      <div class="card-open-bottom" v-if="result && pass_num === 5 && showBtn">
        <div class="card-open-bottom-btn" @click="receivePassSkin">结束挑战</div>
      </div>
      <div class="card-open-bottom" v-if="!result && pass_num !== 5 && showBtn">
        <div class="card-open-bottom-btn" @click="restart()">重新开始</div>
      </div>
    </div>
  </div>
</template>
<script >
export  default {
  props: {
    visible: Boolean,
    resultList: Array,
    result: Boolean,
    pass_num: Number,
  },
  data() {
    return {
      open: false,
      scaleCss:'',
      scaleBg:'',
      showBtn: false
    }
  },
  watch:{
    visible:{
      handler(newVal){
        if (newVal){
          this.stopScroll()
          setTimeout(()=>{
            this.scaleCss = `transform:scale(1.3,1.3);transition: all 0.5s;`;
          },10)
          setTimeout(()=>{
            this.open = true
          },500)
          setTimeout(()=>{
            this.scaleBg = `opacity: 0.8;transition: all 0.9s;`;
            setTimeout(()=>{
              this.showBtn = true
            },500)
          },1400)
        }
      },
      deep:true,
      immediate: true
    }
  },
  created() {

  },
  methods: {
    continueChallenge() {
      this.canScroll()
      this.$emit('success')
      this.scaleCss = ''
      this.scaleBg = ''
      this.showBtn = false
      this.open = false

    },
    restart() {
      this.canScroll()
      this.$emit('fail')
      this.scaleCss = ''
      this.scaleBg = ''
      this.showBtn = false
      this.open = false

    },
    receivePassSkin() {
      this.canScroll()
      this.$emit('end')
      this.scaleCss = ''
      this.scaleBg = ''
      this.showBtn = false
      this.open = false

    },
    stopScroll(){

      var mo=function(e){e.preventDefault();};

      document.body.style.overflow='hidden';

      document.addEventListener("touchmove",mo,false);//禁止页面滑动

    },
    canScroll(){

      var mo=function(e){e.preventDefault();};

      document.body.style.overflow='';//出现滚动条

      document.removeEventListener("touchmove",mo,false);

    }
  }

}
</script>
<style scoped lang="scss">
.mask{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background: rgba(0,0,0,0.4);
  z-index: 99;
}
.open-bg{
  position: absolute;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 9;
}
.card-openBox{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  z-index: 991;
}
.card-open-bottom{
  position: absolute;
  bottom: 26%;
  z-index: 3;
  display: flex;
  .card-open-bottom-btn{
    cursor: pointer;
    width: 414px;
    height: 236px;
    background: url("@/assets/images/card/open-btn-bg.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    line-height: 234px;
    justify-content: center;
    font-size: 0.3rem;
    color: #FFFFFF;
  }
}
.card-open{
  transition: all 10s ;
  transform:scale(0,0)
}
.card-item{
  width: 508px;
  height: 642px;
  margin-bottom: 43px;
  position: relative;
  transform: translate(0);
  .card{
    width: 100%;
    height: 100%;
    background: url("@/assets/images/card/opposite.png") no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 2;
    backface-visibility: hidden;
    .card-open-btn{
      width: 240px;
      height: 92px;
      display: flex;
      justify-content: center;
      line-height: 42px;
      font-size: 16px;
      background: url("@/assets/images/card/card-btn.png") no-repeat;
      background-size: 100% 100%;
      position: absolute;
      bottom: 24px;
      margin: 0 auto;
      cursor: pointer;
    }
  }
  .contxt{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: url("@/assets/images/card/straight.png") no-repeat;
    background-size: 100% 100%;
    transform: rotateY(180deg);
    opacity: 0;
    backface-visibility: hidden;
    .card-contxt-title{
      width: 45%;
      margin: 0 auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      padding-top: 14px;
      font-size: 0.3rem;
    }
    .card-context-img{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 160px;
      img{
        width: 80%;
        transform: rotate(30deg);
      }
    }
  }
}
.cardAnimate {
  animation: rotetaCard 1s ease-in-out;
  animation-fill-mode: forwards;
}
/* 正面 */
.contxtAnimate {
  animation: contxtRotate 1s ease-in;
  animation-fill-mode: forwards;
}
@keyframes contxtRotate {
  from {
    transform: rotateY(180deg);
    opacity: 1;
  }

  to {
    transform: rotateY(0deg);
    opacity: 1;
  }
}

/* 背面卡牌翻牌动画 */
@keyframes rotetaCard {
  0%{
    transform: rotateY(0);
  }
  20%{
    transform: rotateY(360deg);
  }
  40%{
    transform: rotateY(0deg);
  }
  60%{
    transform: rotateY(360deg);
  }
  80%{
    transform: rotateY(0deg);
  }
  100%{
    transform: rotateY(180deg);
  }
  //from {
  //  transform: rotateY(0);
  //}
  //
  //to {
  //  transform: rotateY(180deg);
  //}
}
</style>
