<!-- 站内信息 -->
<template>
  <div class="stationInformation">
    <div class="index_title">站内信息</div>
    <div class="station-title">
      <div class="DeleteRead" :style="VPointer()" @click="del_player_mailApi">
        删除已读
      </div>
      <div class="station-messages">站内信息总数 : {{ total_rows }}</div>
    </div>
    <ul>
      <li
        v-for="item in playerList"
        :key="item.id"
        :style="VPointer()"
        @click="set_readApi(item)"
      >
        <div class="player-title">
          <div class="player-name">
            <div class="hint" v-if="item.is_read === 1"></div>
            {{ item.send_name }}
          </div>
          <div class="player-time">{{ item.update_time }}</div>
        </div>
        <div class="player-content" v-html="item.content"></div>
      </li>
    </ul>

    <!-- <div class="loadMore">
      <button class="btn" :style="VPointer()" @click="handleCurrentChange">
        上一页
      </button>
      <button class="btn_num">{{ page }}</button>
      <button class="btn" :style="VPointer()" @click="handleCurrentChangeTwo">
        下一页
      </button>
    </div> -->
  </div>
</template>

<script>
import {
  player_mail,
  get_player_mail,
  del_player_mail
} from '@/api/index/personalCenter'
import AntiShake from  '@/utils/AntiShake.js'
export default {
  name: 'stationInformation',
  data() {
    return {
      page_size: 6,
      page: 1,
      total_rows: null,
      playerList: [],
      page_count: null //总页数
    }
  },
  created() {
		if (sessionStorage.getItem('n7_token')) {
			this.$store.dispatch('getPlayerApi')
		}
    this.player_mailApi()
  },
  mounted() {
    window.addEventListener('scroll', this.getbottom)
  },
  beforeDestroy(){
    window.removeEventListener('scroll', this.getbottom)
  },
  methods: {
    getbottom() {
      // 返回滚动条垂直滚动距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight
      // 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      let numHeight = scrollTop + clientHeight
      if (scrollHeight > clientHeight && numHeight > scrollHeight - 1) {
        this.getData();
      }
    },
	getData: AntiShake.throttle(function () {
	  if (this.page < this.page_count && this.page_count != 1) {
	        this.page++
	        this.player_mailApi()
	  }
	} , 500),
    // 获取站内信息
    player_mailApi() {
      player_mail({
        page_size: this.page_size,
        page: this.page
      }).then((res) => {
        this.total_rows = res.data.total_rows
        this.page_count = res.data.page_count
        // this.playerList = res.data.rows
        res.data.rows.forEach(item=>{
          this.playerList.push(item)
        })
      })
    },
    // 站内信息-修改已读
    set_readApi(item) {
     this.$router.push('/stationInformationDetail?id='+item.id)
    },
    // 站内信息-删除已读
    del_player_mailApi() {
      del_player_mail().then((res) => {
        console.log(res)
        if (res.status === 1) {
          this.page=1
          this.playerList=[]
          this.player_mailApi()
        }
      })
    },
    handleCurrentChange() {
      this.page--
      if (this.page < 1) {
        this.page = 1
      }

      this.player_mailApi()
    },
    handleCurrentChangeTwo() {
      this.page++
      if (this.page > this.page_count) {
        this.page = this.page_count
      }
      this.player_mailApi()
    }
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
// @import '';
@import '@/styles/variables.scss';
.loadMore {
  padding-top: 0.3rem;
  width: 6rem;
  margin: auto;
  .btn {
    background-color: #1ef0ed;
    height: 0.7rem;
    width: 1.4rem;
    border-radius: 0.1rem;
    font-size: 0.3rem;
    border: none;
    margin: 0 0.2rem;
    color: #fff;
  }
  .btn_num {
    width: 0.7rem;
    height: 0.7rem;
    background-color: #1ef0ed;
    border-radius: 0.1rem;
    color: #fff;
    font-weight: 700;
    border: none;
    font-size: 0.3rem;
  }
}
.stationInformation {
  // height: 620px;
  position: relative;
  padding: 0.2rem;
  .station-title {
    display: flex;
    justify-content: space-between;
    .DeleteRead {
      border: 2px $theme solid;
      font-size: .3rem;
      padding: 0.1rem;
      &:hover {
        color: $theme;
      }
    }
  }
  ul {
    // background-color: blue;
    // height: 570px;
    // overflow-y: auto;
    padding-bottom: 0.2rem;
    li {
      font-size: 0.3rem;
      margin-top: 0.2rem;
      padding: 0.2rem;
      width: 9.6rem;
      // background: url('@/assets/images/personalCenter/站内信息back.png')
      //   no-repeat;
      border-bottom:0.05rem solid #1ef0ed;
      background-size: 100% 100%;
      .player-title {
        display: flex;
        justify-content: space-between;
        .hint {
          width: 0.2rem;
          height: 0.2rem;
          border-radius: 50%;
          background-color: red;
          display: inline-block;
        }
      }
      .player-content {
        margin-top: 0.1rem;
      }
    }
  }
  // ul::-webkit-scrollbar {
  //   width: 8px;
  // }
  // ul::-webkit-scrollbar-thumb {
  //   border-radius: 10px;
  //   -webkit-box-shadow: inset 0 0 5px #1ef0ed;
  //   background: rgba(0, 0, 0, 0.2);
  // }
  // ul::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 5px #1ef0ec65;
  //   border-radius: 0;
  //   background: rgba(0, 0, 0, 0.1);
  // }
  .pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
.station-messages{
      font-size: .3rem;

}
</style>
