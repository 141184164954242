<!-- 进入房间 -->
<template>
  <div class="app GoRoom">
    <!-- 玩法规则： -->
    <div class="GoRoom-head"
         :class="rollDetail.type === 1 ? 'official-background' : 'anchor-background'"
    >
      <div class="GoRoom-head-title ">
        <div class="GoRoom-head-title-a">

          <img
            class="GoRoom-head-title-a-img"
            src="@/assets/images/rollImages/roll-in.png"
            alt=""
          />

          <div class="GoRoom-head-title-a-text">{{ rollDetail.name }}</div>
        </div>
        <h4 class="GoRoom-head-title-time">
          开奖时间:{{ rollDetail.run_lottery_time | formatDate }}
        </h4>
      </div>
      <div class="GoRoom-head-rule">
        <div>玩法规则：</div>
        <div>{{ rollDetail.desc }}</div>
      </div>
      <div class="GoRoom-head-on">
        <div class="GoRoom-head-on-a">
          <img
            class="GoRoom-head-on-a-img"
            src="@/assets/images/Check.png"
            alt=""
          />
          <div @click="getJoinRoomApi" class="GoRoom-head-on-a-text">
            {{ this.$route.query.status | status(rollDetail.is_join) }}
            <!-- {{
              this.$route.query.status == 0
                ? '未开始'
                : this.$route.query.status == 1
                ? '进行中'
                : this.$route.query.status == 2
                ? '已结束'
                : ''
            }} -->
          </div>
        </div>
      </div>
    </div>
    <!-- 标题 -->
    <div class="GoRoom-title">
      <img
        class="GoRoom-title-off"
        src="@/assets/images/rollImages/3title.png"
        alt=""
      />
      <div
        class="GoRoom-title-off-myOne"
        :style="VPointer()"
        :class="playTitle === 'rollJackpot' ? 'GoRoom-title-off-one' : ''"
        @click="rollJackpot"
      >
        奖池
      </div>
      <div
        class="GoRoom-title-off-myTow"
        :style="VPointer()"
        :class="playTitle === 'rollParticipant' ? 'GoRoom-title-off-tow' : ''"
        @click="rollParticipant"
      >
        参与人员
      </div>
      <div
        class="GoRoom-title-off-myThree"
        :style="VPointer()"
        :class="playTitle === 'rollWinner' ? 'GoRoom-title-off-three' : ''"
        @click="rollWinner"
      >
        获奖名单
      </div>
    </div>
    <!-- 奖池 -->
    <div class="GoRoom-jackpot" v-show="playTitle === 'rollJackpot'">
      <div class="GoRoom-jackpot-res">
        <ul class="GoRoom-jackpot-res-ul">
          <li
            class="GoRoom-jackpot-res-ul-li"
            v-for="item in goodslist"
            :key="item.id"
          >
            <img
              class="GoRoom-jackpot-res-ul-li-back"
              :src="item.rarity_thumb"
              alt=""
            />
            <img
              class="GoRoom-jackpot-res-ul-li-main"
              :src="item.image_url"
              alt=""
            />
            <img
              class="GoRoom-jackpot-res-ul-li-shadow"
              src="@/assets/images/showImages/shadow.png"
              alt=""
            />

            <!-- <el-tooltip placement="bottom">
              <div slot="content">{{ item.item_name }}</div> -->
            <div
              class="GoRoom-jackpot-res-ul-li-name GoRoom-jackpot-res-ul-li-li"
            >
              {{ item.item_name }}
            </div>
            <!-- </el-tooltip> -->

            <div
              class="GoRoom-jackpot-res-ul-li-currency GoRoom-jackpot-res-ul-li-li"
            >
              <div class="GoRoom-jackpot-res-ul-li-currency-yuan">
                <img src="@/assets/images/n7_money.png" alt=""/>
                <div>{{ item.price }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 参与人员 -->
    <div class="GoRoom-participant" v-show="playTitle === 'rollParticipant'">
      <ul class="GoRoom-participant-ul">
        <li
          class="GoRoom-participant-ul-li"
          v-for="item in personnellist"
          :key="item.id"
        >
          <img
            class="GoRoom-participant-ul-li-portrait"
            :src="item.head_img"
            alt=""
          />
          <div class="GoRoom-participant-ul-li-name">{{ item.nike_name }}</div>
        </li>
      </ul>
    </div>
    <!-- 获奖名单 -->
    <!-- <div class="GoRoom-winner" v-show="playTitle === 'rollWinner'">
      <div class="GoRoom-winner-res">
        <ul class="GoRoom-winner-res-ul">
          <li
            class="GoRoom-winner-res-ul-li"
            v-for="item in prizeWinner"
            :key="item.id"
          >
              <img
              class="GoRoom-winner-res-ul-li-back"
              :src="item.rarity_thumb"
              alt=""
            />
            <img
              class="GoRoom-winner-res-ul-li-main"
              :src="item.image_url"
              alt=""
            />
            <img
              class="GoRoom-winner-res-ul-li-shadow"
              src="@/assets/images/showImages/shadow.png"
              alt=""
            />

            <el-tooltip placement="bottom">
              <div slot="content">{{ item.item_name }}</div>
              <div
                class="GoRoom-winner-res-ul-li-name GoRoom-winner-res-ul-li-li"
              >
                {{ item.item_name }}
              </div>
            </el-tooltip>
            <div
              class="GoRoom-winner-res-ul-li-currency GoRoom-winner-res-ul-li-li"
            >
              <div class="GoRoom-winner-res-ul-li-currency-yuan">
                <img src="@/assets/images/n7_money.png" alt="" />
                <div>{{ item.price }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div> -->

    <div class="GoRoom-jackpot" v-show="playTitle === 'rollWinner'">
      <div class="GoRoom-jackpot-res">
        <ul class="GoRoom-jackpot-res-ul">
          <li
            class="GoRoom-jackpot-res-ul-li"
            v-for="item in prizeWinner"
            :key="item.id"
          >
            <img
              class="GoRoom-jackpot-res-ul-li-back"
              :src="item.rarity_thumb"
              alt=""
            />
            <img
              class="GoRoom-jackpot-res-ul-li-main"
              :src="item.image_url"
              alt=""
            />
            <img
              class="GoRoom-jackpot-res-ul-li-shadow"
              src="@/assets/images/showImages/shadow.png"
              alt=""
            />

            <div
              class="GoRoom-jackpot-res-ul-li-name GoRoom-jackpot-res-ul-li-li"
            >
              {{ item.item_name }}
            </div>

            <div
              class="GoRoom-jackpot-res-ul-li-currency GoRoom-jackpot-res-ul-li-li"
            >
              <div class="GoRoom-jackpot-res-ul-li-currency-yuan">
                <img src="@/assets/images/n7_money.png" alt=""/>
                <div>{{ item.price }}</div>
              </div>
            </div>
            <div class="rollWinner_user">
              <img :src="item.head_img" alt="">
              <div class="">{{ item.nike_name }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <el-dialog
      custom-class="Password"
      title="请输入口令"
      :visible.sync="dialogVisible"
      width="80%"
    >
        <span>
           <el-input v-model="password" placeholder="请输入口令"></el-input>

        </span>
      <span slot="footer" class="dialog-footer">
          <div class="cancal" @click="dialogVisible = false">取消</div>
          <div class="heng"></div>
          <div class="confrim" @click="join_password_room">确认</div>

        </span>
    </el-dialog>
  </div>
</template>

<script>
import {formatDate} from '@/utils/date'
import {
  join_room,
  get_roll_detail,
  get_prize_winner
} from '@/api/index/rollHome'

export default {
  name: 'GoRoom',
  data() {
    return {
      playTitle: 'rollJackpot', // 标题
      JoinRoom: {}, // 加入rool房
      rollDetail: [], // rool房详情
      dialogVisible: false,
      prizeWinner: [], // rool房详情-获奖名单
      password: "",
      goodslist: [],
      personnellist: [],
      rollWinnerList: [],
    }
  },
  filters: {
    formatDate(time) {
      var date = new Date(time)
      return formatDate(date, 'yyyy年MM月dd日 hh:mm:ss')
    },
    status(args, join) {
      if (args == 0) {
        return '未开始'
      }
      if (args == 1 && join == 1) {
        return '已参与'
      }
      if (args == 1 && join == 2) {
        return '立即参与'
      }
      if (args == 2) {
        return '已参与'
      }
    }
  },
  created() {
    if (sessionStorage.getItem('n7_token')) {
      this.$store.dispatch('getPlayerApi')
    }
    this.getRollDetailApi()
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  /*  beforeDestroy(){
      window.removeEventListener('scroll', this.handleScroll)
    }, */
  methods: {
    // 加入rool房
    getJoinRoomApi() {
      if (this.rollDetail.status == 1 && this.rollDetail.is_join == 2) {
        if (this.rollDetail.condition_type != 2) {
          this.dialogVisible = true
        } else {
          this.join_rooms()
        }
      }
    },
    //加入口令+充值房间
    join_password_room() {
      this.join_rooms()
    },
    join_rooms() {
      join_room({room_id: this.$route.query.room_id, password: this.password}).then((res) => {
        if (res.status === 1) {
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.dialogVisible = false
          this.getRollDetailApi()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      //变量windowHeight是可视区的高度
      let windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      //变量scrollHeight是滚动条的总高度
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      console.log("scrollTop " + scrollTop)
      console.log("windowHeight " + windowHeight)
      console.log("scrollHeight " + scrollHeight)

      //滚动条到底部的条件
      if (scrollTop + windowHeight >= scrollHeight - 400) {
        switch (this.playTitle) {
          case 'rollJackpot':
            this.lazy_goods();
            break;
          case 'rollParticipant':
            this.lazy_personnel();
            break;
          case 'rollWinner':
            this.lazy_rollWinner();
            break;
        }
      }
    },
    // 获奖名单
    lazy_rollWinner() {
      if (this.rollWinnerList.length < this.prizeWinner.length) {
        this.rollWinnerList = this.rollWinnerList.concat(
          this.prizeWinner.slice(
            this.rollWinnerList.length,
            this.rollWinnerList.length + 20
          )
        );
      }
    },
    //参与人员延迟渲染
    lazy_personnel() {
      if (this.personnellist.length < this.rollDetail.room_players.length) {
        this.personnellist = this.personnellist.concat(
          this.rollDetail.room_players.slice(
            this.personnellist.length,
            this.personnellist.length + 40
          )
        );
      }
    },
    //物品延迟渲染
    lazy_goods() {
      if (this.goodslist.length < this.rollDetail.room_skins.length) {
        this.goodslist = this.goodslist.concat(
          this.rollDetail.room_skins.slice(
            this.goodslist.length,
            this.goodslist.length + 20
          )
        );
      }
    },


    // rool房详情
    getRollDetailApi() {
      get_roll_detail({room_id: this.$route.query.room_id}).then((res) => {
        this.rollDetail = res.data
        this.goodslist = res.data.room_skins.slice(0, 20);
        this.personnellist = res.data.room_players.slice(0, 40);
      })
    },
    // rool房详情-获奖名单
    getPrizeWinnerApi() {
      get_prize_winner({room_id: this.$route.query.room_id}).then((res) => {
        if (res.data) {
          this.prizeWinner = res.data
          this.rollWinnerList = this.prizeWinner.slice(0, 20)
        }

      })
    },
    //  奖池
    rollJackpot() {
      this.playTitle = 'rollJackpot'
    },
    // 参与人员
    rollParticipant() {
      this.playTitle = 'rollParticipant'
    },
    // 获奖名单
    rollWinner() {
      this.playTitle = 'rollWinner'
      this.getPrizeWinnerApi()
    },

  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/GoRoom.scss';

.official-background {
  background: url('@/assets/images/rollImages/official.png') no-repeat;
  background-size: 100% 100%;
}

.anchor-background {
  background: url('@/assets/images/rollImages/anchor.png') no-repeat;
  background-size: 100% 100%;
}

.dialog-footer {
  display: flex;
  margin-top: .3rem;
  padding-bottom: 0;
  border-top: 1px solid #1ef0ed;
  height: 1rem;
}

.heng {
  width: 1px;
  background: #1ef0ed;
}

::v-deep .el-dialog__footer {
  padding: 0;

}

::v-deep .el-dialog__header {
  padding: .4rem 0;
}

::v-deep .el-dialog__header .el-dialog__title {
  font-size: .4rem;
  color: #fff;
}

::v-deep .Password {
  margin-top: 40vh !important;
  background: #000;
  border: 2px solid #1ef0ed;
  border-radius: .2rem;
}

::v-deep .el-input__inner {
  border: 2px solid #1ef0ed;
  background: rgba(29, 29, 35, .2);
  color: #fff;
  height: .7rem;
  width: 80%;
  font-size: .3rem;

}

::v-deep .el-input__inner:focus {
  border-color: #1ef0ed;
}

.cancal {
  text-align: center;
  color: #fff;
  font-size: .4rem;
  display: flex;
  justify-content: center;
  text-align: .2rem;
  letter-spacing: .2rem;
  align-items: center;
  flex: 1
}

.confrim {
  text-align: center;
  color: #000;
  display: flex;
  justify-content: center;
  font-size: .4rem;
  flex: 1;
  align-items: center;
  color: #1ef0ed;
  text-align: .2rem;
  letter-spacing: .2rem;
}
</style>
