

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/elStyles.scss'
import axios from 'axios'
// import '@/styles/variables.scss';
import '@/styles/index.scss'
import 'amfe-flexible'
// import 'amfe-flexible'

// import './assets/rem/rem.js'
import vueFilter from '@/assets/js/VFilter.js' //公共过滤器
import {
	VPointer,
	BodyPointer
} from '@/utils/style.js' //指针样式
import {
	Log_pop_up,
	GetUserInfo,
	GetPersonalData,
	ToolTips,
	GetTextByCode,
	getammo,
	Set_whole_verification,
	closeopenedRed,
	Award_winning,
	setNotice,
	SetRes_Sm_show,
	setRechargeverification,
	setRecharge,
	setPassRed,
	setTransportMask,
	setTaskwallShow,
	setloadShow,
	truncation,
	setBinodalData
} from '@/assets/js/fashion.js'
import VueClipboard from 'vue-clipboard2' //复制
// import 'swiper/css/swiper.css'
// import VueWechatTitle from 'vue-wechat-title'//配置页面meta信息
import VConsole from 'vconsole'
const isProd = process.env.NODE_ENV === 'production'
if (!isProd) {
  const vConsole = new VConsole()
  Vue.use(vConsole)
}
// import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'
//
// Vue.use(VueAwesomeSwiper)

import "babel-polyfill";
// import './assets/font/font.css'
Vue.config.productionTip = false


import {
	CountDown
} from 'vant' //vant倒计时
import {
	NoticeBar
} from 'vant' //vant通知栏
import {
	Swipe,
	SwipeItem
} from 'vant' //vantSwiper
import {
	Overlay
} from 'vant' //vant遮罩层
import {
	Checkbox,
	CheckboxGroup
} from 'vant' //vant复选框
import {
	Uploader
} from 'vant' //vant上传
import {
	Loading
} from 'vant' //vant加载icon
import { List } from 'vant';
import { Lazyload } from 'vant';
router.afterEach(() => {
  window.scrollTo(0,0);
});



Vue.use(CountDown)
Vue.use(NoticeBar)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(VueClipboard)
Vue.use(ElementUI)
Vue.use(Overlay)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Uploader)
Vue.use(Loading)
Vue.use(List)
Vue.use(Lazyload, {
	lazyComponent: true,
  });
// Vue.use(VueWechatTitle)
Vue.prototype.VPointer = VPointer
Vue.prototype.BodyPointer = BodyPointer
Vue.prototype.Log_pop_up = Log_pop_up
Vue.prototype.GetUserInfo = GetUserInfo
Vue.prototype.GetPersonalData = GetPersonalData
Vue.prototype.ToolTips = ToolTips
Vue.prototype.GetTextByCode = GetTextByCode
Vue.prototype.Set_whole_verification = Set_whole_verification
Vue.prototype.closeopenedRed = closeopenedRed
Vue.prototype.Award_winning = Award_winning
Vue.prototype.SetRes_Sm_show = SetRes_Sm_show
Vue.prototype.setRechargeverification = setRechargeverification
Vue.prototype.setRecharge = setRecharge
Vue.prototype.setNotice = setNotice
Vue.prototype.setPassRed = setPassRed
Vue.prototype.getammo = getammo
Vue.prototype.setTransportMask = setTransportMask
Vue.prototype.setTaskwallShow = setTaskwallShow
Vue.prototype.setloadShow = setloadShow
Vue.prototype.$axios = axios
Vue.prototype.truncation = truncation
Vue.prototype.setBinodalData = setBinodalData


for (let key in vueFilter) { //公共过滤器
	Vue.filter(key, vueFilter[key])
}


/**
 * 解决hbuilder打包app之后点击手机返回键直接退出app的
 */
document.addEventListener('plusready', function () {
  var webview = plus.webview.currentWebview()
  plus.key.addEventListener('backbutton', function () {
    webview.canBack(function (e) {
      if (e.canBack) {
        webview.back()
      } else {
        // webview.close() //hide,quit
        // plus.runtime.quit()
        // 首页返回键处理
        // 处理逻辑：1秒内，连续两次按返回键，则退出应用；
        var first = null
        plus.key.addEventListener(
          'backbutton',
          function () {
            // 首次按键，提示‘再按一次退出应用’
            if (!first) {
              first = new Date().getTime()
              console.log('再按一次退出应用') // 此处可以用自定义提示
              plus.nativeUI.toast('再按一次退出应用')
              setTimeout(function () {
                first = null
              }, 1000)
            } else {
              if (new Date().getTime() - first < 1500) {
                  plus.runtime.quit();
                // plus.runtime.quit()
              }
            }
          },
          false
        )
      }
    })
  })
})

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')


