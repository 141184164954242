<!-- 盲盒对战 -->
<template>
  <div class="app">
    <div class="index_title">盲盒对战</div>
    <div
      :class="[
        { introduced: CaseDetails },
        { introduced: GamePlayShowModal },
        { introduced: HistoricalShowModal },
        { introduced: MyHistoricalShowModal },
        { introduced: CreateRoomShowModal }

      ]"
    ></div>
    <div class="fight-body">
      <!-- 头 -->
      <ul class="fight-body-mainUl">
        <li
          class="fight-body-mainUl-li"
          v-for="(item, index) in battleBoxList"
          :key="index"
        >
          <div class="fight-body-mainUl-li-back">
            <div class="fight-body-mainUl-li-back-box">
              <img :src="item.img_main" alt=""/>
            </div>
            <div
              class="fight-body-mainUl-li-back-main"
              @click="CaseDetailsTo(item.id)"
            >
              <img :src="item.img_active" alt=""/>
            </div>
          </div>
          <div class="fight-body-mainUl-li-name">{{ item.name }}</div>
          <div class="fight-body-mainUl-li-price">
            <img src="@/assets/images/n7_money.png" alt=""/>
            <div>{{ item.price }}</div>
          </div>
        </li>
      </ul>
      <!-- 箱子详情 -->
      <div class="CaseDetails" v-if="CaseDetails">
        <CaseDetails
          :visible.sync="CaseDetails"
          :battleBoxData="battleBoxData"
        />
      </div>

      <!-- 盲盒战场 -->
      <div class="fight-body-battleground">
        <!-- 盲盒战场标题 -->
        <div class="fight-body-battleground-title">
          <div class="jieshao" :style="VPointer()" @click="GamePlay">
            玩法介绍
          </div>
          <div class="fight-body-battleground-title-text">盲盒战场</div>
        </div>
        <!-- 盲盒战场按钮 -->
        <div class="fight-body-battleground-but">
<!--          <div class="fight-body-battleground-but-left">-->
<!--            <div class="fight-body-battleground-but-left-btn"-->
<!--                 :class="btnActive === 1 ? 'fight-body-battleground-but-left-active':''" @click="clickTab(1)"-->
<!--                 :style="VPointer()">全 部-->
<!--            </div>-->
<!--            <div class="fight-body-battleground-but-left-btn"-->
<!--                 :class="btnActive === 2 ? 'fight-body-battleground-but-left-active':''" @click="clickTab(2)"-->
<!--                 :style="VPointer()">欧 皇-->
<!--            </div>-->
<!--            <div class="fight-body-battleground-but-left-btn"-->
<!--                 :class="btnActive === 3 ? 'fight-body-battleground-but-left-active':''" @click="clickTab(3)" :style="VPointer()">非 酋</div>-->
<!--            <div class="fight-body-battleground-but-left-btn"-->
<!--                 :class="btnActive === 4 ? 'fight-body-battleground-but-left-active':''" @click="clickTab(4)"-->
<!--                 :style="VPointer()">组 队-->
<!--            </div>-->
<!--          </div>-->
          <div class="fight-body-battleground-but-right">
            <div
              class="fight-body-battleground-but-right-ShowModal"
              v-show="GamePlayShowModal"
            >
              <GamePlay :visible.sync="GamePlayShowModal"/>
            </div>
            <img
              src="@/assets/images/fightImages/history.png"
              alt=""
              @click="historical"
            />
            <div
              class="fight-body-battleground-but-right-ShowModal"
              v-show="HistoricalShowModal"
            >
              <AgainstHistorical ref="AgainstHistorical" :visible.sync="HistoricalShowModal"/>
            </div>
            <!-- 我的对战历史 -->
            <img
              src="@/assets/images/fightImages/my.png"
              alt=""
              @click="Myhistorical"
            />
            <div
              class="fight-body-battleground-but-right-ShowModal"
              v-show="MyHistoricalShowModal"
            >
              <MyAgainstHistorical ref="MyAgainstHistorical" :visible.sync="MyHistoricalShowModal"/>
            </div>
            <img
              src="@/assets/images/fightImages/create-room.png"
              alt=""
              @click="createRoom"
            />
            <div
              class="fight-body-battleground-but-right-ShowModals"
              v-show="CreateRoomShowModal"
            >
              <CreateRoom
                ref="CreateRoomShowModalRef"
                :visible.sync="CreateRoomShowModal"
                :battleBox="battleBoxList"
              />
            </div>
          </div>
        </div>
        <!-- 盲盒战场房间 -->
        <div class="fight-body-battleground-room">
          <ul class="fight-body-battleground-room-ul">
            <li
              :class="[
                'fight-body-battleground-room-ul-li',
                { ai: item.room_type === 3 },
                { players: item.room_type === 1 },
                { zbs: item.room_type === 2 }
              ]"
              @click="toPlay(item.id)"
              v-for="item in battle_room.battle_room"
              :key="item.id"
            >
              <!-- 头 -->
              <div class="fight-body-battleground-room-ul-li-title">
                <div class="fight-body-battleground-room-ul-li-title-or">
                  <div>
                    <img
                      src="@/assets/images/inbattle.png"
                      class="inbattle"
                      v-if="item.room_status === 2"
                      alt=""
                    />
                    <img
                      src="@/assets/images/etc_icon.png"
                      class="ladging"
                      alt=""
                      v-else
                    />
                  </div>
                  {{
                    item.room_status === 1
                      ? '等待对战'
                      : item.room_status === 2
                        ? '对战中'
                        : item.room_status === 3
                          ? '已结束'
                          : ''
                  }}
                </div>
                <div :class="['fight-body-battleground-room-ul-li-title-type',{ ai_type: item.room_type === 3 },
                { players_type: item.room_type === 1 },
                { anchor_type: item.room_type === 2 }]">
                  <span v-if="item.battle_type == 1" style="color: #FFFFFF">欧皇</span>
                  <span v-if="item.battle_type == 2">非酋</span>
                  <span v-if="item.battle_type == 3" style="color: #FFFFFF">组队</span>
                </div>
                <div class="fight-body-battleground-room-ul-li-title-bout" style="font-size: 0.35rem;">
                  {{ item.battle_box_num }}回合
                </div>
              </div>
              <!-- VS -->
              <div class="fight-body-battleground-room-ul-li-player" v-if="item.battle_type != 3">
                <!-- 房间人 -->
                <div class="fight-body-battleground-room-ul-li-player-one">
                  <div
                    :class="
                     itemInfo.type != 1?' fight-body-battleground-room-ul-li-player-one-inviter':''
                    "
                    v-for="itemInfo in item.info"
                    :key="itemInfo.id"
                  >
                    <div v-if="itemInfo.type == 1">
                      <i class="vs">VS</i>
                    </div>
                    <div v-else>
                      <img
                        v-if="itemInfo.id === null"
                        class="
                          fight-body-battleground-room-ul-li-player-one-inviter-inviterImg
                          head-img
                        "
                        src="@/assets/images/fightImages/Inviter.png"
                        @click.stop="tojoinRoom(item, itemInfo)"
                        alt=""
                      />
                      <img
                        v-else
                        class="
                          fight-body-battleground-room-ul-li-player-one-inviter-inviterImg
                          head-img
                        "
                        :src="itemInfo.head_img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="fight-body-battleground-room-ul-li-player" v-if="item.battle_type == 3">
                <!-- 房间人 -->
                <div class="fight-body-battleground-room-ul-li-player-one">
                  <div
                    :class="
                     itemInfo.type != 1?' fight-body-battleground-room-ul-li-player-one-inviter':''
                    "
                    v-for="itemInfo in item.info.slice(0,3)"
                    :key="itemInfo.id"
                  >
                    <div v-if="itemInfo.type == 1">
                      <!--                      <i class="vs">VS</i>-->
                    </div>
                    <div v-else>
                      <img
                        v-if="itemInfo.id === null"
                        class="
                          fight-body-battleground-room-ul-li-player-one-inviter-inviterImg
                          head-img
                        "
                        src="@/assets/images/fightImages/Inviter.png"
                        @click.stop="tojoinRoom(item, itemInfo)"
                        alt=""
                      />
                      <img
                        v-else
                        class="
                          fight-body-battleground-room-ul-li-player-one-inviter-inviterImg
                          head-img
                        "
                        :src="itemInfo.head_img"
                        alt=""
                      />
                    </div>
                  </div>
                  <div>
                    <i class="vs team-vs" style="color: red">VS</i>
                  </div>
                  <div
                    :class="
                     itemInfo.type != 1?' fight-body-battleground-room-ul-li-player-one-inviter':''
                    "
                    v-for="itemInfo in item.info.slice(3,7)"
                    :key="itemInfo.id"
                  >
                    <div v-if="itemInfo.type == 1">
                      <!--                      <i class="vs">VS</i>-->
                    </div>
                    <div v-else>
                      <img
                        v-if="itemInfo.id === null"
                        class="
                          fight-body-battleground-room-ul-li-player-one-inviter-inviterImg
                          head-img
                        "
                        src="@/assets/images/fightImages/Inviter-red.png"
                        @click.stop="tojoinRoom(item, itemInfo)"
                        alt=""
                      />
                      <img
                        v-else
                        class="
                          fight-body-battleground-room-ul-li-player-one-inviter-inviterImg
                          head-img
                        "
                        :src="itemInfo.head_img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- 箱子 -->
              <div class="fight-body-battleground-room-ul-li-award">
                <div class="boxBox">
                  <div
                    class="fight-body-battleground-room-ul-li-award-one"
                    v-for="itemBox in item.battle_box_list"
                    :key="itemBox.id"
                  >
                    <div
                      class="fight-body-battleground-room-ul-li-award-one-back"
                    >
                      <div
                        class="
                          fight-body-battleground-room-ul-li-award-one-back-box
                        "
                      >
                        <img :src="itemBox.img_main" alt=""/>
                      </div>
                      <div
                        class="
                          fight-body-battleground-room-ul-li-award-one-back-main
                        "
                      >
                        <img :src="itemBox.img_active" alt=""/>
                      </div>
                    </div>
                    <div
                      class="fight-body-battleground-room-ul-li-award-one-number"
                    >
                      <img
                        class="
                          fight-body-battleground-room-ul-li-award-one-number-numberImg
                        "
                        src="https://oss.n7buff.com/mobile/assets/images/fightImages/0.png"
                        alt=""
                      />
                      <div
                        class="
                          fight-body-battleground-room-ul-li-award-one-number-numberText
                        "
                      >
                        {{ itemBox.battle_box_count }}
                      </div>
                    </div>
                  </div>
                </div>

                <div class="fight-body-battleground-room-ul-li-award-currency">
                  <div
                    class="fight-body-battleground-room-ul-li-award-currency-on"
                  >
                    <img src="@/assets/images/n7_money.png" alt=""/>
                    <div class="sum_price">{{ item.battle_box_sum_price }}</div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaseDetails from './components/CaseDetails.vue'
import GamePlay from './components/GamePlay.vue'
import AgainstHistorical from './components/AgainstHistorical.vue'
import MyAgainstHistorical from './components/MyAgainstHistorical.vue'
import CreateRoom from './components/CreateRoom.vue'
import {
  get_battle_box_list,
  get_battle_box_data,
  get_battle_room_list,
  join_battle_room
} from '@/api/index/BlindBattle'

export default {
  name: 'K5Home',
  data() {
    return {
      imgurl: require('@/assets/images/n7_money.png'),
      // room: 2,
      CaseDetails: false, // 箱子详情
      GamePlayShowModal: false, // 玩法介绍
      HistoricalShowModal: false, // 对战历史
      CreateRoomShowModal: false, // 创建房间
      MyHistoricalShowModal: false,//我的对战历史
      battleBoxList: [], // 箱子列表
      battleBoxData: {}, // 箱子详情
      battle_room: {}, // 对战中房间列表
      /*对战列表相关*/
      Timer: null, //定时器
      battle_room_count: null,
      loadMoreCount: 12, //
      btnActive: 1
    }
  },
  components: {
    CaseDetails,
    GamePlay,
    AgainstHistorical,
    MyAgainstHistorical,
    CreateRoom
  },
  watch: {
    CaseDetails: {
      immediate: true,
      handler(newData, oldData) {
        this.initOverflow(newData);
      }
    },
    GamePlayShowModal: {
      immediate: true,
      handler(newData, oldData) {
        this.initOverflow(newData);
      }
    },
    HistoricalShowModal: {
      immediate: true,
      handler(newData, oldData) {
        this.initOverflow(newData);
      }
    },
    CreateRoomShowModal: {
      immediate: true,
      handler(newData, oldData) {
        this.initOverflow(newData);
      }
    },
    MyHistoricalShowModal: {
      immediate: true,
      handler(newData, oldData) {
        this.initOverflow(newData);
      }
    }
  },
  created() {
    if (sessionStorage.getItem('n7_token')) {
      this.$store.dispatch('getPlayerApi')
    }
    this.getBattleBoxListApi()
    // this.getBattleRoomListApi()
    this.getBattleRoomListApi(this.loadMoreCount)
    this.Timer = setInterval(() => {
      this.getBattleRoomListApi(this.loadMoreCount)
    }, 3000)
  },
  mounted() {
    window.addEventListener('scroll', this.getbottom)
  },
  methods: {
    // 筛选切换
    clickTab(index) {
      this.btnActive = index
    },
    initOverflow(newData) {
      if (!newData) {
        var mo = function (e) {
          e.preventDefault()
        }
        document.body.style.overflow = '' //出现滚动条
        document.removeEventListener('touchmove', mo, false)
      }
    },
    getbottom() {
      // 返回滚动条垂直滚动距离
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      // 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
      let clientHeight =
        document.documentElement.clientHeight || document.body.clientHeight
      // 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
      let scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight
      let numHeight = scrollTop + clientHeight
      if (scrollHeight > clientHeight && numHeight > scrollHeight - 1) {
        if (this.loadMoreCount + 12 > this.battle_room_count) {
          this.loadMoreCount = this.battle_room_count
          this.getBattleRoomListApi(this.battle_room_count)
        } else {
          this.loadMoreCount = this.loadMoreCount + 12
          this.getBattleRoomListApi(this.loadMoreCount)
        }
      }
    },
    // 盲盒对战-箱子列表
    async getBattleBoxListApi() {
      const res = await get_battle_box_list()
      this.battleBoxList = res.data
    },
    // 箱子详情
    async CaseDetailsTo(id) {
      const res = await get_battle_box_data({id: id})
      this.battleBoxData = res.data
      this.CaseDetails = true
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', mo, false) //禁止页面滑动
    },
    // 玩法介绍弹出
    GamePlay() {
      this.GamePlayShowModal = true
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', mo, false) //禁止页面滑动
    },
    // 我的对战历史弹出
    Myhistorical() {
      this.MyHistoricalShowModal = true
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', mo, false) //禁止页面滑动
    },

    // 对战历史弹出
    historical() {
      this.HistoricalShowModal = true
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', mo, false) //禁止页面滑动
    },
    // 创建房间弹出
    createRoom() {
      this.CreateRoomShowModal = true
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      this.$refs.CreateRoomShowModalRef.addData = []
      document.addEventListener('touchmove', mo, false) //禁止页面滑动
    },

    //加入战场
    tojoinRoom(room_detail, itemInfo) {
      /* 	console.log("romm_detail " + room_detail.id)
        return */
      console.log(itemInfo)
      this.$confirm(
        `消耗<img style="width:0.4rem" src="${this.imgurl}">${room_detail.battle_box_sum_price}加入房间<br/>确定加入房间吗?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true
        }
      )
        .then(() => {
          join_battle_room({battle_room_id: room_detail.id, battle_station: itemInfo.index}).then(
            (res) => {
              if (res.status == 1) {
                this.$store.dispatch('getPlayerApi')
                this.$message.success('加入成功')
                //this.$emit('Refresh_room')
                this.$router.push({path: '/battleDetail', query: {id: room_detail.id}})
              } else {
                this.$message.error(res.msg)
              }
            }
          )
        })
        .catch(() => {
        })
    },
    // 盲盒对战-对战中房间列表
    async getBattleRoomListApi(size) {
      const res = await get_battle_room_list({size: size})
      res.data.battle_room.forEach((item) => {
        this.$set(item, 'info', [])
        item.player_info.forEach((item1, index) => {
          if (index + 1 < item.player_info.length) {
            item1.index = index
            item.info.push(item1)
            item.info.push({
              type: 1
            })
          } else {
            item1.index = index
            item.info.push(item1)
          }
        })
      })
      this.battle_room = res.data
      this.battle_room_count = res.data.battle_room_count
    },
    // 加载更多···
    loadMore() {
      this.loadMoreCount = this.loadMoreCount + 9
      this.loadMoreApi()
    },
    async loadMoreApi() {
      const res = await get_battle_room_list({size: this.loadMoreCount})
      res.data.battle_room.forEach((item) => {
        this.$set(item, 'info', [])
        item.player_info.forEach((item1, index) => {
          if (index + 1 < item.player_info.length) {
            item1.index = index
            item.info.push(item1)
            item.info.push({
              type: 1
            })
          } else {
            item1.index = index
            item.info.push(item1)
          }
        })
      })
      this.battle_room = res.data
    },
    // 进入房间
    toPlay(id) {
      this.$router.push({path: '/battleDetail', query: {id: id}})
    }
  },
  beforeDestroy() {
    this.$refs.AgainstHistorical.off() //对战历史
    this.$refs.MyAgainstHistorical.off() // 我的对战
    window.removeEventListener('scroll', this.getbottom)
    clearInterval(this.Timer)
    this.Timer = null
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/BlindBoxFight.scss';

.fight-body {
  color: aliceblue;
}

.inbattle {
  width: 0.4rem !important;
  height: 0.4rem !important;
  margin-right: 0.08rem !important;
  margin-top: 0.1rem;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

.ladging {
  width: 0.3rem !important;
  height: 0.3rem !important;
  margin-right: 0.1rem !important;
  margin-top: 0.1rem;
  animation: scaleDraw 2.5s linear infinite;
}

@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: scale(1); /*开始为原始大小*/
  }
  25% {
    transform: scale(1.3); /*放大1.1倍*/
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.3);
  }
}

.vs {
  color: #1ef0ed;
  font-size: 0.35rem;
  font-weight: 700;
}

.sum_price {
  font-size: .4rem;
}

.jieshao {
  position: absolute;
  padding: 10px 5px;
  top: 10px;
  left: 40px;
  color: #1ef0ed;
  font-size: .3rem;
}

.fight-body-battleground-room {
  min-height: 7rem;
}
</style>

