const VPointer = () => {
	return 'cursor: url('+ require("@/assets/images/commom/Pointer.png") + '), auto;'
}
const BodyPointer = () => {
	return 'cursor: url(' + require('@/assets/images/commom/hand-pointer.png') + '), auto;'
}
export {
	VPointer,
	BodyPointer
}
