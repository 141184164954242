<!-- 个人中心 -->
<template>
  <div class="personal">
    <div class="index_title">个人中心</div>
    <div class="personalUser">
      <div class="back message">
        <!--  设置用户头像 -->
        <div class="headPortrait a">
          <img v-if="imageUrl" class="head-img" :src="imageUrl" alt=""/>
          <img v-else class="head-img" :src="personalData.head_img" alt=""/>
          <el-upload class="avatar-uploader uploadPhoto" action="https://api.n7buff.com/api/upload/do_upload_img"
                     :on-error="handleAvatarErr" :show-file-list="false" :on-success="handleAvatarSuccess"
                     :before-upload="beforeAvatarUpload">
            <!-- <i class="el-icon-upload avatar-uploader-icon"></i> -->
            <i>上传</i>
          </el-upload>
        </div>

        <!-- 用户信息 -->
        <div class="personalBox">
          <div class="cellNumber">
            <div v-if="!IDredact">昵称: {{ personalData.nike_name }}</div>
            <div class="redact" v-if="!IDredact" @click="redactID">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
                   class="bi bi-pencil" viewBox="0 -1 16 16">
                <path
                  d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
              </svg>
              编辑
            </div>
            <div v-if="IDredact">
              <input type="text" class="personalC-inp" v-model="nikeName"/>
            </div>
            <div class="confirm" v-if="IDredact" @click="SetNickname">确定</div>
            <div class="confirm" v-if="IDredact" @click="CloseNickname">取消</div>
          </div>
          <div class="personalC">
            <div>注册账号: {{ personalData.mobile }}</div>
          </div>
        </div>
      </div>

      <!-- <div class="Steam">
        (1) 获取你的Steam交易链接
        <span class="sss">点击这里</span><br />
        (2) 打不开Steam怎么办?
        <span class="sss">点击看教程</span>
      </div> -->
      <!-- 账号管理 -->
      <div class="back management">
        <div class="management-head">账号管理</div>
        <div class="managementC">
          <!-- <div class="postbox">
            邮箱绑定：<input type="text" placeholder="请输入邮箱" />
            <div class="confirm">确定</div>
          </div> -->
          <!-- <div class="bor"></div> -->
          <div class="amend">
            修改密码：<input type="text" placeholder="修改密码" v-model="set_password"/>
            <div class="confirm" @click="setPassword">确定</div>
          </div>
        </div>
      </div>


      <!-- 邀请 -->
      <div class="back invite">
        <div class="invite-head">邀请</div>
        <div class="inviteC">
          <div class="my">
            我的邀请码：<input type="text" placeholder="请输入邀请码" v-model="personalData.invite_code"/>
            <!-- <div class="confirm">修改</div> -->
          </div>
          <div class="bor"></div>
          <div class="binding">
            绑定邀请码：<input type="text" :disabled="Bound" placeholder="请输入绑定的邀请码"
                              v-model="personalData.parent_code"/>
            <div class="confirm" v-if="!Bound" @click="bindParentCodeApi">确定</div>
            <div class="confirm" v-if="Bound" @click="bindParentCodeApi">已绑定</div>
          </div>
        </div>
      </div>

      <!-- 交易链接 -->
      <div class="back TradeLinks">
        <div class="TradeLinks-head">Steam<br/>交易链接</div>
        <div class="Trade">
          <div class="TradeC">
            <div>
              <input type="text" placeholder="请输入Steam交易链接" class="TradeC-inp"
                     v-model="personalData.trade_url"/>
            </div>
            <div class="confirm" @click="bindSteamApi">确定</div>
          </div>
        </div>
      </div>


      <!-- 偏好设置 -->
      <!-- 	<div class="back set">
          <div class="set-head">偏好设置</div>
          <div class="setC">
            <div>
              <el-radio class="radio" v-model="$store.state.Audio" :label="true" @change="openShowSound">开启声音
              </el-radio>
            </div>
            <div>
              <el-radio v-model="$store.state.Audio" :label="false" @change="showSound">关闭声音</el-radio>
            </div>
          </div>
        </div> -->


      <!-- 实名认证 -->
      <div class="back authentication">
        <div class="authentication-head">实名认证</div>
        <div class="authenticationC">
          <div class="realName">
            真实姓名：<input type="text" placeholder="请输入真实姓名" v-model="nameAuthentication.name"/>
          </div>
          <div class="bor"></div>
          <div class="n">
            身份证号：<input type="text" placeholder="请输入身份证号号码" v-model="nameAuthentication.idcard"/>
            <div class="confirm" @click="authenticationApi">确定</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bot">
      <div class="logOut">
        <div @click="logOut">退出登录</div>
      </div>
      <div class="return" @click="user_return()">
        <div>返回</div>
      </div>
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="90%"
    >
      <div slot="title">
        <div class="user-shiren-title">
          <p>实人认证</p>
          <div class="closebut" @click="dialogVisible = false" :style="VPointer()">
            <img src="@/assets/images/userWidget/close.png" alt="" />
          </div>
        </div>
      </div>

      <div class="user-shiren">
        <div id="qrcode" v-if="dialogVisible" ref="qrCodeUrl"></div>
        <span>请截图保存<br>并使用支付宝扫码进行实人认证</span>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import {
  do_upload_img,
  set_avar,
  get_player_info,
  set_nike_name,
  set_password,
  authentication,
  bind_parent_code,
  bind_steam
} from '@/api/index/personalCenter'
import {
  isAuthentication
} from '@/utils/validate'
import {Loading} from 'element-ui';
import {
  signOut
} from '@/api/index/logIn'
import axios from 'axios';
import QRCode from "qrcodejs2";
let loadingInstance = null;

export default {
  name: 'personalUser',
  data() {
    return {
      Audio: null,
      imageUrl: '',
      IDredact: false,
      nikeName: '', // 设置昵称
      set_password: '', // 密码编辑
      //实名认证
      nameAuthentication: {
        name: null,
        idcard: null
      },
      personalData: {},
      Bound: false, // 是否绑定邀请码
      dialogVisible: false,
      timeDown: null
    }
  },
  created() {
    if (sessionStorage.getItem('n7_token')) {
      this.$store.dispatch('getPlayerApi')
    }
    this.get_player_infoApi()
    //console.log(this.$store.state.Audio)
  },
  methods: {
    handleAvatarErr() {
      loadingInstance.close();
    },
    // 获取个人资料
    get_player_infoApi() {
      get_player_info().then((res) => {
        //console.log(res)
        this.personalData = res.data
        if (res.data.authentication_status === 2) {
          if (this.timeDown !== null) {
            this.$message.success('实名认证成功！')
            this.dialogVisible = false
          }
          this.nameAuthentication.name = res.data.name
          this.nameAuthentication.idcard = res.data.idcard
        } else  {
          if (this.timeDown !== null) {
            this.$message.error('实名认证超时，请重新发起认证！')
            this.dialogVisible = false
          }
        }

        if (res.data.parent_code != '') {
          this.Bound = true
        }
        this.$store.dispatch('getPlayerApi')
      })
    },
    // 循环请求个人资料判断是否实人认证成功
    async each_get_player_infoApi() {
      this.timeDown = 1
      await new Promise(resolve => setTimeout(resolve, 15000));
      this.get_player_infoApi()
      this.dialogVisible = false
    },
    // 上传头像
    handleAvatarSuccess(res, file) {
      // console.log(file.raw)
      let fd = new FormData()
      fd.append('file', file.raw)
      do_upload_img(fd).then((res) => {
        // console.log(res.data.img_url)
        this.imageUrl = res.data.img_url
        this.$store.state.userInfo.head_img = res.data.img_url
        localStorage.setItem('img', res.data.img_url)
        //console.log(this.$store.state.userInfo.head_img)
        set_avar({
          head_img: res.data.img_url
        })
        loadingInstance.close();
      })
    },
    beforeAvatarUpload(file) {
      loadingInstance = Loading.service({fullscreen: true, background: "#000000c9", customClass: "loadingInstance"});
      let isJPG = true
      const isLt2M = file.size / 1024 / 1024 < 2
      let file_imgs = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
      //console.log("file.type "+file.type);
      if (!file_imgs.indexOf(file.type)) {
        isJPG = false
        this.$message.error('上传头像只能是图片格式!')
      }
      /* if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      } */
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
        loadingInstance.close();
      }
      return isJPG && isLt2M
    },
    // 昵称编辑开关
    redactID() {
      this.nikeName = this.personalData.nike_name
      this.IDredact = true
    },
    // 设置昵称
    SetNickname() {
      set_nike_name({
        nike_name: this.nikeName
      }).then((res) => {
        if (res.status === 1) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.IDredact = false
          this.get_player_infoApi()

        } else {
          this.$message.error(res.msg)
        }
      })
    },
    CloseNickname() {
      this.IDredact = false
    },
    // 登录密码编辑
    setPassword() {
      set_password({
        password: this.set_password
      }).then((res) => {
        if (res.status === 1) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.IDredact = false
          this.get_player_infoApi()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 实名认证
    authenticationApi() {
      if (this.nameAuthentication.name === null) {
        this.$message.error('真实姓名不能为空')
      } else {
        if (isAuthentication(this.nameAuthentication.idcard)) {

          loadingInstance = Loading.service({fullscreen: true, background: "#000000c9", customClass: "loadingInstance"});
          // https://ali.n7buff.com
          axios({
            method: 'post',
            url: process.env.NODE_ENV === 'development' ? '/plugunit/user/authentication' : 'https://ali.n7buff.com/plugunit/user/authentication',
            data: { ...this.nameAuthentication },
            headers: {
              'Authorization': `${this.$store.state.token}`
            },
            withCredentials: true,
          })
            .then(response => {
              loadingInstance.close();
              // 处理登录成功的情况
              const res = response.data
              if (res.status !== 4444 && res.status !== 1) {
                this.$message({
                  message: res.msg,
                  type: 'error',
                  duration: 3 * 1000
                })
                return
              }
              if (navigator.userAgent.indexOf('Html5Plus') == -1) {
                // alert("浏览器");
                window.location.href = res.data.real_url
                return
              } else {
                this.dialogVisible = true
                this.$nextTick(()=>{
                  new QRCode(this.$refs.qrCodeUrl, {
                    width: this.$refs.qrCodeUrl.offsetWidth, // 宽
                    height: this.$refs.qrCodeUrl.offsetHeight, // 高
                    text: res.data.real_url
                  })
                })
                this.each_get_player_infoApi()
              }

              console.log(res)
            }).catch(()=>{
            loadingInstance.close();
          })

          // authentication({
          //   ...this.nameAuthentication
          // }).then((res) => {
          //   if (res.status === 1) {
          //     this.$message({
          //       message: '修改成功',
          //       type: 'success'
          //     })
          //     this.IDredact = false
          //     this.get_player_infoApi()
          //   } else {
          //     this.$message.error(res.msg)
          //   }
          // })
        } else {
          if (this.nameAuthentication.idcard === null) {
            this.$message.error('身份证号码不能为空')
          } else {
            this.$message.error('请输入正确的身份证号码')
          }
        }
      }
    },
    // 绑定邀请码
    bindParentCodeApi() {
      bind_parent_code({
        parent_code: this.personalData.parent_code
      }).then((res) => {
        if (res.status === 1) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.IDredact = false
          this.get_player_infoApi()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 绑定steam交易链接
    bindSteamApi() {
      bind_steam({
        steam_url: this.personalData.trade_url
      }).then((res) => {
        if (res.status === 1) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.IDredact = false
          this.get_player_infoApi()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 声音开关
    // 开启声音
    openShowSound() {
      this.$store.dispatch('setAudio', true)
      //console.log(this.$store.state.Audio)
    },
    // 关闭声音
    showSound() {
      this.$store.dispatch('setAudio', false)
      //console.log(this.$store.state.Audio)
    },

    // 退出登录
    logOut() {
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        //type: 'warning'
      })
        .then(() => {

          /* this.$store.dispatch('logout')
          window.localStorage.removeItem('img'); */
          //window.localStorage.removeItem('key');
          signOut().then((res) => {
            if (res.status === 1) {
              /* this.$store.dispatch('logout')
                  localStorage.clear() */
              this.$store.dispatch('logout')
              window.localStorage.removeItem('img');
              this.$message({
                type: 'success',
                message: '退出成功!'
              })
              this.closes()
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              })
            }
          })


        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消退出'
          })
        })
    },

    user_return() {
      this.$router.push('/')
    }
  }
}
</script>
<style lang="scss" scoped>

::v-deep .el-dialog{
  //background: #1ef0ed;
  background-color: transparent;
  background-image: url("@/assets/images/news3.png") !important;
  background-size: 100% 100%;
}
.user-shiren-title{
  display: flex;
  align-items: center;
  justify-content: center;
  p{
    font-weight: bold;
    font-size: 0.6rem;
  }
  .closebut{
    position: absolute;
    right: 0;
  }
}
.user-shiren{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: .4rem;
  #qrcode{
    width: 4.4rem;
    height: 4.4rem;
    margin-bottom: .4rem;
    background: #ff0000;
    img{
      width: 100%;
      height: 100%;
    }
  }
  span{
    font-size: .4rem;
    color: red;
  }
}

// 引入css类
@import '@/styles/variables.scss';

.personal {
  margin-top: 30px;
  // background-color: aqua;
  width: 100%;

  // overflow: hidden;
  .personal_title {
    font-size: 0.5rem;
    font-weight: 700;
    margin-bottom: 0.2rem;
  }

  .personalUser {
    .back {
      margin: 0 auto;
      // background: url('@/assets/images/personalCenter/1.png') no-repeat;
      background-size: 100% 100%;
      width: 9rem;
      height: 100%;
      padding: 0.2rem 0;
      margin-top: 10px;
      border-bottom: 0.05rem solid #1FD1CF;
    }

    .message {
      // background-color: red;
      display: flex;
      align-items: center;

      .headPortrait {
        width: 1rem;
        // background-color: red;
        margin-right: 0.2rem;
        position: relative;
        font-size: 0.3rem;

        &:hover .uploadPhoto {
          display: block;
        }

        // .file {
        //   position: absolute;
        //   top: 0;
        //   left: 20px;
        //   width: 80px;
        //   height: 80px;
        //   background-color: rgba(255, 0, 0, 0);
        //   z-index: 1000;
        //   font-size: 0;
        //   border: none;
        // }
        img {
          width: 1rem;
          height: 1rem;
        }

        .uploadPhoto {
          font-size: 0.1rem;
          line-height: 1rem;
          display: none;
          width: 1rem;
          height: 1rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-color: rgba($color: #000000, $alpha: 0.5);
        }
      }

      .personalBox {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .cellNumber {
          display: flex;
          font-size: 0.3rem;

          .redact {
            border: 2px $theme solid;
            border-radius: 0.1rem;
            padding: 0 0.2rem;
            margin: 0 0.5rem;
            font-size: 0.3rem;

            svg {
              width: 0.4rem;
              vertical-align: bottom;
              margin-right: 0.2rem;
            }
          }

          .personalC-inp {
            // height: 25px;
            height: 0.5rem;
            background: none;
            outline: none;
            border: none;
            border: 2px $theme solid;
            padding: 0 0.1rem;
            color: #fff;
            font-size: 0.3rem;
          }

          .confirm {
            border: 2px $theme solid;
            border-radius: 0.1rem;
            padding: 0 0.2rem;
            margin: 0 0.2rem;
            font-size: 0.3rem;
            line-height: 0.5rem;
          }
        }

        .personalC {
          display: flex;
          margin-top: 0.2rem;
          font-size: 0.3rem;
        }
      }
    }

    // 交易链接
    .TradeLinks {
      @extend .message;
      background-size: 155%;

      .TradeLinks-head {
        @extend .headPortrait;
        color: $theme;
        // background-color: red;
        width: 1.5rem;
        text-align: left;
      }

      .Trade {
        .cellNumber {
          text-align: left;
        }

        .TradeC {
          display: flex;
          justify-content: space-between;

          // width: 850px;
          .TradeC-inp {
            font-size: 0.3rem;
            width: 5.6rem;
            height: 0.7rem;
            background: none;
            outline: none;
            border: none;
            // border: 2px $theme solid;
            padding: 0 0.1rem;
            color: #fff;
            //  background-color: red;
          }

          .confirm {
            height: 0.5rem;
            line-height: .5rem;
            border: 0.01302rem #1ef0ed solid;
            border-radius: 0.1rem;
            padding: 0 0.15rem;
            margin: 0 0.2rem;
            font-size: .3rem;
            //margin-left: 1.8rem;
          }
        }
      }
    }

    .Steam {
      font-size: 0.3rem;

      span {
        color: $theme;
      }
    }

    // 账号管理
    .management {
      @extend .message;
      background-size: 155% 100%;

      .management-head {
        @extend .headPortrait;
        color: $theme;
        width: 1.5rem;
        text-align: left;
      }

      .managementC {
        // width: 600px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .postbox {
          width: 7rem;
          display: flex;
          padding: 0.2rem 0;

          // justify-content: space-between;
          // border-bottom: 1px dashed #fff;
          input {
            font-size: 0.3rem;
            width: 4rem;
            height: 0.5rem;
            background: none;
            // background-color: aqua;
            outline: none;
            border: none;
            // border: 2px $theme solid;
            padding: 0 5px;
            color: #fff;
          }

          .confirm {
            height: 0.5rem;
            line-height: 0.5rem;
            border: 2px $theme solid;
            border-radius: 0.1rem;
            padding: 0px 0.15rem;
            margin: 0 0.1rem;
          }

          margin: 0.1rem 0;
        }

        .bor {
          width: 100%;
          height: 0.03rem;
          background: -webkit-linear-gradient(left,
            #000 -4%,
            $theme 50%,
            #000 100%);
        }

        .amend {
          @extend .postbox;
          font-size: 0.3rem;
        }
      }
    }

    // 偏好设置
    .set {
      @extend .message;
      background-size: 155% 100%;

      .set-head {
        @extend .headPortrait;
        color: $theme;
        width: 1.5rem;
        text-align: left;
      }

      .setC {
        display: flex;

        .radio {
          margin-right: 1rem;
        }
      }
    }

    // 邀请
    .invite {
      @extend .message;

      .invite-head {
        @extend .headPortrait;
        color: $theme;
        text-align: left;
        width: 1.5rem;
      }

      .inviteC {
        .my {
          display: flex;
          padding: 0.1rem 0;
          font-size: 0.3rem;

          input {
            width: 3.7rem;
            height: 0.5rem;
            background: none;
            outline: none;
            border: none;
            // border: 2px $theme solid;
            padding: 0 0.1rem;
            color: #fff;

            &:nth-child(1) {
              margin-bottom: 0.2rem;
            }
          }

          .confirm {
            height: 0.5rem;
            line-height: 0.5rem;
            border: 2px $theme solid;
            border-radius: 0.1rem;
            padding: 0px 0.15rem;
            margin: 0 0.2rem;
          }
        }

        .bor {
          width: 100%;
          height: 0.03rem;
          background: -webkit-linear-gradient(left,
            #000 -4%,
            $theme 50%,
            #000 100%);
        }

        .binding {
          @extend .my;
          margin-top: 0.1rem;
        }
      }
    }

    // 实名认证
    .authentication {
      @extend .message;
      background-size: 155% 100%;

      .authentication-head {
        @extend .headPortrait;
        color: $theme;
        width: 1.5rem;
        text-align: left;
      }

      .authenticationC {

        // display: flex;
        .realName {
          display: flex;
          padding: 0.1rem 0;
          font-size: 0.3rem;

          input {
            width: 4rem;
            height: 0.5rem;
            background: none;
            outline: none;
            border: none;
            // border: 2px $theme solid;
            padding: 0 0.1rem;
            color: #fff;
          }
        }

        .bor {
          width: 100%;
          height: 0.03rem;
          background: -webkit-linear-gradient(left,
            #000 -4%,
            $theme 50%,
            #000 100%);
        }

        .n {
          @extend .realName;
          position: relative;

          .confirm {
            position: absolute;
            top: -60%;
            right: -24%;
            // height: 50px;
            border: 2px $theme solid;
            border-radius: 0.1rem;
            padding: 0.1rem 0.2rem;
            margin: 0 0.1rem;
          }

          margin-top: 0.1rem;
        }
      }
    }
  }

  .bot {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-top: .4rem;
    padding: 0 .8rem;
  }

  .logOut {
    text-align: left;
    margin: 0.1rem 0 0 0.3rem;

    div {
      display: inline-block;
      border: 2px $theme solid;
      border-radius: 0.1rem;
      font-size: 0.3rem;
      padding: 0.1rem 0.15rem;
      // margin: 0 10px;
    }
  }

  .return {
    text-align: right;
    margin: 0.1rem 0.17rem 0 0;

    div {
      display: inline-block;
      border: 2px $theme solid;
      border-radius: 0.1rem;
      font-size: 0.3rem;
      padding: 0.1rem 0.15rem;
    }
  }
}
</style>
<style lang="less" scoped>
/deep/ .el-radio {
  //从这里开始 就是更改颜色的代码，将你的颜色           改掉我的颜色就可以了
  // .el-radio__label {
  //   color: #000 !important;
  // }
  .el-radio__inner {
    width: 0.3rem;
    height: 0.3rem;
  }

  .el-radio__input {
    margin-bottom: px(5);
    width: 0.3rem;
    height: 0.3rem;

    &.is-checked {
      .el-radio__inner {
        width: 0.3rem;
        height: 0.3rem;
        background-color: #28d4c1;
        border-color: #28d4c1;
      }
    }
  }
}
</style>
