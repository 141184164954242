<!-- 幸运饰品 -->
<template>
  <div class="app LuckyOrnament">
    <div class="index_title">幸运饰品</div>
    <div class="s"></div>
    <!-- 菜单分类 -->
    <MenuItems @skinListS="skinListS" />
    <!-- 全部商品 -->
    <div class="AllGoods"
       v-infinite-scroll="load" :infinite-scroll-distance="20" infinite-scroll-delay="200" style="overflow: auto;height:65vh;"
        :infinite-scroll-disabled="false">
      <ul v-if="skinList.length">
        <li
          v-for="item in skinList"
          :key="item.id"
          @click="LuckyOrnament(item.id)"
        >
          <div class="Goods">
            <div class="subject">
              <img :src="item.image_url" alt="" />
            </div>
            <div class="back">
              <img
                src="@/assets/images/fightImages/Photoefficiency.png"
                alt=""
              />
            </div>
            <el-tooltip placement="bottom">
              <div slot="content">{{ item.item_name }}</div>
              <div class="nameText">
                {{ item.item_name }}
              </div>
            </el-tooltip>
            <div class="price">
              <div class="img"></div>
              <div class="priceText">{{ item.price }}</div>
            </div>
          </div>
        </li>
      </ul>
       <div v-else class="noMore">
        <div>没有更多了</div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItems from '@/components/MenuItems.vue'
import { get_skin_list } from '@/api/index/LuckyAccessories'
export default {
  name: 'LuckyOrnament',
  data() {
    return {
	  all_img:require('@/assets/images/all.png'),
      skinList: [], // 饰品列表
      total_rows: null,
      page: 1,
      page_size: 10,
      page_count: null,
      skin_category_id: null, // 一级id
      skin_category_sub_id: null, // 二级id
      quality_id: null, // 类别id
      rarity_id: null, // 品质id
      exterior_id: null, // 外观id
      lucky_recomm: 0, // 是否推荐
      start_price: '',
      end_price: ''
    }
  },
  components: {
    MenuItems
  },
  created() {
						if (sessionStorage.getItem('n7_token')) {
							this.$store.dispatch('getPlayerApi')
						}
    this.getSkinList()
  },
  mounted() {
    // window.addEventListener('scroll', this.getbottom)
  },
  methods: {
    load(){
        if (this.page != this.page_count) {
          this.page++
          this.getSkinList()
        }
    },
    // getbottom() {
    //   // 返回滚动条垂直滚动距离
    //   let scrollTop =
    //     document.documentElement.scrollTop || document.body.scrollTop
    //   // 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
    //   let clientHeight =
    //     document.documentElement.clientHeight || document.body.clientHeight
    //   // 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
    //   let scrollHeight =
    //     document.documentElement.scrollHeight || document.body.scrollHeight
    //   let numHeight = scrollTop + clientHeight
    //   if (scrollHeight > clientHeight && numHeight > scrollHeight - 1) {
    //     if (this.page != this.page_count) {
    //       this.page++
    //       this.getSkinList()
    //     }
    //   }
    // },
    // 子传父获取
    skinListS(skinList,parmas,total_rows) {
        this.nameAccessories=parmas.name
        this.skin_category_id=parmas.skin_category_id
        this.skin_category_sub_id=parmas.skin_category_sub_id
        this.quality_id=parmas.quality_id
        this.rarity_id=parmas.rarity_id
        this.exterior_id=parmas.exterior_id
        this.start_price=parmas.start_price
        this.end_price=parmas.end_price
        this.lucky_recomm=parmas.lucky_recomm
        this.total_rows = total_rows
        this.skinList = skinList
    },
    // 获取饰品列表
    async getSkinList() {
      const res = await get_skin_list({
        page: this.page,
        page_size: 10,
        name: this.nameAccessories,
        skin_category_id: this.skin_category_id,
        skin_category_sub_id: this.skin_category_sub_id,
        quality_id: this.quality_id,
        rarity_id: this.rarity_id,
        exterior_id: this.exterior_id,
        lucky_recomm: this.lucky_recomm,
        start_price: parseInt(this.start_price),
        end_price: parseInt(this.end_price)
      })
      this.total_rows = res.data.total_rows
      this.page_count = res.data.page_count
      res.data.rows.forEach((item) => {
        this.skinList.push(item)
      })
    },
    // 点击商品
    LuckyOrnament(id) {
			// 判断用户是否登录
			/* if (!sessionStorage.getItem('n7_token')) {
				 this.$store.dispatch('setLogSignRetrieve', true)
				 return
			} */
      this.$router.push({ path: '/OrnamentOpen', query: { id: id } })
    },
    // 加载更多
    loadMore() {
      this.page_size = this.page_size + 10
      this.getSkinList()
    }
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
@import '../../styles/LuckyOrnament.scss';
@import '@/styles/MenuItems';
.noMore{
  text-align: center;
  position: absolute;
  top: 50%;
  left: calc(50% + .7rem);

  transform: translate(-50%,-50%);
  div{
    color: #999;
  }
}
.priceText{
  font-size: .3rem;
}
</style>
