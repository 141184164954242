<!-- 开箱 -->
<template>
  <div class="home">
    <div class="home-body">
      <BoxShowBodyOpen :boxData="boxData" />
      <BoxShowBodyIn :skinsList="boxData.skins" />
    </div>
  </div>
</template>

<script>
import BoxShowBodyOpen from './BoxShowBodyOpen.vue'
import BoxShowBodyIn from './BoxShowBodyIn.vue'
import { get_box_data } from '@/api/index/boxCorrelation'
export default {
  name: 'K5Home',
  components: {
    BoxShowBodyOpen,
    BoxShowBodyIn
  },
  data() {
    return {
      boxData: {}
    }
  },
  created() {
						if (sessionStorage.getItem('n7_token')) {
							this.$store.dispatch('getPlayerApi')
						}
    this.getBoxDataApi()
  },
  methods: {
    async getBoxDataApi() {
      const res = await get_box_data({ id: this.$route.query.id })
      this.boxData = res.data
      //console.log(this.boxData)
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.home{
  margin-top: 0.2rem;
}
</style>
