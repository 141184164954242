<!-- 箱子详情 -->
<template>
  <div
    class="CaseDetails"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="CaseDetails-title">
      <i>{{ battleBoxData.name }}</i>
    </div>
    <div class="CaseDetails-body">
      <div class="gb" :style="VPointer()" @click="off">
        <img src="@/assets/images/fightImages/close.png" alt="" />
      </div>
      <div class="listBox">
        <ul>
          <li v-for="item in battleBoxData.skins" :key="item.id">
            <div class="back"><img :src="item.rarity_thumb" alt="" /></div>
            <div class="box">
              <div class="main"><img :src="item.image_url" alt="" /></div>

              <el-tooltip placement="bottom">
                <div slot="content">{{ item.item_name }}</div>
                <div class="name">{{ item.item_name }}</div>
              </el-tooltip>
              <!-- <el-table-column class="name" :show-overflow-tooltip="true">{{item.item_name}}</el-table-column> -->
              <div class="price" style="width: 90%;">
                <span class="priceTow"
                  ><img src="@/assets/images/n7_money.png" alt="" />
                  {{ item.price }}</span
                >
                <span class="probability">{{ (item.probability * 1 ).toFixed(5) }}%</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CaseDetails',
  data() {
    return {}
  },
  props: {
    visible: Boolean,
    battleBoxData: Object
  },
  created() {
    //console.log(this.battleBoxData)
  },
  methods: {
    off() {
      this.$emit('update:visible', false)
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' //出现滚动条
      document.removeEventListener('touchmove', mo, false)
    }
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
// @import '';
.CaseDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  // top: 0;
  &-title {
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    width: 8rem;
    height: 1.6rem;
    background: url('@/assets/images/fightImages/box-in.png') no-repeat;
    background-size: 100%;
  }

  &-body {
    width: 9rem;
    height: 9rem;
    background: url('https://oss.n7buff.com/mobile/assets/images/userWidget/frame.png') no-repeat;
    background-size: 100% 100%;
    .gb {
      position: absolute;
      top: 15%;
      right: 0.4rem;
      img{
        width: 1rem;
      }
    }
    .listBox {
      overflow: auto;
      width: 90%;
      height: 7.2rem;
      margin: auto;
      margin-top: 1rem;
    }
    .listBox::-webkit-scrollbar {
      width: 0.2rem;
      /*height: 4px;*/
    }
    .listBox::-webkit-scrollbar-thumb {
      border-radius: 0.1rem;
      -webkit-box-shadow: inset 0 0 5px #1ef0ed;
      background: rgba(0, 0, 0, 0.2);
    }
    .listBox::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px #1ef0ec65;
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0.4rem 0.4rem;
      li {
        width: 3.5rem;
        height: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        margin: auto;
        margin-bottom: 0.3rem;
        .back {
          height: 3rem;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 80;
          img {
            width: 3.5rem;
            height: 100%;
          }
        }
        .box {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          z-index: 90;
          .main {
            img {
              margin-top: 0.2rem;
              width: 2rem;
            }
          }
          .name {
            font-size: 0.3rem;
            position: absolute;
            top: 58%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 2rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: 0.1rem;
          }
          .price {
            width: 80%;
            font-size: .2rem;
            img {
              width: 0.3rem;
              // margin-right: 10px;
              vertical-align: bottom;
            }
            .probability {
              // margin-left: 10px;
              float: right;
            }
            .priceTow {
              float: left;
            }
          }
        }
      }
    }
  }
}

</style>
