<template>
<!--  -->
	<div id="portrait" ref="portrait" :class="{'active' : open_key }">
		<div class="animationy_back">
			<div class="animationy_main" v-if="volutionList.length > 0">
				<div class="animationy_box initial" v-for="(item,index) in volutionList" :key="index" ref="sound" @transitionend="Transitionend">
					<div class="animationy_item" :style="item.rarity_thumb | OpenBack" v-for="(item,index) in volutionList[index]" style="background-image: url({item.rarity_thumb})" :key="index">
						<img :src="item.image_url" alt="">
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name:'portrait',
		props:{
			goodsList:{
				type:Array
			},
			prize_data:{
				type:Object
			}
		},
		data(){
			return{
				open_key:false,              //显示/隐藏
				soundStyleArr:[              //10个不同过渡
					'animationy_box soundone',
					'animationy_box soundtwo',
					'animationy_box soundthree',
					'animationy_box soundfour',
					'animationy_box soundfive',
					'animationy_box soundsix',
					'animationy_box soundseven',
					'animationy_box soundeight',
					'animationy_box soundnine',
					'animationy_box soundten'
				],
				concat_num:0,                 //拼接数量
				volutionList:[],              //处理后的数据
				process:new Audio('https://oss.n7buff.com/mobile/audio/roll9.mp3'),//过程声音
				Transitionend_arr:[],//奖品展示所用凭证
			}
		},
		mounted() {
			this.prohibit()
		},
		methods:{
			//拼接并打乱数组
			Arraysplicing(data,str) {
				let num = data.length
				this.volutionList = []
				let expect = 34 - this.goodsList.length
				if(expect >= 0){
					this.concat_num  = (Math.floor(expect / this.goodsList.length) + 2)
				}else{
					this.concat_num = 1
				}


				for(let k = 0; k < num; k ++){
					let A_Arr = []
					for (let i = 0; i < this.concat_num ; i++) {
						A_Arr = A_Arr.concat(this.goodsList)
					}
					//打乱
					function randSort(A_Arr) {
						for (let i = 0, len = A_Arr.length; i < len; i++) {
							let rand = parseInt(Math.random() * len)
							let temp = A_Arr[rand]

							A_Arr[rand] = A_Arr[i]
							A_Arr[i] = temp
						}
						return A_Arr
					}
					//插入
					this.$nextTick(()=>{
						this.volutionList.push(randSort(A_Arr))
						if(this.volutionList.length == num){
							this.$emit('finishCallback',data,str)
						}
					})

				}
			},



			//处理中奖
			Determine(){
				this.$nextTick(() => {
					for(let k = 0; k < this.prize_data.list.length; k++){
						this.volutionList[k][0] = this.prize_data.list[k]
						this.$refs.sound[k].className = this.soundStyleArr[Math.floor(Math.random()*(9-0+1)+0)]
					}
					this.$forceUpdate()
					this.open_key = true
					this.process.play()
				})
			},

			Transitionend(){
				// console.log('存在')
				this.Transitionend_arr.push(true)
					// console.log('结束',this.Transitionend_arr.length)
				if (this.Transitionend_arr.length == this.$refs.sound.length) {
					setTimeout(() => {
						this.end()
					},500)
				}
			},

			//结束
			end(){
				this.open_key = false
				this.$emit('end')
				setTimeout(()=>{
					for(let k = 0; k < this.$refs.sound.length; k++){
						this.$refs.sound[k].className = 'animationy_box initial'
					}
					this.Transitionend_arr = []
				},200)
			},

			//弹出后禁止屏幕滑动
			prohibit(){
				this.$refs.portrait.addEventListener('touchmove', function (event) {
				       event.preventDefault()
				})
			},
		},
		watch:{
			prize_data(v){
				// console.log(v)
				if(v.show == 'portrait') this.Determine()
			}
		}
	}
</script>

<style lang="scss">
	#portrait{
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		z-index: -1;
		background-color: rgba($color: #000000, $alpha: .8);
		opacity: 0;
		transition: .3s;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-bottom: 100px;
		box-sizing: border-box;
		&.active{
			z-index: 1000;
			opacity: 1;
		}
		.animationy_back{
			width: 1500px;
			height: 480px;
			background: url(@/assets/images/showImages/bak2.png) no-repeat center;
			background-size: contain;
			position: relative;
			padding: 0 50px;
			box-sizing: border-box;
			&::after{
				display: block;
				content: '';
				width: 1440px;
				height: 32px;
				background: url('@/assets/images/showImages/line_y.png') no-repeat center;
				background-size: contain;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translateX(-50%) translateY(-50%);

			}
			.animationy_main{
				width: 1400px;
				height: 280px;
				margin-top:100px;
				box-sizing: border-box;
				overflow: hidden;
				display: flex;

				.animationy_box{
					flex: 1;
					&.soundone{
						transition:9s cubic-bezier(0.2,0.29,0.15,.91);
						transform: translateY(0);
					}
					&.soundtwo{
						transition:9s cubic-bezier(0.2,0.3,0.14,.92);
						transform: translateY(0);
					}
					&.soundthree{
						transition:9s cubic-bezier(0.2,0.31,0.15,1);
						transform: translateY(0);
					}
					&.soundfour{
						transition:9s cubic-bezier(0.2,0.32,0.14,.89);
						transform: translateY(0);
					}
					&.soundfive{
						transition:9s cubic-bezier(0.2,0.33,0.15,.9);
						transform: translateY(0);
					}
					&.soundsix{
						transition:9s cubic-bezier(0.2,0.32,0.14,.98);
						transform: translateY(0);
					}
					&.soundseven{
						transition:9s cubic-bezier(0.2,0.31,0.15,.95);
						transform: translateY(0);
					}
					&.soundeight{
						transition:9s cubic-bezier(0.2,0.3,0.14,.96);
						transform: translateY(0);
					}
					&.soundnine{
						transition:9s cubic-bezier(0.2,0.29,0.14,.92);
						transform: translateY(0);
					}
					&.soundten{
						transition:9s cubic-bezier(0.2,0.3,0.15,.97);
						transform: translateY(0);
					}

					&.initial{
						transform: translateY(-4620px);
						transition: none;
					}

					.animationy_item{
						width: 100%;
						height: 280px;
						background: url(https://95-csgo.oss-cn-beijing.aliyuncs.com/cache/images/open/add.png);
						background-size: 100% 100%;
						background-repeat: no-repeat;
						background-position: center;
						position: relative;
						img{
							max-width: 80%;
							max-height: 80%;
							position: absolute;
							left: 0;
							right: 0;
							top: 0;
							bottom: 0;
							margin: auto;
						}
					}
				}
			}
		}
	}
</style>
