<!-- 玩法介绍 -->
<template>
  <div
    class="int"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="int-box">
      <div class="int-box-title">
        <div class="int-box-title-text">玩法介绍</div>
      </div>
      <div class="int-box-frame">
        <div class="int-box-frame-off" :style="VPointer()" @click="off"></div>
        <div class="int-box-frame-content">
          <!-- <div class="int-box-frame-content-head">
            {{ playIntroduction.title }}
          </div> -->
          <div class="int-box-frame-contents">
            <div v-html="playIntroduction.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_play_introduction } from '@/api/index/BlindBattle'
export default {
  name: 'GamePlayIntroduced',
  data() {
    return {
      playIntroduction: {}
    }
  },
  props: {
    visible: Boolean
  },
  watch: {
    visible(val) {
      if (val) {
        this.getPlayIntroductionApi()
      }
    }
  },
  created() {},
  methods: {
    // 盲盒对战-玩法介绍
    getPlayIntroductionApi() {
      get_play_introduction().then((res) => {
        //console.log(res)
        this.playIntroduction = res.data
      })
    },
    // 关闭
    off() {
      this.$emit('update:visible', false)
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' //出现滚动条
      document.removeEventListener('touchmove', mo, false)
    }
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
@import '@/styles/BlindBoxFightShow.scss';
.int-box-frame-content-head{
  color: #1ef0ed;
  font-size: .6rem;
}
.int{
  width: 8rem;
}
.int-box-frame{
  padding-top: .3rem;
  padding-bottom: .3rem;
  height: auto;
}
.int-box-frame-contents{
  height: 8rem;
  overflow-y: auto;
}
</style>
