import request from '@/api/request'
// 获取个人余额信息
export const get_player = (data) => {
	return request({
		url: '/api/user/get_player',
		method: 'POST',
		data
	})
}

// 设置头像--上传头像文件
export const do_upload_img = (data) => {
	return request({
		url: '/api/upload/do_upload_img',
		method: 'POST',
		data
	})
}

// 设置头像--保存头像地址
export const set_avar = (data) => {
	return request({
		url: '/api/user/set_avar',
		method: 'POST',
		data
	})
}

// 设置昵称
export const set_nike_name = (data) => {
	return request({
		url: '/api/user/set_nike_name',
		method: 'POST',
		data
	})
}

// 获取个人资料
export const get_player_info = (data) => {
	return request({
		url: '/api/user/get_player_info',
		method: 'POST',
		data
	})
}

// 登录密码编辑
export const set_password = (data) => {
	return request({
		url: '/api/user/set_password',
		method: 'POST',
		data
	})
}

// 实名认证
export const authentication = (data) => {
	return request({
		url: '/api/user/authentication',
		method: 'POST',
		data
	})
}

// 绑定邀请码
export const bind_parent_code = (data) => {
	return request({
		url: '/api/user/bind_parent_code',
		method: 'POST',
		data
	})
}

// 绑定steam交易链接
export const bind_steam = (data) => {
	return request({
		url: '/api/user/bind_steam',
		method: 'POST',
		data
	})
}

// 我的背包
export const get_package_list = (data) => {
	return request({
		url: '/api/player_package/get_package_list',
		method: 'POST',
		data
	})
}

//星空商城 背包
export const get_star_package_list = (data) => {
	return request({
		url: '/api/player_package/get_star_package_list',
		method: 'POST',
		data
	})
}

// 个人明细
export const get_list = (data) => {
	return request({
		url: '/api/player_wallet_history/get_list',
		method: 'POST',
		data
	})
}

//我的积分日志
export const get_player_integral_history = (data) => {
	return request({
		url: '/api/player_integral_history/get_list',
		method: 'POST',
		data
	})
}
//我的星币日志
export const get_player_star_history = (data) => {
	return request({
		url: '/api/player_star_history/get_list',
		method: 'POST',
		data
	})
}

// 推广分红-列表
export const rechargeGet_list = (data) => {
	return request({
		url: '/api/recharge_commission/get_list',
		method: 'POST',
		data
	})
}

// 推广分红-单一提取
export const retrieve_commission = (data) => {
	return request({
		url: '/api/recharge_commission/retrieve_commission',
		method: 'POST',
		data
	})
}

// 推广分红-全部提取
export const all_retrieve_commission = (data) => {
	return request({
		url: '/api/recharge_commission/all_retrieve_commission',
		method: 'POST',
		data
	})
}

// 推广分红-我的收益
export const get_extension_info = (data) => {
	return request({
		url: '/api/user/get_extension_info',
		method: 'POST',
		data
	})
}



// 饰品回收
export const recovery_skins = (data) => {
	return request({
		url: '/api/player_package/recovery_skins',
		method: 'POST',
		data
	})
}
//饰品兑换星空币
export const exchange_star_skins = (data) => {
	return request({
		url: '/api/player_package/exchange_star_skins',
		method: 'POST',
		data
	})
}

// 饰品提取
export const skin_to_steam = (data) => {
	return request({
		url: '/api/player_package/skin_to_steam',
		method: 'POST',
		data
	})
}

// 饰品取消提取
export const buyer_cancel = (data) => {
	return request({
		url: '/api/player_package/buyer_cancel',
		method: 'POST',
		data
	})
}

// 获取token令牌
export const get_token = (data) => {
	return request({
		url: '/api/token/get_token',
		method: 'POST',
		data
	})
}

// 站内信息
export const player_mail = (data) => {
	return request({
		url: '/api/player_mail/get_list',
		method: 'POST',
		data
	})
}

// 站内信息-修改已读
export const get_player_mail = (data) => {
	return request({
		url: '/api/player_mail/get_player_mail',
		method: 'POST',
		data
	})
}

// 站内信息-删除已读
export const del_player_mail = (data) => {
	return request({
		url: '/api/player_mail/del_player_mail',
		method: 'POST',
		data
	})
}

// 红包领取
export const get_red = (data) => {
	return request({
		url: '/api/red_code/red_envelope_receiving',
		method: 'POST',
		data
	})
}

//联系我们
export const get_contact_us = (data) => {
	return request({
		url: '/api/notice/get_contact_us',
		method: 'POST',
		data
	})
}

// 饰品记录
export const jewe_lry_name = (data) => {
	return request({
		url: '/api/player_package/get_rucksack_list',
		method: 'POST',
		data
	})
}
