var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"battleDetail"}},[_c('navigation',{attrs:{"battleBoxList":_vm.battleBoxList,"battleBoxSumPrice":_vm.battleBoxSumPrice,"roomStatus":_vm.roomStatus,"innerStatus":_vm.innerStatus,"start":_vm.start,"battle_type":_vm.battle_type},on:{"Refresh_room":_vm.Refresh_room,"tocopyRoom":_vm.tocopyRoom}}),(_vm.battle_type != 3)?_c('div',{staticClass:"rooms"},_vm._l((_vm.playerList),function(item,index){return _c('RoomF',{key:index,class:[
        { Double: _vm.playerList.length == 2 },
        { Three: _vm.playerList.length == 3 },
        { Four: _vm.playerList.length == 4 }
      ],attrs:{"start":_vm.start,"battleBoxSumPrice":_vm.battleBoxSumPrice,"wid":_vm.playerList.length,"bill_data":item,"roomStatus":_vm.roomStatus,"roomCount":_vm.roomCount,"battleBoxList":_vm.battleBoxList,"Draw_prize":_vm.Draw_prize},on:{"moveData":_vm.moveData,"join_Room":_vm.Refresh_room,"slideEnd":_vm.slideEnd,"complete":_vm.complete}})}),1):_vm._e(),(_vm.battle_type == 3)?_c('div',{staticClass:"rooms"},[_c('div',{staticClass:"rooms-buld"},_vm._l((_vm.playerList.slice(0,2)),function(item,index){return _c('TeamUp',{key:index,class:[
        { Double: _vm.playerList.length == 2 },
        { Three: _vm.playerList.length == 3 },
        { Four: _vm.playerList.length == 4 }
      ],attrs:{"start":_vm.start,"battleBoxSumPrice":_vm.battleBoxSumPrice,"wid":_vm.playerList.length,"ranks":'buld',"bill_data":item,"roomStatus":_vm.roomStatus,"roomCount":_vm.roomCount,"battleBoxList":_vm.battleBoxList,"Draw_prize":_vm.Draw_prize},on:{"moveData":_vm.moveData,"join_Room":_vm.Refresh_room,"slideEnd":_vm.slideEnd,"complete":_vm.complete}})}),1),_c('div',{staticClass:"rooms-red"},_vm._l((_vm.playerList.slice(2,4)),function(item,index){return _c('TeamUp',{key:index,class:[
        { Double: _vm.playerList.length == 2 },
        { Three: _vm.playerList.length == 3 },
        { Four: _vm.playerList.length == 4 }
      ],attrs:{"start":_vm.start,"battleBoxSumPrice":_vm.battleBoxSumPrice,"wid":_vm.playerList.length,"bill_data":item,"ranks":'red',"roomStatus":_vm.roomStatus,"roomCount":_vm.roomCount,"battleBoxList":_vm.battleBoxList,"Draw_prize":_vm.Draw_prize},on:{"moveData":_vm.moveData,"join_Room":_vm.Refresh_room,"slideEnd":_vm.slideEnd,"complete":_vm.complete}})}),1)]):_vm._e(),_c('onlineList')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }