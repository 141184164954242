<!-- 我的背包 -->
<template>
	<div class="MyBackpackBox">
		<div class="app">
			<div class="index_title">获取星币</div>
			<div class="MyBackpack">
				<div class="sort hnk">
					<el-dropdown class="show" trigger="click" placement="bottom">
					  <div class="el-dropdown-link" :style="VPointer()">
					    {{ sortText.sort.sort }}<i class="el-icon-arrow-down el-icon--right"></i>
					  </div>
					  <el-dropdown-menu
					    class="dropdownMenu appearance-menus"
					    slot="dropdown"
					  >
					    <el-dropdown-item
					      :style="VPointer()"
					      v-for="(item, index) in sortText.conceal"
					      :key="item.id"
					    >
					      <div @click="concealA(index)">
					        {{ item.conceal }}
					      </div>
					    </el-dropdown-item>
					  </el-dropdown-menu>
					</el-dropdown>
				</div>
				<div class="search hnk">
					<input placeholder="请输入物品名称"  v-model="search_key" />
					<div class="searchIco" @click="search_key_sumbit()">
						<i class="el-icon-search"></i>
					</div>
				</div>
				<div class="recycle">
					<div>
						星币：{{$store.state.userInfo.total_star ? $store.state.userInfo.total_star : 0.00}}
					</div>
					<div @click="recoverySkinsApi">兑换星币</div>
				</div>
			</div>
			<div class="MyBackpackSelect">
				<div class="skins_total">总数:{{ this.total_rows }}</div>
				<div class="price_sum">总价值:{{ this.price_sum }}</div>


				<div class="checkAll">
					<el-checkbox type="checkbox" v-model="checkAll" @change="checkAllCheck" />
					全选
				</div>
				<div class="selected">已选:{{ checkList.length }}</div>
			</div>
		</div>
		<div class="" style="height:2.8rem"></div>

		<!-- 背包物品 -->
		<div class="BackpackItems">
			<ul :class="quantity > 13 ? 'roll' : ''">
				<li v-for="item in rowsList" :key="item.id" @click.stop.prevent="checkBox(item.id)" :style="[
            {
              'background-image': 'url(' + item.rarity_thumb + ')'
            }
          ]">
					<img class="xz" v-if="item.checked" src="@/assets/images/xz.png" alt="">

					<div class="underway" v-if="item.state_name !== null">
						{{ item.state_name }}...
					</div>
					<div class="subject">
						<img :src="item.image_url" alt="" />
					</div>

					<el-tooltip placement="bottom">
						<div slot="content">{{ item.item_name }}</div>
						<div class="subjectName">
							{{ item.item_name }}
						</div>
					</el-tooltip>
					<div class="fool">
						<div class="price">
							<img src="https://oss.N7buff.com/star_currency.png" alt="" /> {{ item.price }}
						</div>
						<!-- <el-checkbox-group class="pitch" v-model="checkList">
              <el-checkbox :label="item.id"> 选中</el-checkbox>
            </el-checkbox-group> -->
					</div>
				</li>
				<!-- <div class="loadMore">
          <button class="btn" @click="handleCurrentChange">上一页</button>
          <button class="btn_num">{{ page }}</button>
          <button class="btn" @click="handleCurrentChangeTwo">下一页</button>
        </div> -->
			</ul>
		</div>
	</div>
</template>

<script>
	import {
		get_star_package_list,
		exchange_star_skins,
		get_token
	} from '@/api/index/personalCenter'
	export default {
		name: 'MyBackpack',
		data() {
			return {
				sort: false,
				sortText: {
					TS: '',
					sort: {
						sorts: 'time',
						sort: '按获取时间排序'
					},
					conceal: [{
							sorts: 'time',
							conceal: '按获取时间排序'
						},
						{
							sorts: 'high_price',
							conceal: '按价格由高到低排序'
						},
						{
							sorts: 'low_price',
							conceal: '按价格由低到高排序'
						}
					]
				},
				sorts: 'time', // 排序
				search_key: '', // 饰品名称关键词搜索
				price_sum:0, //总价值
				page: 1, // 页码
				page_size: 12, // 每页请求数量
				total_rows: null, // 总数据量
				page_count: null, //总页数
				checkAll: false, // 全选
				quantity: 13,
				rowsList: [], //背包物品
				checkList: [], // 选中的id
				token: '', //token令牌
				timeOut: {}
			}
		},
		created() {
			if (sessionStorage.getItem('n7_token')) {
				this.$store.dispatch('getPlayerApi')
			}
			this.getTokenApi()
			this.getPackageListApi()
			this.page = '1'
		},
		mounted() {
			window.addEventListener('scroll', this.getbottom)
		},
		watch: {},
		methods: {
			getbottom() {
				// 返回滚动条垂直滚动距离
				let scrollTop =
					document.documentElement.scrollTop || document.body.scrollTop
				// 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
				let clientHeight =
					document.documentElement.clientHeight || document.body.clientHeight
				// 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
				let scrollHeight =
					document.documentElement.scrollHeight || document.body.scrollHeight
				let numHeight = scrollTop + clientHeight
				if (scrollHeight > clientHeight && numHeight > scrollHeight - 1) {
					if (this.page != this.page_count) {
						this.page++
						this.getPackageListApi()
					}
				}
			},
			// 下拉菜单开关
			sortShow() {
				if (!this.sort) {
					this.sort = true
				} else {
					this.sort = false
				}
			},
			onblur() {
				this.sort = false
			},
			// 下拉菜单排序
			concealA(index) {
				this.sortText.sort.sort = this.sortText.conceal[index].conceal
				this.sorts = this.sortText.conceal[index].sorts
				this.rowsList = [];
				this.getPackageListApi()
				// this.sortText.conceal[index].conceal = this.sortText.sort.sort
				// this.sortText.sort.sort = this.sortText.TS
				this.sort = false
			},
			search_key_sumbit(){
				this.rowsList = [];
				this.getPackageListApi()
			},
			// 我的背包
			getPackageListApi() {
				get_star_package_list({
					sort: this.sorts,
					search_key:this.search_key,
					page: this.page,
					page_size: this.page_size
				}).then((res) => {
					this.total_rows = res.data.total_rows
					// this.rowsList = res.data.rows
					this.price_sum = res.data.price_sum
					this.page_count = res.data.page_count
					this.checkAll = false
					// this.checkList = []
					let obj = JSON.parse(JSON.stringify(res.data.rows))
					obj.forEach((item) => {
						let obj = {
							checked: false
						}
						this.rowsList.push({
							...item,
							...obj
						})
					})
				})
			},
			returnRoute(){
				if (this.$route.query.source) {
				  this.$router.push('/StarStore')
				} else {
				  this.$emit('clear')
				  this.$router.push('/StarStore')
				}
			},
			// 分页
			handleCurrentChange() {

				this.page--
				if (this.page < 1) {
					this.page = 1
				}

				this.getPackageListApi()
			},
			handleCurrentChangeTwo() {
				this.page++
				if (this.page > this.page_count) {
					this.page = this.page_count
				}
				this.getPackageListApi()
			},

			// 饰品回收
			recoverySkinsApi() {

				if (this.checkList.length === 0) {
					this.$message.error('请选择饰品')
				} else {
					this.$confirm('是否需要兑换星币?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							dangerouslyUseHTMLString: true
						})
						.then(() => {
							exchange_star_skins({
								player_skins_ids: this.checkList,
								token: this.token
							}).then((res) => {
								this.getTokenApi()
								if (res.status === 1) {
									this.$message({
										message: '回收成功',
										type: 'success'
									})
									for (let i = this.rowsList.length - 1; i >= 0; i--) {
										if (this.rowsList[i].checked) {
											this.rowsList.splice(i, 1)
										}
									}
									this.checkList = [];
									this.checkAll = false
									// this.rowsList.forEach(item=>{
									//  if(item.checked){
									//    delete item
									//  }
									// })
									this.$store.dispatch('getPlayerApi')
								    this.initialization_page()
								} else {
									this.$message.error(res.msg)
								}
							})
						})
						.catch(() => {
							this.$message({
								type: 'info',
								message: '取消回收'
							})
						})
				}
				this.getTokenApi()
			},
			// 获取token令牌
			getTokenApi() {
				get_token().then((res) => {
					this.token = res.data.toekn
				})
			},
			//单选
			checkBox(id) {
				this.rowsList.forEach(item => {
					if (item.id == id) {
						item.checked = !item.checked
					}
				})

				let arr = []
				this.rowsList.forEach(item => {
					if (item.checked) {
						arr.push(item.id)
					}
				})
				this.checkList = arr

				let t = true
				this.rowsList.forEach(item => {
					if (!item.checked) {
						t = false
					}
				})
				this.checkAll = t
			},
			//全选
			checkAllCheck() {
				this.rowsList.forEach(item => {
					item.checked = this.checkAll
				})
				let arr = []
				this.rowsList.forEach(item => {
					if (item.checked) {
						arr.push(item.id)
					}
				})
				this.checkList = arr
			},
			initialization_page(){
				this.page = 1
				this.page_size = 12
				this.rowsList = []
				this.getPackageListApi()
			}



		}
	}
</script>
<style lang="scss" scoped>
	.app {
		position: fixed;
		top: 0.85rem;
		left: 0;
		z-index: 10;
		width: 100%;
		height: 3.8rem;
		// border: 1px solid red;
	}

	@import '@/styles/variables.scss';
.skins_total{
	margin-right: 0.5rem;
}
.price_sum{
	margin-right: 0.5rem;
}
.el-dropdown-link {
  cursor: pointer;
  color: #fff;
  // width: 80px;
  margin: 0 !important;
}
.el-icon-arrow-down {
  font-size: 0.3rem;
}

.el-dropdown-menu {
  // left: 730px !important;
  // top: 282px !important;
  width: 4.2rem !important;
  background-color: #2b4f5300;
  border: 2px solid $theme;
  border-bottom: none;
  padding: 0 0;
}
.el-dropdown-menu__item {
  padding: 0 0px !important;
  margin: 0 !important;
  width: 4.19rem;
  height: .67rem;
  line-height: 0.67rem;
  text-align: center;
  color: #fff;
  border-bottom: 0.001rem solid #FFA500;
 font-size: .3rem;
  &:hover {
	background-color: #606266 !important;
	color: #fff !important;
  }
}
.el-popper {
  // top: 216px !important;
  margin-top: -1px !important;
  background-color: #272451;
  z-index: 50 !important;
  // position: absolute !important;
  // top: 23% !important;
  // left: 10.4% !important;
}


	// 引入css类
	// @import '';
	.MyBackpackBox {
		padding: 0.4rem;

		.MyBackpack {
			padding-top:0.4rem;
			// display: flex;
			// justify-content: space-between;
			.sort {
				position: relative;

				.show {
					border: 2px #FFA500 solid;
					font-size: 0.3rem;
					padding: 0.1rem 0.2rem;
					margin: 0 0.2rem;
					width: 4.2rem;
					text-align: center;
					background: transparent;
					color: #ffffff;
					//height: 0.66rem;
					//line-height: 66rem;
				}

				.conceal {
					width: 100%;
					position: absolute;
					top: 270%;
					left: 50%;
					transform: translate(-50%, -50%);
					display: none;
					z-index: 9;

					div {
						font-size: 0.2rem;
						padding: 0.14rem 0;
						background-color: #272451;
						@extend .show;
					}
				}

				.concealShow {
					display: block;
				}
			}

			.recycle {
				display: flex;
				margin-top: 0.3rem;
				margin-left: .6rem;

				div:first-child{
					color: #FFA500;
					font-size: 0.4rem;
				}
				div:last-child {
					border: 2px #FFA500 solid;
					padding: 0.1rem 0.2rem;
					margin: 0 0.1rem;
					font-size: 0.3rem;
					margin-left: auto;
					margin-right:0.6rem;
				}
			}

			.search {
				position: relative;

				input {
					height: 0.66rem;
					width: 4rem;
					background: none;
					outline: none;
					border: none;
					border: 2px #FFA500 solid;
					padding: 0 0.2rem;
					color: #fff;
					margin-left: 0.3rem;
				}

				.searchIco {
					width: 0.66rem;
					height: 0.66rem;
					background-color: #FFA500;
					position: absolute;
					top: 0;
					right: 0;

					i {
						font-size: 0.4rem;
						position: absolute;
						top: 50%;
						transform: translate(-50%, -50%);
					}
				}
			}
		}

		.MyBackpackSelect {
			display: flex;
			justify-content: flex-end;
			margin-top: 0.1rem;
			margin-right: .8rem;
			color: #FFA500;
			font-size: 0.3rem;

			.checkAll {
				margin-right: 0.3rem;

				input {
					width: 0.3rem;
					vertical-align: middle;
				}
			}
		}

		.BackpackItems {

			// height: 550px;
			ul {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding: 0 5px;

				li {
					width: 47%;
					height: 2.7rem;
					margin: 0.2rem 0.1rem 0 0;
					padding: 0 0.2rem;
					background-repeat: no-repeat;
					background-size: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					position: relative;

					.xz {
						position: absolute;
						top: 10%;
						right: 5%;
						width: .6rem;
					}

					.underway {
						position: absolute;
						top: 5%;
						right: 5%;
						color: #FFA500;
						font-size: 0.3rem;
					}

					.subject {

						// margin-bottom: 5px;
						img {
							width: 2rem;
						}
					}

					.subjectName {
						width: 3rem;
						margin: auto;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
						font-size: 0.3rem;
						margin-top: -10px;
					}

					.fool {
						position: absolute;
						bottom: 0.3rem;
						display: flex;
						align-items: center;
						justify-content: space-evenly;

						.price {
							display: flex;
							align-items: center;
							font-size: 0.3rem;
							margin-right: 0.6rem;
							margin-left: 0.2rem;
							color:#FFA500;
							img {
								width: 0.4rem;
								margin-right: 0.2rem;
							}
						}

						.pitch {
							font-size: 0.3rem;
						}
					}

					&:nth-child(4n) {
						margin-right: 0;
					}
				}

				.loadMore {
					padding-top: 0.3rem;
					width: 6rem;
					margin: auto;

					.btn {
						background-color: #FFA500;
						height: 0.7rem;
						width: 1.4rem;
						border-radius: 0.1rem;
						font-size: 0.3rem;
						border: none;
						margin: 0 0.2rem;
						color: #fff;
					}

					.btn_num {
						width: 0.7rem;
						height: 0.7rem;
						background-color: #FFA500;
						border-radius: 0.1rem;
						color: #fff;
						font-weight: 700;
						border: none;
						font-size: 0.3rem;
					}
				}
			}

			// .roll {
			//   overflow-y: scroll;
			//   height: 550px;
			//   /* 滚动条整体部分 */
			//   &::-webkit-scrollbar {
			//     width: 10px;
			//     height: 10px;
			//     background-color: none;
			//     border-radius: 10px;
			//   }
			//   /* scroll轨道背景 */
			//   &::-webkit-scrollbar-track {
			//     // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
			//     border-radius: 10px;
			//     background-color: rgba(216, 216, 216, 0);
			//   }
			//   /* 滚动条中能上下移动的小块 */
			//   &::-webkit-scrollbar-thumb {
			//     border-radius: 10px;
			//     -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
			//     background-color: rgb(119, 117, 117);
			//   }
			// }
		}
	}

	//  /deep/ .el-checkbox {
	//   color: #fff;
	//   font-size: 0.3rem;
	//   .el-checkbox__inner {
	//     width: 0.3rem !important;
	//     height: 0.3rem !important;
	//     &::after {
	//       height: 0.2rem;
	//       left: 0;
	//     }
	//   }
	// }
</style>
<style lang="less" scoped>
	/deep/ .el-checkbox {
		color: #fff;
		font-size: 0.3rem;

		.el-checkbox__inner {
			width: 0.3rem !important;
			height: 0.3rem !important;

			&::after {
				height: 0.2rem;
				left: 0.1rem;
			}
		}
	}
</style>
