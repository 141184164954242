<!-- HomeFoot -->

<template>
  <div class="foot">
    <img
      class="foot-decorate"
      src="@/assets/images/Bottom menu.png"
      alt=""
    />
    <img class="foot-img" src="@/assets/images/line.png" alt="" />
    <div class="foot-z">
      <ul class="foot-z-head">
        <li>
          <div>{{ getTotalInfoList.open_box_num }}</div>
          <div>已开启盲盒总数</div>
        </li>
        <img src="@/assets/images/Bottom.png" alt="" />
        <li>
          <div>{{ getTotalInfoList.register_num }}</div>
          <div>已注册人数</div>
        </li>
        <img src="@/assets/images/Bottom.png" alt="" />
        <li>
          <div>{{ getTotalInfoList.online_num }}</div>
          <div>在线人数</div>
        </li>
      </ul>
      <ul class="foot-z-body">
        <li @click="goHelp(1)">关于我们</li>
        <span>|</span>
        <li @click="goHelp(2)">隐私政策</li>
        <span>|</span>
        <li @click="goHelp(3)">用户协议</li>
        <span>|</span>
        <li @click="goHelp(4)">常见问题</li>
        <span>|</span>
        <li @click="goHelp(5)">相关教程</li>
        <span>|</span>
        <li @click="goHelp(6)">合规声明</li>
        <span>|</span>
        <li @click="goHelp(7)">联系我们</li>
      </ul>
      <ul class="foot-z-foot foot-text-color">
        <li @click="goWebView(1)" v-if="false">
          <img src="@/assets/images/BeiAn.png" alt="">
          鲁公安网备：37010202003012</li>
        <li @click="goWebView(2)">备案号：鲁ICP备2023038418号-3</li>
        <li v-if="false">增值电信业务经营许可证：鲁B2-20230230</li>
        <li>版权所属：济南星界网络科技服务有限公司</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { get_total_info } from '@/api/index/home'
export default {
  name: 'HomeFoot',
  data() {
    return {
      getTotalInfoList: [],
      qq_service:[]
    }
  },

  created() {
    this.getTotalInfoApi()
    // document.onkeydown = () => {
    //   //禁用F12
    //   if (window.event && window.event.keyCode == 123) {
    //     return false
    //     //禁用ctrl+shift+i,
    //   } else if (
    //     window.event.ctrlKey &&
    //     window.event.shiftKey &&
    //     window.event.keyCode == 73
    //   ) {
    //     return false
    //     //屏蔽Shift+F10
    //   } else if (window.event.shiftKey && window.event.keyCode == 121) {
    //     return false
    //   }
    // }
  },
  methods: {
    getTotalInfoApi() {
      get_total_info().then((res) => {
        this.getTotalInfoList = res.data
      })
    },
    goHelp(e) {
      // console.log(123);
      this.$router.push('/Helpcenter?id=' + e)
    },
    goWebView(type){
      if(type==1){
        window.open('https://beian.miit.gov.cn/#/Integrated/index')
      }
      if(type==2){
        window.open('https://beian.miit.gov.cn/#/Integrated/index')
      }
    },
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.foot {
  font-size: 0.3rem;
  color: aliceblue;
  margin-top: 0.6rem;
  position: relative;
  &-decorate {
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &-img {
    width: 100%;
  }
  &-z {
    // margin: 0.1rem 0 0px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-head {
      display: flex;
      align-items: center;
      li {
        :first-child {
          margin-bottom: 0.1rem;
        }
      }
      img {
        margin: 0 0.5rem;
      }
    }
    &-body {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 7rem;
      margin: .2rem auto;
      li {
        margin: 0 0.03rem;
        padding-top: .1rem;
      }
      span{
        margin: 0 0.2rem;
        padding-top: .1rem;

      }
    }
    &-foot {
      display: flex;
      flex-direction: column;
      align-content: center;
      padding-bottom: 1rem;
      img{
        width: .3rem;
        height: .3rem;
      }
    }
		.foot-text-color{
			color: #878787;
		}
		.foot-z-foot{
			padding-top: 0.5rem;
			li{
				margin-top: 0.1rem;
			}
		}
  }
}
</style>
