<template>
  <el-dialog
    custom-class="inv-pop"
    :visible="dialogVisible"
  >
    <div slot="title" class="inv-title">邀请对战</div>
    <div class="inv-content">
      <div class="inv-user">
        <img src="" alt="">
        <span class="inv-user-name">王富哪个贵</span>
      </div>
      <div class="inv-info">邀请您组队 <span>欧皇对战</span></div>
      <div class="inv-bottom">
        <div class="inv-btn">拒绝</div>
        <div class="inv-btn jieshou">接受</div>
      </div>
    </div>
  </el-dialog>
</template>
<script >
export default {
  props:{
    dialogVisible:{
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="scss">
::v-deep .inv-pop{
  width: 90%;
  height: 965px;
  background: url("https://oss.N7buff.com/web/images/Battle/yaoqing-bg.png") no-repeat;
  background-size: 100% 100%;
  .el-dialog__header{
    padding-top: 86px;
  }
  .inv-title{
    width: 90%;
    margin: 0 auto;
    font-size: 70px;
    color: #E5F9FF;
    font-weight: bold;
    text-shadow: 0px 0px 6px #00FFF7;
    text-align: left;
    padding-left: 205px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    position: relative;
    padding-bottom: 61px;
    &:after{
      content: "";
      width: 179px;
      height: 9px;
      background: #06F7FF;
      position: absolute;
      left: 258px;
      bottom: 0;
    }
  }
  .inv-user{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 78px;
    img{
      width: 168px ;
      height: 168px;
      border-radius: 50%;
      overflow: hidden;
    }
    .inv-user-name{
      font-size: 70px;
      color: #FFFFFF;
      margin-left: 53px;
    }
  }
  .inv-info{
    font-size: 70px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      color: #00FFFC;
      font-size: 82px;
      margin-left: 10px;
    }
  }
  .inv-bottom{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 78px;
    .inv-btn{
      width: 352px;
      height: 122px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 49px;
      color: #FFFFFF;
      cursor: pointer;
      background: url("https://oss.N7buff.com/web/images/Battle/inv-btn.png") no-repeat;
      background-size: 100% 100%;
      &:last-of-type{
        margin-left: 201px;
      }
    }
    .jieshou{
      background: url("https://oss.N7buff.com/web/images/Battle/inv-btn-jieshou.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
