<template>
  <div class="help_box">
    <p class="title">帮助中心</p>
    <div class="dable">
      <ul class="HelpList hnk">
        <li @click="tab_help(1)" :class="tabId == 1 ? 'bgc' : ''">关于我们</li>
        <li @click="tab_help(2)" :class="tabId == 2 ? 'bgc' : ''">隐私政策</li>
        <li @click="tab_help(3)" :class="tabId == 3 ? 'bgc' : ''">用户协议</li>
        <li @click="tab_help(4)" :class="tabId == 4 ? 'bgc' : ''">常见问题</li>
        <li @click="tab_help(5)" :class="tabId == 5 ? 'bgc' : ''">相关教程</li>
        <li @click="tab_help(6)" :class="tabId == 6 ? 'bgc' : ''">合规声明</li>
        <li @click="tab_help(7)" :class="tabId == 7 ? 'bgc' : ''">联系我们</li>
      </ul>

      <div class="bigBox hnk">
        <!-- 左侧tab切换 -->

        <!-- 关于我们 -->
        <div
          class="cente get_about_us"
          v-show="tabId == 1"
          v-html="get_about_usObj.content"
        ></div>
        <!-- 隐私政策 -->
        <div
          class="cente get_privacy_olicy"
          v-show="tabId == 2"
          v-html="get_privacy_olicyObj.content"
        ></div>
        <!-- 用户协议 -->
        <div
          class="cente get_terms_of_use"
          v-show="tabId == 3"
          v-html="get_terms_of_useObj.content"
        ></div>
        <!-- 常见问题 -->
        <div class="cente get_problem" v-show="tabId == 4">
          <div v-show="index_one < 0">
            <div
              v-for="(item, index) in getproblemArr"
              :key="index"
              class="tab_list"
              @click="problem_list(index)"
            >
              <span> > </span>
              {{ item.title }}
            </div>
          </div>
          <div
            class="over"
            :key="index"
            v-for="(item, index) in getproblemArr"
            v-show="index_one == index"
          >
            <div @click="goback()" class="goback">返回</div>
            <div v-html="item.content"></div>
          </div>
        </div>
        <!-- 相关教程 -->
        <div class="cente get_tutorials" v-show="tabId == 5">
          <div v-show="index_two < 0">
            <div
              v-for="(item, index) in get_tutorialsArr"
              :key="index"
              class="tab_list"
              @click="tutorials_list(index)"
            >
              <span> > </span>
              {{ item.title }}
            </div>
          </div>
          <div
            class="over"
            :key="index"
            v-for="(item, index) in get_tutorialsArr"
            v-show="index_two == index"
          >
            <div @click="goback()" class="goback">返回</div>
            <div v-html="item.content"></div>
          </div>
        </div>
        <!-- 合规声明 -->
        <div class="cente get_tutorials" v-show="tabId == 6">
          <div v-show="index_three < 0">
            <div
              v-for="(item, index) in complianceStatement"
              :key="index"
              class="tab_list"
              @click="Statementchange(index)"
            >
              <span> > </span>
              {{ item.title }}
            </div>
          </div>
          <div
            class="over"
            :key="index"
            v-for="(item, index) in complianceStatement"
            v-show="index_three == index"
          >
            <div @click="goback()" class="goback">返回</div>
            <div v-html="item.content"></div>
          </div>
        </div>
        <!-- 联系我们 -->
        <div v-show="tabId == 7" class="contactpage">
          <div class="contact">
            <div class="contactbox">
              <div class="text">QQ号码：{{ contact.qq_group_chat_number }}</div>
            </div>
            <div class="contactbox">
              <div class="text">客服邮箱：{{ contact.email_group_chat }}</div>
            </div>
          </div>
          <div class="contact">
            <div class="contactbox">
              <div class="imgbox">
                <img :src="contact.qq_group_chat" alt="" srcset="" />
              </div>
              <div class="text">QQ群聊</div>
            </div>
            <div class="contactbox">
              <div class="imgbox">
                <img :src="contact.wechat_qr_code" alt="" srcset="" />
              </div>
              <div class="text">微信公众号</div>
            </div>
            <div class="contactbox">
              <div class="imgbox">
                <img :src="contact.tiktok_qr_code" alt="" srcset="" />
              </div>
              <div class="text">抖音</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_about_us,
  get_privacy_olicy,
  get_terms_of_use,
  get_problem,
  get_tutorials,
  get_compliance,
  get_contact_us
} from '@/api/index/HelpCenter'
export default {
  data() {
    return {
      tabId: 1,
      get_about_usObj: {},
      get_privacy_olicyObj: {},
      get_terms_of_useObj: {},
      getproblemArr: [],
      get_tutorialsArr: [],
      index_one: -1,
      index_two: -1,
      index_three: -1,
      complianceStatement: [],
      contact: {}
    }
  },
   watch:{
    '$route'(){
      this.tabId = this.$route.query.id
    }
  },
  methods: {
    tutorials_list(index) {
      this.index_two = index
    },
    Statementchange(index) {
      this.index_three = index
    },
    problem_list(index) {
      this.index_one = index
    },
    goback() {
      this.index_one = -1
      this.index_two = -1
      this.index_three = -1
    },
    notice() {
      // 关于我们
      get_about_us().then((res) => {
        this.get_about_usObj = res.data
      })
      // 隐私政策
      get_privacy_olicy().then((res) => {
        this.get_privacy_olicyObj = res.data
      })
      // 用户协议
      get_terms_of_use().then((res) => {
        this.get_terms_of_useObj = res.data
      })
      // 常见问题
      get_problem().then((res) => {
        this.getproblemArr = res.data
      })
      // 相关教程
      get_tutorials().then((res) => {
        this.get_tutorialsArr = res.data
      })
      // 合规声明
      get_compliance().then((res) => {
        this.complianceStatement = res.data
      })
      // 联系我们
      get_contact_us().then((res) => {
        this.contact = res.data
      })
    },

    // tab切换
    tab_help(e) {
      this.tabId = e
    }
  },
  created() {
						if (sessionStorage.getItem('n7_token')) {
							this.$store.dispatch('getPlayerApi')
						}
    this.tabId = this.$route.query.id
    this.notice()
  }
}
</script>

<style lang="scss" scoped>
.contactpage{
  width: 90%;
  margin: 0 auto;
}
.contact {

  margin-top: 50px;
  padding-top: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.contactbox {
  text-align: center;
  .imgbox {
    padding: 5.5px;
    border: 2px solid #7debec;
    border-radius: 10px;
  }
  img {
    width: 2rem;
    height: 2rem;
    border-radius: 10px;
    padding: 10px;
    display: inline-block;
    background: #fff;
  }
  .text {
    color: #fff;
    margin-top: 10px;
    font-size: .3rem
  }
}
@import '@/styles/Helpcenter.scss';
</style>
