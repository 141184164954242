<!--菜单分类  -->
<template>
  <!-- 菜单分类 -->
  <div class="MenuItems">
    <div :class="[{ introduced: GamePlayShowModal || StarStoreGamePlayShowModal }]"></div>
    <!-- 顶层 -->
    <div class="MenuItemsBottom">
      <!-- 金额 -->
      <div class="r">
        <div class="money">
          <input type="number" v-model="start_price" class="mix" placeholder="最低金额" />
          <div></div>
          <div class="h">一</div>
          <input type="number" v-model="end_price" class="max" placeholder="最高金额" />
          <div class="searchIco" @click="searchAccessories">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
              />
            </svg>
          </div>
        </div>
        <div class="search">
          <input placeholder="请输入要寻找的武器名称" v-model="nameAccessories" />
          <div class="searchIco" @click="searchAccessories">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              class="bi bi-search"
              viewBox="0 0 16 16"
            >
              <path
                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"
              />
            </svg>
          </div>
        </div>
      </div>
      <!-- 品质&外观 -->
      <div class="quApp">
        <!-- 品质 -->
        <el-dropdown class="quality" trigger="click" placement="bottom">
          <div class="el-dropdown-link">
            {{ skinRarity }}<i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu class="dropdownMenu appearance-menus" slot="dropdown">
            <el-dropdown-item>
              <div class="lis" @click="skinRarityAll">不限品质</div>
            </el-dropdown-item>
            <el-dropdown-item v-for="item in skinRarityList" :key="item.id" class="lis">
              <div @click="skinRarityS(item.id, item.name)">
                {{ item.name }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 类别 -->
        <el-dropdown class="appearance" trigger="click" placement="bottom">
          <div class="el-dropdown-link">
            {{ skinQuality }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu class="appearance-menus" slot="dropdown">
            <el-dropdown-item>
              <div class="lis" @click="skinQualityAll">不限类别</div>
            </el-dropdown-item>
            <el-dropdown-item class="lis" v-for="item in skinQualityList" :key="item.id">
              <div @click="skinQualityS(item.id, item.name)">
                {{ item.name }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 外观 -->
        <el-dropdown class="appearance" trigger="click" placement="bottom">
          <div class="el-dropdown-link">
            {{ skinExterior }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu class="appearance-menus" slot="dropdown">
            <el-dropdown-item>
              <div class="lis" @click="skinExteriorAll">不限外观</div>
            </el-dropdown-item>
            <el-dropdown-item class="lis" v-for="item in skinExteriorList" :key="item.id">
              <div @click="skinExteriorS(item.id, item.name)">
                {{ item.name }}
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 分类 -->
    <ul>
      <li v-for="(item, index) in postLogin" :key="item.id">
        <div class="icos">
          <div
            :class="chose_id == index ? 'bgc' : ''"
            class="ico"
            @click="classifyOne(item.id, index)"
          >
            <img :src="item.thumb" alt="" />
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
      </li>
    </ul>
    <div class="icoName">
      <div v-for="(item, index) in postLogin" :key="item.id" class="icobox">
        <div
          v-show="index == chose_id"
          :style="{ height: open ? '35px' : '' }"
          class="icoNames"
        >
          <button
            v-for="items in item.list"
            :key="items.id"
            :class="skin_category_sub_id == items.id ? 'text-ac' : ''"
            @click="classifyTow(items.id, items, item, index)"
          >
            {{ items.name }}
          </button>
        </div>
        <div v-show="index == chose_id" @click="open = !open" class="open">
          <div  >{{ open ? "展开" : "收起" }}</div>
          <i class="el-icon-arrow-up " :class="open?'open-i-open':'open-i'"></i>
        </div>
      </div>
      
    </div>
    <div class="ShowModal" v-show="GamePlayShowModal">
      <GamePlay :visible.sync="GamePlayShowModal" />
    <!-- </div>
		<div class="ShowModal" v-show="StarStoreGamePlayShowModal">
		   <StarStoreGamePlay :visible.sync="StarStoreGamePlayShowModal" />
		</div>
    <div -->
      class="HowPlay"
      v-if="this.$route.path === '/LuckyOrnament'"
      @click="playIntroduction"
    >
      玩<br />法<br />介<br />绍
    </div>
  </div>
</template>

<script>
import { get_skin_types, get_shop_list } from "@/api/index/AccessoriessTore";
import { get_skin_list } from "@/api/index/LuckyAccessories";
import { get_star_shop_list } from '@/api/index/StarStore'
import GamePlay from "@/views/LuckyOrnament/components/GamePlay.vue";
export default {
  name: "MenuItems",
  data() {
    return {
      GamePlayShowModal: false, // 玩法介绍
	  StarStoreGamePlayShowModal:false,
      postLogin: [], // 饰品大分类;
      skinRarityList: [], // 饰品品质
      skinRarity: "品质", // 饰品品质临时
      skinQualityList: [], // 饰品类别
      skinQuality: "类别", // 饰品类别临时
      skinExteriorList: [], // 饰品外观
      skinExterior: "外观", // 饰品外观临时
      skinList: [], // 幸运饰品列表
      shopSkinList: [], // 商城饰品列表
	  starShopSkinList:[],
      total_rows: null,
      page: 1,
      page_size: 10,
      nameAccessories: "", // 模糊搜索饰品名称
      skin_category_id: null, // 一级id
      skin_category_sub_id: null, // 二级id
      quality_id: null, // 类别id
      rarity_id: null, // 品质id
      exterior_id: null, // 外观id
      lucky_recomm: 0, // 是否推荐
      chose_id: 0, //选中的id
      start_price: "",
      end_price: "",
      open: false,
	  all_img:require('@/assets/images/all.png'),
    };
  },
  watch: {
    skinList() {
      this.$emit(
        "skinListS",
        this.skinList,
        {
          page: this.page,
          page_size: this.page_size,
          name: this.nameAccessories,
          skin_category_id: this.skin_category_id,
          skin_category_sub_id: this.skin_category_sub_id,
          quality_id: this.quality_id,
          rarity_id: this.rarity_id,
          exterior_id: this.exterior_id,
          lucky_recomm: this.lucky_recomm,
          start_price: parseInt(Number(this.start_price)),
          end_price: parseInt(Number(this.end_price)),
        },
        this.total_rows
      );
    },
    shopSkinList() {
      this.$emit(
        "shopSkinListS",
        this.shopSkinList,
        {
          page: this.page,
          page_size: this.page_size,
          name: this.nameAccessories,
          skin_category_id: this.skin_category_id,
          skin_category_sub_id: this.skin_category_sub_id,
          quality_id: this.quality_id,
          rarity_id: this.rarity_id,
          exterior_id: this.exterior_id,
          lucky_recomm: this.lucky_recomm,
          start_price: parseInt(Number(this.start_price)),
          end_price: parseInt(Number(this.end_price)),
        },
        this.total_rows
      );
    },
	starShopSkinList() {
	  this.$emit(
	    "starShopSkinListS",
	    this.starShopSkinList,
	    {
	      page: this.page,
	      page_size: this.page_size,
	      name: this.nameAccessories,
	      skin_category_id: this.skin_category_id,
	      skin_category_sub_id: this.skin_category_sub_id,
	      quality_id: this.quality_id,
	      rarity_id: this.rarity_id,
	      exterior_id: this.exterior_id,
	      lucky_recomm: this.lucky_recomm,
	      start_price: parseInt(Number(this.start_price)),
	      end_price: parseInt(Number(this.end_price)),
	    },
	    this.total_rows
	  );
	},
  },
  components: {
    GamePlay,
  },
  created() {
    this.nameAccessories = ""; // 模糊搜索饰品名称
    this.skin_category_id = null; // 一级id
    this.skin_category_sub_id = null; // 二级id
    this.quality_id = null; // 类别id
    this.rarity_id = null; // 品质id
    this.exterior_id = null; // 外观id
    this.lucky_recomm = 0; // 是否推荐
    this.getSkinTypesApi();
  },
  methods: {
    // 搜索饰品
    searchAccessories() {
      this.skin_category_id = null; // 一级id
      this.skin_category_sub_id = null; // 二级id
      // this.quality_id = null // 类别id
      // this.rarity_id = null // 品质id
      // this.exterior_id = null // 外观id
      // this.lucky_recomm = 0 // 是否推荐
      this.ifTo();
    },
    // 点击饰品品质
    skinRarityAll() {
      this.rarity_id = null;
      this.skinRarity = "不限品质";
      this.ifTo();
    },
    skinRarityS(rarity_id, name) {
      //console.log(rarity_id, name);
      this.rarity_id = rarity_id;
      this.skinRarity = name;
      this.ifTo();
    },

    // 点击饰品类别
    skinQualityAll() {
      this.quality_id = null;
      this.skinQuality = "不限类别";
      this.ifTo();
    },
    skinQualityS(quality_id, name) {
      this.quality_id = quality_id;
      this.skinQuality = name;
      this.ifTo();
    },

    // 点击饰品外观
    skinExteriorAll() {
      this.exterior_id = null;
      this.skinExterior = "不限外观";
      this.ifTo();
    },
    skinExteriorS(exterior_id, name) {
      this.exterior_id = exterior_id;
      this.skinExterior = name;
      this.ifTo();
    },
    // 点击一级分类
    classifyOne(skin_category_id, index) {
      this.skin_category_sub_id = null; // 二级id
      this.open = true;
      // this.quality_id = null // 类别id
      // this.rarity_id = null // 品质id
      // this.exterior_id = null // 外观id
      this.lucky_recomm = 0; // 是否推荐
      // console.log(index);
      if (this.skin_category_id === skin_category_id) {
        this.skin_category_id = null;
        this.chose_id = 0;
        this.ifTo();
      } else {
        this.skin_category_id = skin_category_id;
        this.chose_id = index;
        this.ifTo();
      }
    },
    // 点击二级分类
    classifyTow(skin_category_sub_id, items, item, index) {
      this.skin_category_id = item.id; // 一级id
      // this.quality_id = null // 类别id
      // this.rarity_id = null // 品质id
      // this.exterior_id = null // 外观id
      this.lucky_recomm = 0; // 是否推荐

      this.skin_category_sub_id = skin_category_sub_id;
      //console.log(skin_category_sub_id);
      this.ifTo();
    },
    // 获取饰品分类
    getSkinTypesApi() {
      get_skin_types().then((res) => {
        //console.log(res.data.skin_category);
        res.data.skin_category.unshift({
          id:0,
          thumb:this.all_img,
          name:"全部"
        })
        this.postLogin = res.data.skin_category;
        this.skinRarityList = res.data.skin_rarity;
        this.skinQualityList = res.data.skin_quality;
        this.skinExteriorList = res.data.skin_exterior;
      });
    },
    // 获取幸运饰品列表
    async getSkinList() {
      const res = await get_skin_list({
        page: this.page,
        page_size: this.page_size,
        name: this.nameAccessories,
        skin_category_id: this.skin_category_id,
        skin_category_sub_id: this.skin_category_sub_id,
        quality_id: this.quality_id,
        rarity_id: this.rarity_id,
        exterior_id: this.exterior_id,
        lucky_recomm: this.lucky_recomm,
        start_price: parseInt(this.start_price),
        end_price: parseInt(this.end_price),
      });
     if (res.status === 1) {
     	this.total_rows = res.data.total_rows
     	this.skinList = res.data.rows
     } else {
     	this.$message.error(res.msg)
     }
    },
    // 获取商品饰品列表
    async getShopListApi() {
      const res = await get_shop_list({
        page: this.page,
        page_size: this.page_size,
        name: this.nameAccessories,
        skin_category_id: this.skin_category_id,
        skin_category_sub_id: this.skin_category_sub_id,
        quality_id: this.quality_id,
        rarity_id: this.rarity_id,
        exterior_id: this.exterior_id,
        lucky_recomm: this.lucky_recomm,
        start_price: parseInt(this.start_price),
        end_price: parseInt(this.end_price),
      });
      if (res.status === 1) {
      	this.total_rows = res.data.total_rows
      	this.shopSkinList = res.data.rows
      } else {
      	this.$message.error(res.msg)
      }
    },
	// 获取星空商品饰品列表
	async getStarShopListApi() {
	  const res = await get_star_shop_list({
	    page: this.page,
	    page_size: this.page_size,
	    name: this.nameAccessories,
	    skin_category_id: this.skin_category_id,
	    skin_category_sub_id: this.skin_category_sub_id,
	    quality_id: this.quality_id,
	    rarity_id: this.rarity_id,
	    exterior_id: this.exterior_id,
	    lucky_recomm: this.lucky_recomm,
	    start_price: parseInt(this.start_price),
	    end_price: parseInt(this.end_price),
	  });
	  if (res.status === 1) {
	  	this.total_rows = res.data.total_rows
	  	this.starShopSkinList = res.data.rows
	  } else {
	  	this.$message.error(res.msg)
	  }
	},
    // 玩法介绍
    playIntroduction() {
	  this.GamePlayShowModal = true;
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false); //禁止页面滑动
    },
    ifTo() {
      if (this.$route.path === "/LuckyOrnament") {
        this.getSkinList();
      }
      if (this.$route.path === "/OrnamentStore") {
        this.getShopListApi();
      }
	  if (this.$route.path === "/StarStore") {
	    this.getStarShopListApi();
	  }
    },
  },
};
</script>
<style lang="scss" scoped>
.open-i{
  transform: rotate(0);
  transition: all .2s linear;
}
.open-i-open{
  transform: rotate(180deg);
  transition: all .2s linear;
}
.open {
  height: 0.7rem;
  background-color: rgba(30, 240, 236, 0);
  color: #fff;
  min-width:1.3rem;
  width:1.3rem;
  max-width:1.3rem;
  border: 0.00651rem solid #1ef0ec;
  margin-right: 0.2rem;
  border-radius: 0.1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: .3rem;
    
}
.icobox {
  display: flex;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
input[type="number"] {
  -moz-appearance: textfield;
}
// 引入css类
// @import '';
@import "@/styles/MenuItems";
.introduced {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 98;
  transition: all 0.15s linear;
}
.el-dropdown {
  width: 124px !important;
}
.el-dropdown-link {
  color: #fff;
  // width: 80px;
  margin: 0 !important;
}
.el-icon-arrow-down {
  font-size: 0.3rem;
}

.el-dropdown-menu {
  // left: 730px !important;
  // top: 282px !important;
  width: 124px !important;
  background-color: #2b4f5300;
  border: 2px solid $theme;
  border-bottom: none;
  padding: 0 0;
}
.el-dropdown-menu__item {
  padding: 0 5px !important;
  margin: 0 !important;
  width: 2.07rem;
  color: #fff;
  border-bottom: 0.001rem solid #1ef0ed;
  &:hover {
    background-color: #ecf5ff;
    color: $theme !important;
  }
}
.el-dropdown-menu__item {
  text-align: center;
}
.el-popper {
  // top: 216px !important;
  //margin-top: -1px !important;
  background-color: #272451;
  z-index: 50 !important;
  // position: absolute !important;
  // top: 23% !important;
  // left: 10.4% !important;
}
::v-deep .popper__arrow {
  display: none !important;
}
.appearance-menus {
  position: absolute !important;
  //top: 5.7rem !important;
  width: 2.08rem !important;
  font-size: 0.3rem !important;

  .lis {
    width: 2.07rem !important;
    font-size: 0.3rem;
    text-align: center;
    height: 0.6rem !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 0.6rem;
  }
}
.dropdown-menus {
  // display: flex !important;
  // flex-wrap: wrap !important;
  // width: 230px;
  position: absolute !important;
  top: 282px !important;
  // left: 10.4% !important;
}
.ShowModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
}
.HowPlay {
  position: absolute;
  right: 3rem;
  top: 0;
}
.text-ac {
  background: #1ef0ed !important;
  color: #000 !important;
  border-color: #000 !important;
}
</style>
