<!-- 我的日志 -->
<template>
	<div class="MyLog">
		<div class="index_title">我的日志</div>
		<div class="MyLogRecord">
			<div class="accountDetails" :class="[{ pitchOn: pitchOn === 'recharge' }]" style="margin-left:0.3rem;" @click="rechargeRecord">账户明细</div>
			<div class="ornamentRecord" :class="[{ pitchOn: pitchOn === 'ornament' }]" @click="ornamentRecord">饰品记录</div>
			<!-- <div class="presentRecord" :class="[{ pitchOn: pitchOn === 'present' }]" @click="presentRecord">礼品记录</div> -->
		</div>
		<!-- 排序 -->
		<div class="assistant-sort" v-show="pitchOn === 'ornament'">
			<div class="all" v-if="JWsort === 'desc'" @click="jwsorts">正序</div>
			<div class="all" v-if="JWsort === 'asc'" @click="jwsorts">倒序</div>
		</div>
		<!-- 账户明细 -->
		<div v-show="pitchOn === 'recharge'" class="rechargeRecord">
			<div class="select">
				<div class="main">
					<div class="all" @click="main">
						{{ recordPull[TS].mainName }}
						<i class="el-icon-arrow-down"></i>
					</div>
					<ul v-show="mainConceal">
						<li v-for="(item, index) in recordPull" :key="item.id" @click="mainLi(index, item.change_type)">{{ item.mainName }}</li>
					</ul>
					<!-- 		<el-dropdown class="all" trigger="click" placement="bottom">
						<div class="el-dropdown-link" :style="VPointer()">
							{{ recordPull[TS].mainName }}
							<i class="el-icon-arrow-down el-icon--right"></i>
						</div>
						<el-dropdown-menu class="dropdownMenu appearance-menus" slot="dropdown">
							<el-dropdown-item :style="VPointer()" v-for="(item, index) in recordPull" :key="item.id">
								<div @click="mainLi(index, item.change_type)">{{ item.mainName }}</div>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown> -->
				</div>
				<div class="assistant">
					<!-- <div class="all" @click="assis"   >
            {{ assistants[TS].assistant[TST].assistantName }}
            <i class="el-icon-arrow-down"></i>
          </div>
          <ul v-show="assistantConceal">
            <li
              v-for="(item, index) in assistants[TS].assistant"
              :key="item.id"
              @click="assistantLi(index, item.type)"

            >
              {{ item.assistantName }}
            </li>
          </ul> -->
					<el-dropdown class="all" trigger="click" placement="bottom">
						<div class="el-dropdown-link" :style="VPointer()">
							{{ assistants[TS].assistant[TST].assistantName }}
							<i class="el-icon-arrow-down el-icon--right"></i>
						</div>
						<el-dropdown-menu class="dropdownMenu appearance-menus" slot="dropdown">
							<el-dropdown-item :style="VPointer()" v-for="(item, index) in assistants[TS].assistant" :key="item.id">
								<div @click="assistantLi(index, item.type)">{{ item.assistantName }}</div>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<div class="wallet_assistant-sort" v-show="pitchOn === 'recharge'">
					<div class="all" v-if="sort === 'desc'" @click="sorts">正序</div>
					<div class="all" v-if="sort === 'asc'" @click="sorts">倒序</div>
				</div>
			</div>
			<!-- 表格 -->
			<div class="detail sticky-table">
				<table border="1" cellspacing="0" width="100%">
					<tr class="formH">
						<th>类 &nbsp; 型</th>
						<th>描 &nbsp; 述</th>
						<th>金 &nbsp; 额</th>
						<th>日 &nbsp; 期</th>
					</tr>
					<tr class="formB" v-for="item in rowsList" :key="item.id">
						<td>{{ item.change_type === 1 ? '收入' : '支出' }}</td>
						<td>{{ item.type_name }}</td>
						<td>{{ item.amount }}</td>
						<td>{{ item.update_time }}</td>
					</tr>
				</table>
			</div>
		</div>

		<!-- 饰品记录 -->
		<div v-show="pitchOn === 'ornament'" class="ornament">
			<div class="detail sticky-table">
				<table border="1" cellspacing="0" width="100%">
					<tr class="formH">
						<th width="30%">饰品名称</th>
						<th width="20%">来 &nbsp;源</th>
						<th width="20%">状 &nbsp;态</th>
						<th width="31%">时 &nbsp; 间</th>
					</tr>
					<tr class="formB" v-for="(item, index) in ornamentsData" :key="item.id">
						<td class="Jewelrys-name">{{ item.item_name }}</td>
						<td>{{ item.way_name }}</td>
						<td>{{ item.state_name }}</td>
						<td>{{ item.update_time }}</td>
					</tr>
				</table>
			</div>
		</div>
		<!-- 礼品记录 -->
		<!-- <div v-show="pitchOn === 'present'" class="present">
      <div class="detail sticky-table">
        <table border="1" cellspacing="0" width="100%">
          <tr class="formH">
            <th>日 &nbsp; 期</th>
            <th>类 &nbsp; 型</th>
            <th>描 &nbsp; 述</th>
            <th>数 &nbsp; 量</th>
            <th>余 &nbsp; 额</th>
          </tr>
          <tr class="formB" v-for="item in forms" :key="item.id">
            <td v-for="item in 5" :key="item.id">100</td>
          </tr>
        </table>
      </div>
    </div> -->
		<!-- <el-pagination
      background
      layout="prev, pager, next"
      @current-change="handleCurrentChange"
      hide-on-single-page
      :page-size="page_size"
      :total="total_rows"
    >
    </el-pagination> -->
		<!-- <div class="loadMore">
      <button class="btn"    @click="handleCurrentChange">
        上一页
      </button>
      <button class="btn_num">{{ page }}</button>
      <button class="btn"    @click="handleCurrentChangeTwo">
        下一页
      </button>
    </div> -->
	</div>
</template>

<script>
import { get_list, jewe_lry_name } from '@/api/index/personalCenter';
import AntiShake from '@/utils/AntiShake.js';
export default {
	name: 'MyLog',
	data() {
		return {
			pitchOn: 'recharge',
			mainConceal: false,
			assistantConceal: false,
			TS: 0,
			TST: 0,
			page_count: null, //总页数
			recordPull: [
				{
					mainName: '全部',
					change_type: '0'
				},
				{
					mainName: '支出',
					change_type: '2'
				},
				{
					mainName: '收入',
					change_type: '1'
				}
				/* {
          mainName: '用户充值'
        } */
			],
			assistants: [
				{
					assistant: [{ assistantName: '全部' }]
				},
				{
					assistant: [
						{ assistantName: '全部', type: '0' },
						{ assistantName: '普通盲盒', type: '-1' },
						{ assistantName: '盲盒对战', type: '-2' },
						{ assistantName: '幸运饰品', type: '-3' },
						{ assistantName: '商城消费', type: '-4' }
						/* { assistantName: '平台消费', type: '-5' } */
					]
				},
				{
					assistant: [
						{ assistantName: '全部', type: '0' },
						{ assistantName: '饰品回收', type: '1' },
						/* { assistantName: '消费', type: '2' }, */
						{ assistantName: '充值', type: '3' },
						{ assistantName: '对战获得', type: '4' },
						{ assistantName: '对战平分', type: '5' },
						/* { assistantName: '后台充值', type: '6' }, */
						{ assistantName: '幸运饰品', type: '7' },
						{ assistantName: '对战撤回', type: '8' },
						{ assistantName: '推广分红', type: '9' },
						{ assistantName: '口令红包', type: '10' }
					]
				},
				{
					assistant: [{ assistantName: '充值' }]
				}
			],
			rowsList: [],
			forms: 17,
			sort: 'desc', // 排序
			page: 1, // 页码
			page_size: 20, // 每页数量
			change_type: '0', // 收入支出
			type: '0', // 类型
			total_rows: null, // 总数据量
			ornamentsData: [], //饰品记录
			JWsort: 'desc',
			JWpage:1,
			JWpage_count:0,
			JWpage_size:20,
		};
	},
	created() {
		if (sessionStorage.getItem('n7_token')) {
			this.$store.dispatch('getPlayerApi');
		}
		this.getListApi();
	},
	// props: {
	//   content: String
	// },
	// watch: {
	//   content() {
	//     if (this.content === 'MyLog') {
	//       this.getListApi()
	//     }
	//   }
	// },
	mounted() {
		window.addEventListener('scroll', this.getbottom);
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.getbottom);
	},
	methods: {
		getbottom() {
			// 返回滚动条垂直滚动距离
			let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			// 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
			let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
			// 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
			let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
			let numHeight = scrollTop + clientHeight;
			console.log("zoule1111111111111111")
			if (scrollHeight > clientHeight && numHeight > scrollHeight - 200) {
				this.getData();
			}
		},
		getData: AntiShake.throttle(function() {
			if(this.pitchOn == 'recharge'){
				if (this.page < this.page_count && this.page_count != 1) {
					this.page++;
					this.getListApi();
				}
			}else{
				console.log("this.JWpage",this.JWpage);
				console.log("this.JWpage_count",this.JWpage_count);
				if (this.JWpage < this.JWpage_count && this.JWpage_count != 1) {
					this.JWpage++;
					this.getJewelry();
				}
			}

		}, 500),
		// 获取个人明细
		getListApi() {
			get_list({
				sort: this.sort,
				page: this.page,
				page_size: this.page_size,
				change_type: this.change_type,
				type: this.type
			}).then(res => {
				console.log(res);
				this.total_rows = res.data.total_rows;
				// this.ornamentsData = res.data.rows;
				this.page_count = res.data.page_count;
				// this.rowsList = res.data.rows
				res.data.rows.forEach(item => {
					this.rowsList.push(item);
				});
				// res.data.rows.forEach(item => {
				// 	this.ornamentsData.push(item);
				// });
			});
		},
		getJewelry() {
			jewe_lry_name({
				sort: this.JWsort,
				page: this.JWpage,
				page_size: this.JWpage_size
			}).then(res => {
				//console.log('饰品记录', res);
				//this.ornamentsData = res.data.rows;
				console.log(this.ornamentsData);
				this.JWtotal_rows = res.data.total_rows;
				this.JWpage_count = res.data.page_count;
				res.data.rows.forEach(item => {
					this.ornamentsData.push(item);
				});
			});
		},
		handleCurrentChanges(val) {
			console.log('分页2', val);
			if (this.ornamentsData) this.page = val;
			this.getListApi();
		},
		// 分页
		// handleCurrentChange(val) {
		//   this.page = val
		//   this.getListApi()
		// },
		handleCurrentChange() {
			this.page--;
			if (this.page < 1) {
				this.page = 1;
			}
			this.getListApi();
		},
		handleCurrentChangeTwo() {
			this.page++;
			if (this.page > this.page_count) {
				this.page = this.page_count;
			}
			this.getListApi();
		},
		sorts() {

			this.rowsList = [];
			this.ornamentsData = [];
			if (this.sort === 'desc') {
				this.sort = 'asc';
			} else {
				this.sort = 'desc';
			}
			this.page = 1;
			this.getListApi();
		},
		jwsorts() {
			// this.rowsList = [];
			this.ornamentsData = [];
			if (this.JWsort === 'desc') {
				this.JWsort = 'asc';
			} else {
				this.JWsort = 'desc';
			}
			this.JWpage = 1;
			this.getJewelry();
		},
		rechargeRecord() {
			this.pitchOn = 'recharge';
			this.getListApi();
		},
		ornamentRecord() {
			this.pitchOn = 'ornament';
			this.getJewelry();
		},
		// presentRecord() {
		//   this.pitchOn = 'present'
		// },
		main() {
			if (this.mainConceal) {
				this.mainConceal = false;
				this.assistantConceal = false;
			} else {
				this.mainConceal = true;
				this.assistantConceal = false;
			}
		},
		assis() {
			if (this.assistantConceal) {
				this.assistantConceal = false;
				this.mainConceal = false;
			} else {
				this.assistantConceal = true;
				this.mainConceal = false;
			}
		},
		// 收入或支出
		mainLi(index, change_type) {
			this.change_type = change_type;
			this.TS = index;
			this.TST = 0;
			this.type = '0';
			this.mainConceal = false;
			this.assistantConceal = false;
			this.page = 1;
			this.rowsList = [];
			this.getListApi();
		},
		// 其他类型
		assistantLi(index, type) {
			this.type = type;
			this.TST = index;
			this.mainConceal = false;
			this.assistantConceal = false;
			this.page = 1;
			this.rowsList = [];
			this.getListApi();
		}
	},
	//mounted() {}
};
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';
// 引入css类
// @import '';
.el-dropdown-link {
	cursor: pointer;
	color: #fff;
	// width: 80px;
	margin: 0 !important;
}
.el-icon-arrow-down {
	font-size: 0.3rem;
}

.el-dropdown-menu {
	// left: 730px !important;
	// top: 282px !important;
	width: 2rem !important;
	background-color: #2b4f5300;
	border: 2px solid $theme;
	border-bottom: none;
	padding: 0 0;
}
.el-dropdown-menu__item {
	padding: 0 0px !important;
	margin: 0 !important;
	width: 1.99rem;
	height: 0.67rem;
	line-height: 0.67rem;
	color: #fff;
	text-align: center;
	font-size: 0.3rem;
	border-bottom: 0.001rem solid #1ef0ed;
	&:hover {
		background-color: #606266 !important;
		color: #fff !important;
	}
}
.el-popper {
	// top: 216px !important;
	margin-top: -1px !important;
	background-color: #272451;
	z-index: 50 !important;
	// position: absolute !important;
	// top: 23% !important;
	// left: 10.4% !important;
}

.loadMore {
	padding-top: 0.3rem;
	width: 6rem;
	margin: auto;
	.btn {
		background-color: #1ef0ed;
		height: 0.7rem;
		width: 1.4rem;
		border-radius: 0.1rem;
		font-size: 0.3rem;
		border: none;
		margin: 0 0.2rem;
		color: #fff;
	}
	.btn_num {
		width: 0.7rem;
		height: 0.7rem;
		background-color: #1ef0ed;
		border-radius: 0.1rem;
		color: #fff;
		font-weight: 700;
		border: none;
		font-size: 0.3rem;
	}
}
.MyLog {
	padding-top: 0.2rem;

	.MyLogRecord {
		width: 100%;
		height: 0.7rem;
		display: flex;
		//margin-left: 0.25rem;
		margin-top: 0.2rem;
		div {
			width: 2rem;
			border: 2px $theme solid;
			padding: 0 0.09rem;
			margin: 0 0.1rem;
			height: 0.7rem;
			line-height: 0.8rem;
		}
		.pitchOn {
			background-color: $theme;
			color: #000;
		}
	}
	.assistant-sort {
		margin-left: 7.5rem;
		.all {
			width: 2rem;
			font-size: 0.3rem;
			padding: 0.1rem 0;
			border: 2px $theme solid;
			margin: 0 0.1rem;
		}
	}
	.wallet_assistant-sort{
		margin-left: 3rem;
		.all {
			width: 2rem;
			font-size: 0.3rem;
			padding: 0.1rem 0;
			border: 2px $theme solid;
			margin: 0 0.1rem;
		}
	}
	.rechargeRecord {
		// 选择
		.select {
			display: flex;
			margin-top: 0.2rem;
			.main {
				margin-left: 0.2rem;
				.all {
					width: 2rem;
					font-size: 0.3rem;
					padding: 0.1rem 0;
					border: 2px $theme solid;
					margin: 0 0.1rem;
				}
				ul {
					position: absolute;
					z-index: 999;
					li {
						@extend .all;
						background-color: $hoverText;
					}
				}
			}
			.assistant {
				.all {
					width: 2rem;
					font-size: 0.3rem;
					padding: 0.1rem 0;
					border: 2px $theme solid;
					margin: 0 0.1rem;
				}
				ul {
					position: absolute;
					z-index: 999;
					li {
						@extend .all;
						background-color: $hoverText;
					}
				}
			}
		}
		// 表格
		.detail {
			margin: 0.2rem;
			margin-top: 0.2rem;
			// overflow-y: scroll;
			// height: 10rem;
			/* 滚动条整体部分 */
			// &::-webkit-scrollbar {
			//   width: 10px;
			//   height: 10px;
			//   background-color: none;
			//   border-radius: 10px;
			// }
			// /* scroll轨道背景 */
			// &::-webkit-scrollbar-track {
			//   // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
			//   border-radius: 10px;
			//   background-color: rgba(216, 216, 216, 0);
			// }
			// /* 滚动条中能上下移动的小块 */
			// &::-webkit-scrollbar-thumb {
			//   border-radius: 10px;
			//   -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
			//   background-color: rgb(119, 117, 117);
			// }
			.formH {
				th {
					height: 0.8rem;
					font-size: 0.3rem;
					border-top: 1px #808080 solid;
				}
			}
			.formB {
				td {
					height: 0.8rem;
					font-size: 0.3rem;
					&:nth-child(5) {
						color: $theme;
					}
				}
			}
		}

		.sticky-table th {
			position: sticky;
			top: 0; /* 首行永远固定在头部  */
			background-color: transparent; /*设置表头背景色*/
		}

		.sticky-table th:first-child {
			z-index: 2; /*表头的首列要在上面*/
		}
	}
	.ornament {
		.detail {
			margin: 0.2rem;
			margin-top: 0.2rem;
			padding-right: 9px;
			// overflow-y: scroll;

			table {
				table-layout: fixed;
			}
			.formH {
				th {
					height: 0.8rem;
					font-size: 0.3rem;
					border-top: 1px #808080 solid;
				}
			}
			.formB {
				td {
					height: 0.8rem;
					font-size: 0.3rem;
					&:nth-child(5) {
						color: $theme;
					}
				}
			}
		}
	}
	.present {
		@extend .ornament;
	}
	.accountDetails{
		font-size: 0.3rem;
	}
	.ornamentRecord{
		font-size: 0.3rem;
	}
}
</style>
