<template>
	<div class="banner" v-if="arr.length">
		<swiper :options="swiperOption" ref="mySwiper" class="show-board">
			<swiper-slide v-for="(item, index) in arr" :key="index"><img :src="item.thumb" alt="" /></swiper-slide>
		</swiper>
	</div>
</template>

<script>
import { get_banner } from '@/api/index/home.js';

import { swiper, swiperSlide } from 'vue-awesome-swiper';
import '@/assets/css/swiper.min.css';
import { Tree } from 'element-ui';
export default {
	components: {
		swiper,
		swiperSlide
	},
	data() {
		return {
			arr: [],
			swiperOption: {
				loop: true,
				autoplay: {
					delay: 3000,
					stopOnLastSlide: false,
					disableOnInteraction: false
				}
			},
			tableHei: 'auto',
			timer: null,
			size: 0,
			Banner: []
		};
	},
	created() {
		get_banner().then(res => {
			this.arr = res.data;
		});
	}
};
</script>

<style lang="scss" scoped>
.swiper-container {
	margin: 0 auto;
	width: 9.5rem;
	height: 2.2rem;
}

.banner {
	margin: auto;
	margin-top: 0.2rem;
	margin-bottom: 0.6rem;
	img {
		width: 100%;
		height: 100%;
	}
}
</style>
