<!-- 公告 -->
<template>
    <div class="alert">
        <!-- <div class="text" v-html="content"></div> -->
        <!-- <div class="text" v-text="content"></div> -->
        <notice-bar v-if="content" :text="content"  />
    </div>
</template>

<script>
import { postNotice } from "@/api/index/home.js";
import NoticeBar from './NoticeBar.vue'
export default {
    name: "HomeBodyAlert",
    components: {
            NoticeBar
        },
    data() {
        return {
            content: "",
        };
    },
    watch: {
        $route(){
            this.noticeApi();
        }
    },
    created() {
        this.noticeApi();
    },
    methods: {
        noticeApi() {
            postNotice().then((res) => {
                // console.log(res.datacontent);
                this.content = this.setText(res.data.content) 
            });
        },
        setText(html) {
            let txt = document.createElement("div");
            txt.innerHTML = html;
            let content = txt.innerText || txt.textContent;
            txt = null;
            return content;
        }
    },
};
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.alert {
    // width: 9rem;
    height: 0.6rem;
    // border-radius: 0.25rem;
    // background-color: #b5b4c3;
    //background: rgba($color: #000000, $alpha: 0.7);
    // margin: 1rem auto;
    margin: 0.2rem 0;
    line-height: 0.5rem;
    font-weight: 700;
    overflow: hidden;
    position: relative;
    .text {
        position: absolute;
        top: 50%;
        left: -100%;
        transform: translate(-50%, -50%);
        animation: slide 80s linear infinite;
        ::v-deep p {
            width: 100%;
            display: inline;
        }
        ::v-deep p span {
            white-space: nowrap !important;
            width: 2.5rem;
            display: inline;
            font-size: 0.2rem !important;
            background-color: rgba($color: #000000, $alpha: 0) !important;
        }
        @keyframes slide {
            0% {
                transform: translate(0%, -50%);
            }
            100% {
                transform: translate(-105%, -50%);
            }
        }
    }
}
</style>
