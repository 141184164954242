<!-- 开箱 -->
<template>
  <div class="home">
    <div class="home-body">
      <BoxShowBodyOpen :boxData="boxData"  :gradeList="gradeList" :boxDataList="boxDataList" @getBoxDataList="getBoxDataList" />
      <BoxShowBodyIn :skinsList="boxData.skins" />
    </div>
  </div>
</template>

<script>
import BoxShowBodyOpen from './BoxShowBodyOpen.vue'
import BoxShowBodyIn from './BoxShowBodyIn.vue'
import {get_grade_box_data, get_grade_box_list, get_player_grade_list} from "@/api/index/Grade";
export default {
  name: 'K5Home',
  components: {
    BoxShowBodyOpen,
    BoxShowBodyIn
  },
  data() {
    return {
      boxData:{},
      boxDataList: [],
      gradeBoxList: [],
      gradeList: []
    }
  },
  created() {
    if (sessionStorage.getItem('n7_token')) {
      this.$store.dispatch('getPlayerApi')
    }
    this.getBoxDataListApi()
    this.getGradeList()
  },
  methods: {
    async getBoxDataListApi() {
      const res = await get_grade_box_list({ type:1 })
      this.boxDataList = res.data
      //console.log(this.boxData)
    },
    async getBoxDataList(id) {
      const res = await get_grade_box_data({ grade_box_id:id })
      this.boxData = res.data
      //console.log(this.boxData)
    },
    async getGradeList(){
      const res = await get_player_grade_list({ type:'grade_box' })
      console.log(JSON.stringify(res))
      this.gradeList = res.data
    }
  }
}
</script>
<style lang="scss" scoped>
/* @import url(); 引入css类 */
.home{
  margin-top: 0.2rem;
}
</style>
