<!-- 开箱弹出动画 -->
<template>
  <div
    class="BoxShowPopup"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="oneOn">
     <!-- <img
        v-if="!results"
        src="@/assets/images/BoxShowPopup/一发入魂.png"
        alt=""
      /> -->
      <p class="get" >获得奖励</p>
    </div>
    <div
      class="close"
      @click="closes"
      :style="VPointer()"
    >
      <img src="@/assets/images/BoxShowPopup/close.png" alt="" />
    </div>
	<!-- 中奖结果 -->
    <div class="winningResults">
      <div class="goods">
        <ul>
          <li v-for="(item, index) in resultList" :key="index">
            <div
              class="item"
              :style="'background-image: url(' + item.rarity_thumb + ');'"
            >
              <div class="main">
                <img :src="item.image_url" alt="" />
              </div>
              <div class="name" :title="item.item_name">
                {{ item.item_name }}
              </div>
              <div class="price">
                <img src="../../assets/images/n7_money.png" alt="" />
                <div>{{ item.price }}</div>
              </div>
            </div>
            <div class="take">
              <div class="putIn" :style="VPointer()" @click="toPack(index)">
                放入背包
              </div>
              <div
                class="recycle"
                :style="VPointer()"
                @click="recoverySkinsApi(item.player_skins_id, index)"
              >
                回收 {{ item.price }}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="all">
        <div class="allPutIn" :style="VPointer()" @click="closes">
          全部放入背包
        </div>
        <div
          class="allRecycle"
          :style="VPointer()"
          @click="recoverySkinsAllApi"
        >
          一键回收 {{ priceAllOn }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_box_data } from '@/api/index/boxCorrelation'
import { recovery_skins, get_token } from '@/api/index/personalCenter'
export default {
  name: 'BoxShowPopup',
  data() {
    return {
      Height: null,
      translateY: 0, //偏移值
      results: true, // 中奖结果
      userList: [],
      closesTime: 0,
      winningTimer: 'winningTimer',
      recoverySkins: [], // 抽中物品
      get_token: '', // 令牌
      priceAll: 0, // 总价格
	  show_audio: new Audio('https://oss.n7buff.com/mobile/audio/Shoot.mp3'), //过程声音
      priceAllOn: 0 // 总价格四舍五入后
    }
  },
  props: {
    visible: Boolean,
    count: Number,
    resultList: Array, //结果
    show_prize: true // 动画开关
  },
  watch: {
     visible(val) {

		this.priceAlls()
		//this.show_audio.play()
     //await this.open()
    },
    show_prize(val) {

      if (val) {
        //window.location.reload()
      }
    },
    count() {
      // console.log(this.count);
      //this.getBoxDataApi()
    }
  },
  created() {

    //this.getBoxDataApi()
  },
  // mounted() {
  //   this.getBoxDataApi()
  // },
  // updated(){
  //   this.getBoxDataApi()
  // },
  methods: {

    // 全部价格
    priceAlls() {
		this.recoverySkins = []
	  //console.log("价格123123123")
		  this.priceAll = 0
      for (let i = 0; i < this.resultList.length; i++) {
        this.priceAll += Number(this.resultList[i].price)
      }
      let priceAllOn = this.priceAll.toFixed(2)
      this.priceAllOn = priceAllOn

	  this.resultList.forEach((item) => {
	    this.recoverySkins.push(item.player_skins_id)
	  })

    },
    // 单个放入背包
    toPack(index) {
      if (this.resultList.length > 1) {
        // eslint-disable-next-line vue/no-mutating-props
        this.resultList.splice(index, 1)
        this.recoverySkins.splice(index, 1)
		console.log(this.recoverySkins)
		this.priceAlls()
      } else {
        this.closes()
      }
    },
    // 单个回收
    async recoverySkinsApi(id, index) {
      const token = await get_token()
      this.get_token = token.data.toekn
      const res = await recovery_skins({
        player_skins_ids: Array(id),
        token: this.get_token
      })
      if (res.status == 1) {

        this.$message({
          message: res.msg,
          type: 'success'
        })

        if (this.resultList.length > 1) {
          // eslint-disable-next-line vue/no-mutating-props
          this.resultList.splice(index, 1)
          this.recoverySkins.splice(index, 1)
          this.$store.dispatch('getPlayerApi')
		  console.log(this.recoverySkins)
		  this.priceAlls()
        } else {
          this.$store.dispatch('getPlayerApi')
          this.closes()
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    // 全部回收
    async recoverySkinsAllApi() {
		/* console.log(this.recoverySkins)
		return false */
      const token = await get_token()
      this.get_token = token.data.toekn
      const res = await recovery_skins({
        player_skins_ids: this.recoverySkins,
        token: this.get_token
      })
      if (res.status === 1) {
        this.$message({
          message: res.msg,
          type: 'success'
        })
        this.$store.dispatch('getPlayerApi')
        this.closes()
      } else {
        this.$message.error(res.msg)
      }
    },
    // 弹窗关闭
    closes() {
      this.closesTime = 0
      this.translateY = 0
      this.results = false
      this.priceAll = 0
      this.recoverySkins = []
      clearTimeout(this.winningTimer)
      this.$emit('update:visible', false)
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' //出现滚动条
      document.removeEventListener('touchmove', mo, false)
    },


    // 中奖结果
    winningResults() {
      this.results = true
    }
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
@import '@/styles/variables.scss';
.BoxShowPopup {
  background: url('@/assets/images/BoxShowPopup/background.png') no-repeat;
  background-size: 100% 100%;
  margin-top: -2rem;
  width: 10rem;
  max-height: 16rem;
  min-height: 10rem;
  position: relative;
  .oneOn {
    margin-top: 0.1rem;
	margin-bottom: 1.2rem;
    // width: 4rem;
    .get {
      font-size: 0.6rem;
      font-weight: 700;
    }
  }
  .close {
    position: absolute;
    top: 1rem;
    right: 0;
    img {
      width: 1.5rem;
    }
  }
  // 单个
  .single-box {
    .single {
      position: relative;
      .centre {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 0.15rem;
        margin-top: 25%;
        .left {
          margin-top: 50px;
          img {
            width: 2rem;
          }
        }
        .animation {
          // position: relative;
          //  width: 2.6rem;
          //   height: 2.7rem;
          .animationDiv {
            height: 2rem;
          }
          .animationImg {
            width: 6rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 121;
          }
          .frame {
            // background-color: red;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 120;
            width: 3.5rem;
            height: 3.7rem;
            overflow: hidden;
            .animation_out {
              width: 100%;
              height: 75%;
              transform: translateX(0.1px);
              display: flex;
              .animation_box {
                width: 100%;
                height: 100%;
                flex: 1;
                transition: 6s cubic-bezier(0.2, 0.29, 0.15, 0.91);

                &.soundone {
                  transition: 9s cubic-bezier(0.2, 0.29, 0.15, 0.91);
                }

                &.soundtwo {
                  transition: 9s cubic-bezier(0.2, 0.3, 0.14, 0.92);
                }

                &.soundthree {
                  transition: 9s cubic-bezier(0.2, 0.31, 0.15, 1);
                }

                &.soundfour {
                  transition: 9s cubic-bezier(0.2, 0.32, 0.14, 0.89);
                }

                &.soundfive {
                  transition: 9s cubic-bezier(0.2, 0.33, 0.15, 0.9);
                }

                &.soundsix {
                  transition: 9s cubic-bezier(0.2, 0.32, 0.14, 0.98);
                }

                &.soundseven {
                  transition: 9s cubic-bezier(0.2, 0.31, 0.15, 0.95);
                }

                &.soundeight {
                  transition: 9s cubic-bezier(0.2, 0.3, 0.14, 0.96);
                }

                &.soundnine {
                  transition: 9s cubic-bezier(0.2, 0.29, 0.14, 0.92);
                }

                &.soundten {
                  transition: 9s cubic-bezier(0.2, 0.3, 0.15, 0.97);
                }

                // &.active {
                //   // transition: none !important;
                // }
                .animation_itemBox {
                  position: absolute;
                  top: 70%;
                  left: 46%;
                  transform: translate(-50%, -50%);
                  // overflow: hidden;
                  width: 100%;
                  height: 1rem;
                  .animation_item {
                    .back {
                      position: relative;
                      height: 100%;
                      img {
                        width: 100%;
                        // height: 135px;
                      }
                      .mainBody {
                        width: 2rem;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
        .right {
          @extend .left;
        }
      }
      .light {
        position: absolute;
        top: 90%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        img {
          width: 2.5rem;
        }
      }
    }
  }
  // 多个
  .multi-box {
    width: 8rem;
    margin: auto;
    // margin-top: 1rem;
    // background-color: red;
    display: flex;
    // justify-content: space-evenly;
    flex-wrap: wrap;
    justify-content: space-between;
    .single {
      margin-top: 10%;
      .centre {
        .animation {
          width: 3.7rem;
          .animationImg {
            width: 100%;
            height: 100%;
          }
          .frame {
            // background-color: red;
            height: 2.1rem;
            width: 2.7rem;
            .animation_out {
              // background-color: aqua;
              height: 2.2rem;
              .animation_box {
                .animation_itemBox {
                  top: 98%;
                  .animation_item {
                    .back {
                      position: relative;
                      height: 100%;
                      img {
                        width: 100%;
                        // height: 97px;
                      }
                      .mainBody {
                        width: 1.5rem;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .light {
        position: absolute;
        top: 70%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        img {
          width: 1.5rem;
        }
      }
    }
  }
  // 中奖结果
  .winningResults {
    .goods {
      ul {
        margin-top: 1rem;
        padding: 0 0.8rem;
        height: 6rem;
        overflow-y: auto;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        li {
          width: 48%;
          margin-bottom: 0.3rem;
        }
        width: 100%;
        .item {
          background-repeat: no-repeat;
          background-position: center;
          width: 100%;
          height: 2.5rem;
          background-size: 100%;
          // margin: auto;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .main {
            img {
              width: 1.5rem;
              // margin-top: 0.05rem;
            }
          }

          .name {
            font-size: 0.3rem;
            width: 2rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-top: -0.2rem;
            // margin-bottom: 2px;
          }
          .price {
            font-size: 0.3rem;
            display: flex;
            // margin-top: 0.2rem;
            img {
              width: 0.4rem;
              margin-right: 0.5rem;
              margin-top: -0.051rem;
            }
          }
        }
        // .item > div {
        //   // margin: ;
        // }

        .take {
          display: flex;
          justify-content: space-around;
          .putIn {
            font-size: 0.3rem;
            border: 2px $theme solid;
            border-radius: 5px;
            padding: 0.1rem 0.2rem;
            &:hover {
              background-color: $theme;
              color: $hoverText;
            }
          }
          .recycle {
            @extend .putIn;
          }
        }
      }
    }
    .all {
      display: flex;
      justify-content: center;
      margin-top: 0.3rem;
      .allPutIn {
        border: .03rem $theme solid;
        border-radius: 0.1rem;
        padding: 0.1rem 0.2rem;
        font-size: .3rem;
        &:hover {
          background-color: $theme;
          color: $hoverText;
        }
      }
      .allRecycle {
        @extend .allPutIn;
        margin-left: 1rem;
      }
    }
  }
}
</style>
