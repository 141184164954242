import request from '@/api/request'
// 关于我们
export const get_about_us = (data) => {
  return request({
    url: '/api/notice/get_about_us',
    method: 'POST',
    data
  })
}

// 隐私政策
export const get_privacy_olicy = (data) => {
  return request({
    url: '/api/notice/get_privacy_olicy',
    method: 'POST',
    data
  })
}

// 用户协议
export const get_terms_of_use = (data) => {
  return request({
    url: '/api/notice/get_terms_of_use',
    method: 'POST',
    data
  })
}

// 常见问题
export const get_problem = (data) => {
  return request({
    url: '/api/notice/get_problem',
    method: 'POST',
    data
  })
}

// 相关教程
export const get_tutorials = (data) => {
    return request({
      url: '/api/notice/get_tutorials',
      method: 'POST',
      data
    })
  }

  // 合规声明
export const get_compliance = (data) => {
  return request({
    url: '/api/notice/get_compliance',
    method: 'POST',
    data
  })
}

  // 联系我们
  export const get_contact_us = (data) => {
    return request({
      url: '/api/notice/get_contact_us',
      method: 'POST',
      data
    })
  }