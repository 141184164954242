import request from '@/api/request'
// 幸运饰品记录
export const get_open_luncky_record = (data) => {
  return request({
    url: '/api/lucky/get_open_luncky_record',
    method: 'POST',
    data
  })
}
// 首页公告
export const postNotice = (data) => {
  return request({
    url: '/api/notice/get_notice',
    method: 'POST',
    data
  })
}

// 开箱注册登录信息
export const get_total_info = (data) => {
  return request({
    url: '/api/index/get_total_info',
    method: 'POST',
    data
  })
}

// 首页箱子列表
export const get_box_list = (data) => {
  return request({
    url: '/api/box/get_box_list',
    method: 'POST',
    data
  })
}

// 首页banner
export const get_banner = (data) => {
  return request({
    url: '/api/banner/get_banner',
    method: 'POST',
    data
  })
}
// 开箱历史记录
export const get_open_box_record = (data) => {
  return request({
    url: '/api/box/get_open_box_record',
    method: 'POST',
    data
  })
}
