<!-- 开箱 -->
<template>
  <div class="body">
    <div :class="[{ introduced: AnimationsPopup },{ introduced: GamePlayShowModal }]"></div>
    <BodyBackground/>

    <div class="InBox">
      <!--  标题    -->
<!--      <div class="grade-title">-->
<!--        <span>等级箱子</span>-->
<!--      </div>-->
      <div class="free-title">
        <img
          class="free-title-off"
          src="@/assets/images/2title.png"
          alt=""
        />
        <div
          class="free-title-off-myOne free-title-off-one"
        >
          等级箱子
        </div>
        <div
          class="free-title-off-myTow"
          @click="$router.push({path:'/CdkBox'})"
        >
          口令箱子
        </div>
        <div class="paly-text" :style="VPointer()" @click="GamePlay">
          玩法介绍
        </div>
      </div>
      <div class="grade-speed-box">

        <div class="grade-speed">
          <div class="grade-icon">
            <img :src="gradeData.grade_img" alt="">
          </div>
          <span class="grade-num" v-if="gradeData.grade_num !== 5">XP  {{
              $store.state.userInfo.grade_amount || 0
            }}/{{ gradeData.end_num }}</span>
          <span class="grade-num" v-if="gradeData.grade_num === 5">XP  {{ $store.state.userInfo.grade_amount }}</span>
          <div class="grade-line" v-if="gradeData.grade_num !== 5"
               :style="'width:'+ ($store.state.userInfo.grade_amount/gradeData.end_num)*100+'%'">

          </div>
          <div class="grade-line" v-if="gradeData.grade_num === 5" style="width: 100%;">

          </div>
        </div>

      </div>
      <!-- 轮播图  -->
      <div class="lunbo">
        <carousel-3d
          v-if="boxDataList.length"
          :autoplay="false"
          :autoplayTimeout="3000"
          :perspective="0"
          :space="100"
          :display="5"
          :animationSpeed="300"
          :width="120"
          :height="157"
          :border="0"
          ref="carousel"
          :on-slide-change="onSlideChanged"
        >
          <slide v-for="(item, i) in boxDataList" :index="i" :key="i">
            <template slot-scope="obj">
              <div class="lunbo-li" @click="imgClick(item,obj,i)">
                <div class="lunbo-box">
                  <img class="lunbo-box-box" :src="item.img_main" alt=""/>
                  <img class="lunbo-box-res" :src="item.img_active" alt=""/>
                </div>

                <div class="lunbo-li-title">{{ item.name }}</div>

                <div class="lock-btn" v-if="$store.state.userInfo.grade_id >= item.id">
                  已解锁
                </div>
                <div class="lock-btn" v-else>
                  <img src="@/assets/images/GradeBox/lock.png" alt="">
                  锁定
                </div>
              </div>
            </template>
          </slide>
        </carousel-3d>
      </div>
      <!--  开箱    -->
      <div class="box-main">
        <img class="body-main-openBox-box" :src="boxData.img_main" alt=""/>
        <img class="body-main-openBox-res" :src="boxData.img_active" alt=""/>
        <!-- 开启按钮 -->
        <div @click="open(boxDataList[activeBox].id)" class="box-main-openBut" :style="VPointer()">
          免费{{ open_box_num }}次
        </div>
        <p class="box-des" v-if="boxDataList.length">{{ boxDataList[activeBox].remark }}</p>
      </div>
      <!-- v-show="AnimationsPopup && skinsList" -->
      <BoxShowPopup
        class="BoxShowBodyOpenTake"
        v-show="AnimationsPopup && skinsList"
        :visible.sync="AnimationsPopup"
        :count="count"
        :resultList="resultList"
        :show_prize="show_prize.show"
      />

      <!-- 单开 -->
      <transverse v-if="showAnimations" :goodsList="boxData.skins" :prize_data="prize_data" @end="end" ref="transverse"
                  @finishCallback="finishCallback"></transverse>

      <!-- 多开 -->
      <portrait v-if="showAnimations" :goodsList="boxData.skins" :prize_data="prize_data" @end="end"
                @finishCallback="finishCallback" ref="portrait"></portrait>
      <!--   玩法介绍   -->
      <div
        class="fight-body-battleground-but-ShowModal"
        v-show="GamePlayShowModal"
      >
        <GamePlay :visible.sync="GamePlayShowModal"/>
      </div>
    </div>
  </div>
</template>

<script>
// import BoxShowPopup from '../../components/boxShow/BoxShowPopup.vue'

import transverse from './transverse.vue'
import portrait from './portrait.vue'

import BoxShowPopup from '../../components/GradeBoxShow/BoxShowPopup.vue'
import BodyBackground from '../../components/BodyBackground.vue'
import HomeBodyAlert from '../../components/home/Home_alert.vue'
import {open_box, get_open_box_record} from '@/api/index/boxCorrelation'
import {Carousel3d, Slide} from 'vue-carousel-3d'
import bus from '@/utils/bus.js'
import {get_open_box_num, open_grade_box} from "@/api/index/Grade";
import GamePlay from "@/views/Grade/components/GamePlay.vue";

export default {
  name: 'BoxShowBody',
  components: {
    GamePlay,
    Carousel3d,
    Slide,
    transverse,
    portrait,
    BoxShowPopup,
    BodyBackground,
    HomeBodyAlert
  },
  data() {
    return {
      count: 1, // 箱子个数
      showAnimations: true, // 动画
      AnimationsPopup: false, // 弹出层
      //audio: new Audio(require('@/assets/audio/滚动出枪.mp3')), //过渡声音
      current: new Audio('https://oss.n7buff.com/mobile/audio/roll9.mp3'), //过程声音
      show_audio: new Audio('https://oss.n7buff.com/mobile/audio/Shoot.mp3'), //过程声音
      prize_data: {
        //奖品信息
        show: '',
        list: []
      },
      show_prize: {
        //奖品信息(展示所用，别问什么写两个)
        show: false,
        list: []
      },
      recordList: [], // 开箱历史
      resultList: [], // 结果
      skinsList: [],// 动画总数据,
      showSounds: true,
      open_box_num: 0, //开启次数
      activeBox: 0,
      gradeData: {},
      GamePlayShowModal: false,
      currentIndex: 0
    }
  },
  props: {
    boxDataList: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: []
    },
    boxData: Object,
    gradeList: Array
  },
  watch: {
    // 监听弹出层的变化
    AnimationsPopup() {

      if (!this.AnimationsPopup) {
        //this.audio.pause()
      }
    },
    boxDataList() {
      if (this.boxDataList.length > 0) {
        console.log('1111')
        this.$emit('getBoxDataList', this.boxDataList[0].id)
        if (!sessionStorage.getItem('n7_token')) {
          return
        }
        this.getOpenBoxNumApi(this.boxDataList[0].id)
      }
    },
    gradeList() {
      if (!sessionStorage.getItem('n7_token')) {
        this.getGrade(0)
      } else {
        this.getGrade(this.$store.state.userInfo.grade_amount)
      }

    }
  },
  beforeDestroy() {
    bus.$off('colseInterval')
    bus.$off('openInterval')
  },
  created() {
    // this.getOpenBoxRecordApi()
    /*   open_box({
          box_id: this.$route.query.id,
          total: this.count
        }).then((res) => {
          this.resultList = res.data
        }) */
  },
  methods: {
    // 开箱动画数据
    // async getBoxDataApi() {
    //   const res = await get_box_data({ id: this.$route.query.id })
    //   this.skinsList = res.data.skins
    //   console.log(this.skinsList)
    // },
    // 开箱记录
    // getOpenBoxRecordApi() {
    //   get_open_box_record().then((res) => {
    //     this.recordList = res.data
    //   })
    // },
    onSlideChanged(index) {
      console.log(index)
      this.activeBox = index
      this.$emit('getBoxDataList', this.boxDataList[this.activeBox].id)
      if (!sessionStorage.getItem('n7_token')) {
        return
      }
      this.getOpenBoxNumApi(this.boxDataList[this.activeBox].id)
    },
    // handleTouchStart(){
    //   // console.log(2222)
    //   console.log(this.$refs.carousel.currentIndex)
    // },
    // 玩法介绍弹出
    GamePlay() {
      this.GamePlayShowModal = true
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', mo, false) //禁止页面滑动
    },
    // 点击开启按钮
    imgClick(data, obj, index) {
      this.activeBox = index
      this.$emit('getBoxDataList', data.id)
      if (!sessionStorage.getItem('n7_token')) {
        return
      }
      this.getOpenBoxNumApi(data.id)
    },
    //  获取等级盲盒开启次数
    async getOpenBoxNumApi(id) {
      const res = await get_open_box_num({grade_box_id: id})
      // console.log(res)
      this.open_box_num = res.data.open_box_num
    },
    // 获取当前等级
    getGrade(value) {
      console.log(value)
      const grades = this.gradeList
      for (let i = grades.length - 1; i >= 0; i--) {
        const currentGrade = grades[i];
        if (value >= currentGrade.start_num && value <= currentGrade.end_num) {
          this.gradeData = currentGrade
          console.log(this.gradeData)
          this.$forceUpdate()
          return currentGrade;
        }
      }
      return null; // 如果没有匹配的等级区间，则返回null
    },
    open(id) {
      //console.log("动画开启 "+this.showAnimations)
      // 判断用户是否登录
      if (!sessionStorage.getItem('n7_token')) {
        this.$store.dispatch('setLogSignRetrieve', true)
        return
      }
      /* clearInterval(this.timer)
      return */
      open_grade_box({
        grade_box_id: id,
      }).then((res) => {
        // console.log(res.data);
        if (res.status === 1) {
          this.$store.dispatch('getPlayerApi')
          this.resultList = res.data
          this.open_box_num--
          this.getOpenBoxNumApi(id)
          if (this.showAnimations) {
            //动画开启
            this.Dijah = true
            bus.$emit('colseInterval')
            // this.current.play()
            if (this.resultList.length > 1) {
              this.$refs.portrait.Arraysplicing(this.resultList, 'portrait')
            } else {
              this.$refs.transverse.Arraysplicing(this.resultList, 'transverse')
            }
          } else {
            //动画关闭
            this.show_audio.play()
            this.$nextTick(() => {
              this.show_prize = {
                show: true,
                list: this.resultList
              }
              this.AnimationsPopup = true
            })
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 数量设置
    countImg(count) {
      this.count = count
    },
    // 声音开关
    showSound() {
      this.showSounds = !this.showSounds
    },
    // 动画开关
    showAnimation(status) {
      if (this.showAnimations) {
        this.showAnimations = false
        if (!status) {
          this.end()
        }

      } else {
        this.showAnimations = true
      }
    },
    //子组件动画结束
    end() {
      this.show_audio.play()
      //this.show_audio.play()
      //this.showAnimations = false
      this.AnimationsPopup = true
      //this.Dijah = false //电池光效复原
      this.show_prize = {
        show: true,
        list: this.prize_data.list
      }
      this.prize_data = {
        show: '',
        list: []
      }
      bus.$emit('openInterval')

    },
    finishCallback(data, str) {
      //console.log('data, str: ', data, str)
      setTimeout(() => {
        this.$nextTick(() => {
          this.prize_data = {
            show: str,
            list: data
          }
        })
      }, 550)
    },
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/BoxShowBody.scss';
@import '@/styles/BlindBoxFight.scss';
@import '@/styles/FreeSkinBody.scss';

.introduced {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 90;
  transition: all 0.15s linear;
}

.BoxShowBodyOpenTake {
  position: fixed !important;
  z-index: 91;
  top: 15vh;
  left: 0;
  // background-color: aqua;
}
.fight-body-battleground-but-ShowModal{
  z-index: 99;
  position: relative;
  //margin-top: 10vh;
}
::v-deep .int-box {
  z-index: 99;
  margin-top: 10vh;
}

.InBox {
  padding: 0 0.5rem;
  //margin-top: 30px;
}

.grade-title {
  width: 100%;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("@/assets/images/GradeBox/title-bg.png") no-repeat;
  background-size: 100% 100%;

  span {
    font-size: 24px;
    color: #FFFFFF;
    font-weight: bold;
  }
}

// 轮播图
.lunbo {
  display: flex;
  margin-top: 50px;

  .lunbo-li {
    width: 100%;
    height: 100%;
    background: url("@/assets/images/GradeBox/lunbo-bg.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;

    .lunbo-box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 179px;
      height: 143px;
      position: relative;
      margin: 0 auto;
      margin-top: 120px;

      .lunbo-box-box {
        height: 260px;
      }

      .lunbo-box-res {
        height: 168px;
        position: absolute;
        top: -13%;
      }
    }

    .lunbo-li-title {
      text-align: center;
      font-size: 24px;
      margin-top: 50px;
    }

    .lock-btn {
      width: 243px;
      height: 63px;
      background: url("@/assets/images/GradeBox/lock-bg.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      margin: 0 auto;
      margin-top: 30px;
      color: #272636;

      img {
        width: 44px;
        height: 44px;
      }
    }
  }

  .current {
    .lunbo-li {
      background: url("@/assets/images/GradeBox/lunbo-bg-active.png") no-repeat;
      background-size: 100% 100%;
    }

    .lock-btn {
      background: url("@/assets/images/GradeBox/lock-bg-active.png") no-repeat;
      background-size: 100% 100%;
    }
  }
}

//  开箱主体
.box-main {
  width: 100%;
  height: 758px;
  background: url("@/assets/images/GradeBox/open-bg.png") no-repeat bottom;
  background-size: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: -50px;

  .body-main-openBox-box {
    //width: 623px;
    //height: 600px;
    top: 7%;
  }

  .body-main-openBox-res {
    //width: 404px;
    //height: 307px;
    top: 17%;
  }

  .box-main-openBut {
    width: 454px;
    height: 124px;
    position: absolute;
    bottom: 20px !important;
    background: url("@/assets/images/GradeBox/box-open.png") no-repeat;
    background-size: 100% 100%;
    text-align: center;
    font-size: 60px;
    color: #FFFFFF;
    font-weight: bold;
    line-height: 129px;
  }

  .box-des {
    text-align: center;
    font-size: 16px;
    color: #FFFFFF;
    position: absolute;
    bottom: -160px;
  }
}

.carousel-3d-slide img {
  width: auto;
}

.carousel-3d-slide {
  background: transparent !important;
}

.grade-speed {
  width: 1000px;
  height: 82px;
  margin: 0 auto;
  margin-top: 20px;
  background: url("@/assets/images/GradeBox/speed-bg.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  align-items: center;
  //justify-content: center;
  padding: 0 26px;

  .grade-line {
    width: 0%;
    height: 46px;
    position: relative;
    background: #5CCEF3;
    //transform: perspective(5px) rotateX(-5deg);
    //&:after{
    //  content: '';
    //  width: 18px;
    //  height: 28px;
    //  background: url("@/assets/images/GradeBox/speed-left.png") no-repeat;
    //  background-size: 100% 100%;
    //  position: absolute;
    //  left: 0;
    //  top: -4px;
    //}
  }

  .grade-num {
    font-size: 18px;
    position: absolute;
    right: 20px;
    z-index: 2;
  }

  .grade-icon {
    width: 105px;
    position: absolute;
    left: -130px;

    img {
      width: 100%;
    }
  }
}

.grade-speed-box {
  position: relative;
  z-index: 2;
  display: flex;
  //align-items: center;


}
.paly-text {
  width: 240px;
  height: 100px;
  background: url("@/assets/images/GradeBox/paly.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #4EFEFE;
  cursor: pointer;
  margin-top: 18px;
  position: absolute;
  right: 0;
  top: 0px;
}
</style>
