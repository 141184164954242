<template>
  <el-dialog
    :visible="gamePopShow"
    custom-class="open-pop-body"
    :append-to-body="true"
    :close-on-click-modal="false"
    :style="BodyPointer()"
    @close="popClose"
  >
    <div class="open-pop-main">
      <div class="close" :style="VPointer()" @click="popClose">
        <img src="@/assets/images/BoxShowPopup/close.png" alt="">
      </div>
      <div class="open-pop-title">{{ title }}</div>
      <div class="open-des">
        <div v-html="html"></div>
      </div>
    </div>

  </el-dialog>
</template>
<script>
import {get_play_introduction} from "@/api/index/card";
import {BodyPointer} from "@/utils/style";

export default {
  props:{
    gamePopShow:{
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      html:'',
      title: ''
    }
  },
  watch:{
    gamePopShow:{
      handler(newVal){
        console.log(newVal)
        if (newVal){
          this.getPlayIntroduction()
        }
      },
      deep:true,
      immediate: true
    }
  },
  methods:{
    BodyPointer,
    getPlayIntroduction() {
      get_play_introduction().then(res => {
        this.title = res.data.title
        this.html = res.data.content
      })
    },
    popClose() {
      this.$emit('close')
    },
    isOPen() {
      this.$emit('determine', this.openNum)
    }
  }
}
</script>

<style>
.v-modal{
  opacity: 0.8 !important;
}
</style>
<style scoped lang="scss">
::v-deep .open-des p{
  font-size: 0.3rem;
}
::v-deep .open-pop-body{
  width: 80%;
  height: 1384px;
  background: url("@/assets/images/card/open-pop-bg.png") no-repeat;
  background-size: 100% 100%;
  margin-top: 15vh !important;
  .el-dialog__body{
    padding-top: 80px;
    padding-bottom: 0;
  }
  .open-pop-title{
    font-size: 0.5rem;
    color: #00FFFC;
    text-align: center;
    margin-bottom: 26px;
  }
  .open-des{
    max-height: 1065px;
    overflow: auto;
    padding: 0 75px;
  }
  .close{
    position: absolute;
    right: -45px;
    top: -40px;
    width: 155px;
    height: 155px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
