<template>
  <div id="app" :style="BodyPointer()">
    <div class="home-head" v-if="$route.path !== '/card' &&  $route.path !== '/cardList'">
      <HomeHead :checked="checkeds" />
    </div>
    <router-view />
    <div class="home-foot" v-if="$route.path !== '/card' &&  $route.path !== '/cardList'">
      <FunctionBar />
      <HomeFoot />
    </div>
    <invitePop></invitePop>
  </div>
</template>
<script>
// import BodyBackground from '@/components/BodyBackground.vue'
import HomeHead from '@/views/HomeHead.vue'
import HomeFoot from '@/views/HomeFoot.vue'
import FunctionBar from '@/views/functionBar.vue'
import invitePop from '@/components/Battle/invitePop.vue'
export default {
  data() {
    return {
      blackAndWhite: false,
      checkeds:1,
      name:null
    }
  },
  mounted(){
  },
  watch: {
        $route(to, from){
          console.log(to)
          this.name = to.name
           if(to.fullPath=='/'){
            this.checkeds = 1
           }else if(to.fullPath=='/FreeSkin'){
            this.checkeds = 2
           }else if(to.fullPath=='/BlindBoxFight'){
            this.checkeds = 3
           }else if(to.fullPath=='/GradeBox'){
            this.checkeds = 4
           }else if(to.fullPath=='/CdkBox'){
             this.checkeds = 4
           }else if(to.fullPath=='/OrnamentStore'){
            this.checkeds = 5
           }else if(to.fullPath=='/personalUser'){
            this.checkeds = 6
           }else if(to.fullPath=='/myBack'){
            this.checkeds = 7
           }else if(to.fullPath=='/MyLog'){
            this.checkeds = 8
           }else if(to.fullPath=='/MyPromotion'){
            this.checkeds = 9
           }else if(to.fullPath=='/stationInformation'){
            this.checkeds = 10
           }else if(to.fullPath=='/StarStore'){
            this.checkeds = 16
           }else{
            this.checkeds = null
           }
        },
    },
  components: {
    HomeHead,
    HomeFoot,
    FunctionBar,
    invitePop
  },
	created() {
    this.name = this.$route.name
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
		if (!this._isMobile()) {
			this.$router.onReady(() => {
				if (this.$route.query.code) {
					window.location.href = 'https://n7buff.com/#/?code=' + this.$route.query.code
				} else {
					window.location.href = 'https://n7buff.com/'
				}
			})
		}
		this.$router.onReady(() => {
					if (this.$route.query.app) {
						sessionStorage.setItem('app', 'app');
					}
				});
	},
	methods: {
		//判断访问设备
		_isMobile() {
			let flag = navigator.userAgent.match(
				/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				)
			if (flag == 'iPad,iPad') {
				flag = false
			}
			return flag
		},
    beforeunloadFn(e) {
      console.log('刷新或关闭')
      sessionStorage.removeItem('selfScrollTop')
      // ...
    }
	},
  destroyed() {
  window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  }
}
</script>
<style lang="scss">
@import '@/styles/variables.scss';
.tool{
  background: transparent !important;
}
.loadingInstance .el-loading-spinner .path{
  stroke: #1ef0ed !important;
}
.loadingInstance   .el-loading-spinner .circular{
    height: 1.5rem !important;
    width: 1.5rem !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #fff;
  font-size: 16px;
  background: url('https://oss.n7buff.com/mobile/assets/images/Background_starlight.png') no-repeat;
  background-size: 1600px !important;
}
.home-head {
  width: 100%;
  z-index: 80;
  position: fixed;
  top: 0;
}
.el-message{
  max-width: 5.5rem;
  width: 5.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .3rem 0 !important;
  top: 1.5rem !important;
   font-size: .36rem !important;

}
.el-message--success .el-icon-success{
  display: none;
}
// .home-body {
//   margin-top: 140px;
// }
// html {
//   filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
//   -webkit-filter: grayscale(90%);
// }
.hnk {
  display: inline-block;
  vertical-align: top;
}

body {
  padding-top: 1.2rem;
  background-color: #272451;
  font-size: 0.3rem;
	margin: 0;
  font-family: 微软雅黑;
}



// *{
//   touch-action: pan-y;
// }
</style>
<style>
.el-button--small{
  font-size: .3rem !important;
}
.el-message-box {
  position: relative;
  /* position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10000; */
  width: 7rem !important;
  height: 5rem !important;
  background-color: rgba(255, 255, 255, 0);
  background-image: url(https://oss.n7buff.com/mobile/assets/images/bgs.png);
  background-color: rgba(255, 255, 255, 0) !important;
  background-size: 100% 100%;
  border: none !important;
  /* font-size: 0.4rem !important; */

}
.el-button--default{
  background: rgb(181, 181, 181);
}
.el-message-box__title{
    width: 100%;
    font-size: .36rem !important;
    height: .8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color:#fff !important;
    /* border: 1px solid red; */
}
.el-message-box__message {
  margin-top: 0.4rem !important;
  /* background-color: red; */
  height: 2rem;
  font-size: 0.4rem;
  color: #fff;
  /* border: 1px solid red; */

}
.el-message-box__content{
  height: 2rem !important;
}
.el-message-box__message p {
  line-height: 1rem !important;
  text-align: center;
}
.el-message-box__message p img {
  width: 2rem;
}
.el-message-box__status::before {
  padding-left: 1rem !important;
  /* width: 1rem !important; */
}
.el-message-box__btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  padding: 0 0.5rem !important;
  position: absolute;
  bottom: 0.4rem;
  right: 0;
}
.el-message-box__btns .el-button {
  /* padding: 0.1rem; */
  width: 2rem;
  height: 0.8rem;
  border: none;
  border-radius: 0.2rem;
  margin: 0 0.2rem;
}
.el-icon-close:before {
  display: none;
  content: '\e6db';
  color: #fff;
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  background-color: #2724514a;
  border-radius: 50%;
  padding: 0.1rem;
  font-size: 0.5rem;
}
.index_title {
  font-size: 0.5rem;
  font-weight: 700;
  background-image: url('@/assets/images/showImages/n7open.png');
  background-size: 100%;
  argin: 0.3rem 0;
}
.el-message__content{
  font-size: .3rem !important;
}
</style>
