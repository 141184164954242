<!-- 饰品商城 -->
<template>

	<div class="app OrnamentStore">
		<div :class="[{ introduced: GamePlayShowModal }]"></div>

		<div class="index_title">饰品商城</div>
		<div class="integral">
			<div class="my_integral" v-if="this.$store.state.token">
				积分：{{$store.state.userInfo.integral ? $store.state.userInfo.integral : 0.00}}
			</div>
			<div class="integral_log" v-if="this.$store.state.token" @click="$router.push('/MyIntegralLog')">
				[积分日志]
			</div>
			<div class="introduction_play" @click="GamePlay">
				[玩法介绍]
			</div>
		</div>

		<div class="fight-body-battleground-but-ShowModal" v-show="GamePlayShowModal">
			<GamePlay :visible.sync="GamePlayShowModal" />
		</div>

		<!-- 菜单分类 -->
		<MenuItems @shopSkinListS="shopSkinListS" />
		<!-- 全部商品 -->
		<div class="StoreAllGoods" v-infinite-scroll="load" :infinite-scroll-distance="20" infinite-scroll-delay="200"
			style="overflow-y: auto;height:70vh;" :infinite-scroll-disabled="false">
			<ul v-if="shopSkinList.length">
				<li v-for="item in shopSkinList" :key="item.id">
					<div class="back">
						<img :src="item.shop_thumb" alt="" />
					</div>
					<div class="subject">
						<img :src="item.image_url" alt="" />
					</div>
					<div class="shadow">
						<img src="@/assets/images/showImages/shadow.png" alt="" />
					</div>

					<el-tooltip placement="bottom">
						<div slot="content">{{ item.item_name }}</div>
						<div class="nameText" :title="item.item_name">
							{{ item.item_name }}
						</div>
					</el-tooltip>
					<div class="price">
						<div class="img">
							<img src="https://oss.N7buff.com/n7_integral.png" alt="" />
						</div>
						<div class="priceText">{{ item.price }}</div>
					</div>
					<div class="exchange" @click="ImmediateExchange(item.id,item.price,item.item_name)">
						立即兑换
					</div>
				</li>
			</ul>
			<div v-else class="noMore">
				<div>没有更多了</div>
			</div>
		</div>

	</div>
</template>

<script>
	import MenuItems from '@/components/MenuItems.vue'
	import {
		get_shop_list,
		buy_skin
	} from '@/api/index/AccessoriessTore'
	import MyIntegralLog from './components/MyIntegralLog.vue'
	import GamePlay from './components/GamePlay.vue'
	export default {
		name: 'OrnamentStore',
		data() {
			return {
				imgurl: 'https://oss.N7buff.com/n7_integral.png',
				shopSkinList: [], // 商城饰品列表
				total_rows: null,
				GamePlayShowModal: false, // 玩法介绍
				page: 1,
				page_size: 10,
				page_count: null,
				skin_category_id: null, // 一级id
				skin_category_sub_id: null, // 二级id
				quality_id: null, // 类别id
				rarity_id: null, // 品质id
				exterior_id: null, // 外观id
				lucky_recomm: 0, // 是否推荐
				start_price: '',
				end_price: ''
			}
		},
		components: {
			MenuItems,
			GamePlay,
			MyIntegralLog
		},
		mounted() {
			// window.addEventListener('scroll', this.getbottom)
		},
		created() {
			if (sessionStorage.getItem('n7_token')) {
				this.$store.dispatch('getPlayerApi')
			}
			this.getSkinListApi()
		},
		methods: {
			load() {
				if (this.page != this.page_count) {
					this.page++
					this.getSkinListApi()
				}
			},
			// getbottom() {
			//   // 返回滚动条垂直滚动距离
			//   let scrollTop =
			//     document.documentElement.scrollTop || document.body.scrollTop
			//   // 返回该元素的像素高度，高度包含内边距（padding），不包含边框（border），外边距（margin）和滚动条
			//   let clientHeight =
			//     document.documentElement.clientHeight || document.body.clientHeight
			//   // 返回该元素的像素高度，高度包含内边距（padding），不包含外边距（margin）、边框（border）
			//   let scrollHeight =
			//     document.documentElement.scrollHeight || document.body.scrollHeight
			//   let numHeight = scrollTop + clientHeight
			//   if (scrollHeight > clientHeight && numHeight > scrollHeight - 1) {
			//    if(this.page!=this.page_count){
			//     this.page++
			//     this.getSkinListApi()
			//    }
			//   }
			// },
			// 子传父获取
			shopSkinListS(shopSkinList, parmas, total_rows) {
				this.nameAccessories = parmas.name
				this.skin_category_id = parmas.skin_category_id
				this.skin_category_sub_id = parmas.skin_category_sub_id
				this.quality_id = parmas.quality_id
				this.rarity_id = parmas.rarity_id
				this.exterior_id = parmas.exterior_id
				this.start_price = parmas.start_price
				this.end_price = parmas.end_price
				this.lucky_recomm = parmas.lucky_recomm
				this.total_rows = total_rows
				this.shopSkinList = shopSkinList
			},
			getSkinListApi() {
				get_shop_list({
					page: this.page,
					page_size: 10,
					name: this.nameAccessories,
					skin_category_id: this.skin_category_id,
					skin_category_sub_id: this.skin_category_sub_id,
					quality_id: this.quality_id,
					rarity_id: this.rarity_id,
					exterior_id: this.exterior_id,
					lucky_recomm: this.lucky_recomm,
					start_price: parseInt(Number(this.start_price)),
					end_price: parseInt(Number(this.end_price)),
				}).then((res) => {
					this.total_rows = res.data.total_rows
					// this.shopSkinList = res.data.rows
					this.page_count = res.data.page_count
					res.data.rows.forEach(item => {
						this.shopSkinList.push(item)
					})
				})
			},
			// 立即兑换
			ImmediateExchange(id, skins_price, skins_name) {
				// 判断用户是否登录
				if (!sessionStorage.getItem('n7_token')) {
					this.$store.dispatch('setLogSignRetrieve', true)
					return
				}
				this.$confirm(`是否消耗<img style="width:0.4rem" src="${this.imgurl}">${skins_price}兑换 ${skins_name}?`, '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						dangerouslyUseHTMLString: true
					})
					.then(() => {
						buy_skin({
							skin_id: id
						}).then((res) => {
							if (res.status === 1) {
								this.$message({
									message: '兑换成功',
									type: 'success'
								})
								this.$store.dispatch('getPlayerApi')
								// this.getSkinListApi()
							} else {
								this.$message.error(res.msg)
							}
						})
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: '取消兑换'
						})
					})
			},
			// 加载更多
			loadMore() {
				this.page_size = this.page_size + 10
				this.getSkinListApi()
				console.log(this.skin_category_id);
			},
			MyIntegralLog() {
				console.log("我的积分日志")
				this.$router.push({
					path: '/MyIntegralLog'
				})
			},
			// 玩法介绍弹出
			GamePlay() {
				this.GamePlayShowModal = true
				var mo = function(e) {
					e.preventDefault()
				}
				document.body.style.overflow = 'hidden'
				document.addEventListener('touchmove', mo, false) //禁止页面滑动
			},
		}
	}
</script>
<style lang="scss" scoped>
	// 引入css类
	@import '@/styles/OrnamentStore.scss';

	.noMore {
		text-align: center;
		position: absolute;
		top: 50%;
		left: calc(50% + 1rem);
		transform: translate(-50%, -50%);

		div {
			color: #999;
		}
	}

	.priceText {
		color: #FFFF00;
	}

	.integral {
		width: 100%;
		height: 1rem;
		line-height: 1rem;
		font-size: 0.3rem;
	}

	.my_integral {
		width: 33%;
		float: left;
		color: #FFFF00;
		font-size: 0.4rem;
	}

	.integral_log {
		width: 33%;
		float: left;
		color: #1EF0ED;
		font-size: 0.4rem;
	}

	.introduction_play {
		width: 33%;
		float: right;
		color: #1EF0ED;
		font-size: 0.3rem;
	}

	.fight-body-battleground-but-ShowModal {
		position: fixed;
		top: 40%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 99;
	}

	.introduced {
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.9);
		z-index: 90;
		transition: all 0.15s linear;
	}
</style>
