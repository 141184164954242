<template>
	<div class="down">
		<div class="font_style">
			<div class="font_farim">
				<div>
					<span class="Font_Italic">海 量</span>
					福 利
				</div>
				<p>
					<span class="Font_Italic">R O L L</span>
					不 停
				</p>
				<p>
					<span class="Font_Italic">下 载</span>
					即 可 领 取
				</p>
			</div>
			<div class="down_methods">
				<p class="load_font" @click="down_app">
					<!-- <p class="img"></p> -->
					<img class="andiord" src="@/assets/images/download/andiord.png" alt="" />
					安卓下载
				</p>
				<p class="load_font">
					<img src="@/assets/images/download/ios.png" alt="" />
					IOS敬请期待
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { get_down_app_url } from '@/api/index/TopUp';
export default {
	data() {
		return {};
	},
	methods: {
		down_app() {
			this.$confirm('是否下载?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			})
				.then(() => {
					get_down_app_url().then(res => {
						if (res.status == 1) {
							this.$message({
								type: 'success',
								message: '下载成功!'
							});
							window.open(res.data.down_url);
							this.closes();
						} else {
							this.$message({
								message: res.msg,
								type: 'error'
							});
						}
					});
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消下载'
					});
				});
			
		}
	}
};
</script>

<style lang="scss" scoped>
* {
	margin: 0;
	padding: 0;
	overflow: hidden;
}
.down {
	width: 100%;
	height: 16rem;
	overflow: hidden;
	background: url('@/assets/images/download/background.png') no-repeat;
	background-size: 110% 120%;
	background-position: -10px -100px;
	.font_style {
		margin: 0 auto;
		padding-top: 8.5rem;
		.font_farim {
			font-family: KaiTi;
			font-size: 0.85rem;
			font-weight: 600;
			.Font_Italic {
				font-size: 0.7rem;
				font-style: italic;
			}
		}
		.down_methods {
			display: flex;
			overflow: hidden;
			margin-left: 0.55rem;
			.load_font {
				width: 4.5rem;
				height: 1.8rem;
				line-height: 1.8rem;
				// margin-left: 0.3rem;
				margin-top: 0.5rem;
				background: url('@/assets/images/download/border.png') no-repeat;
				background-size: 100% 100%;
				display: flex;
				font-size: 0.45rem;
				font-weight: 600;
				justify-content: center;
				img {
					width: 0.8rem;
					height: 0.8rem;
					margin-top: 0.45rem;
					margin-right: 0.2rem;
				}
				.andiord {
					width: 1rem;
					height: 1rem;
				}
			}
		}
	}
}
</style>
