<!-- 对战历史 -->
<template>
  <div class="">
    <div
      class="int"
      :visible="visible"
      @update:visible="$emit('update:visible', $event)"
    >
      <div class="int-box">
        <div class="int-box-title">
          <div class="int-box-title-text">对战历史</div>
        </div>
        <div class="int-box-frame">
          <div class="int-box-frame-off" :style="VPointer()" @click="off"></div>
          <div class="int-box-frame-content">
<!--            <div class="int-box-frame-content-tab">-->
<!--              <div class="int-box-frame-content-tab-li" :style="VPointer()" @click="tabActiveClick(1)" :class="tabActive == 1?'int-box-frame-content-tab-active':''" >全部</div>-->
<!--              <div class="int-box-frame-content-tab-li" :style="VPointer()" @click="tabActiveClick(2)" :class="tabActive == 2?'int-box-frame-content-tab-active':''">欧皇</div>-->
<!--              <div class="int-box-frame-content-tab-li" :style="VPointer()" @click="tabActiveClick(3)" :class="tabActive == 3?'int-box-frame-content-tab-active':''">非酋</div>-->
<!--              <div class="int-box-frame-content-tab-li" :style="VPointer()" @click="tabActiveClick(4)" :class="tabActive == 4?'int-box-frame-content-tab-active':''">组队</div>-->
<!--            </div>-->
            <!-- 内容 -->
            <div class="int-box-frame-content-head">
              <div class="int-box-frame-content-head-time">时间</div>
              <div class="int-box-frame-content-head-player">玩家</div>
              <div class="int-box-frame-content-head-blind">盲盒</div>
              <div class="int-box-frame-content-head-value">价值</div>
            </div>
            <div class="int-box-frame-content-player">
              <!-- 盲盒 -->
              <div class="int-box-frame-content-player-blind">
                <ul class="int-box-frame-content-player-blind-mainUl">
                  <li
                    class="int-box-frame-content-player-blind-mainUl-li"
                    v-for="item in battleRoomRecord"
                    :key="item.id"
					@click="toPlay(item.id)"
                  >
                    <!-- 时间 -->
                    <div
                      class="int-box-frame-content-player-blind-mainUl-li-time"
                    >
                      {{ item.create_time | formatDate }}
                    </div>
                    <div
                      class="
                        int-box-frame-content-player-blind-mainUl-li-player
                        headImgBox
                      "
                    >
                      <!-- <span v-for="items in item.player_info" :key="items.id"
                        >{{ items.nike_name }}
                      </span> -->

                      <div  v-for="items in item.player_info" class="headImgView" :key="items.id">
                        <el-tooltip :content="items.nike_name" placement="bottom">
                          <img :src="items.head_img" alt="" class="headImg" />
                        </el-tooltip>
                      </div>
                    </div>
                    <!-- 盲盒 -->
                    <div
                      class="int-box-frame-content-player-blind-mainUl-li-back"
                    >
                      <div
                        v-for="itemBosList in item.battle_box_list"
                        :key="itemBosList.id"
                        class="backBox"
                      >
                      <div class="backBoxCount">{{itemBosList.battle_box_count}}</div>

                        <div
                          class="
                            int-box-frame-content-player-blind-mainUl-li-back-box
                          "
                        >
                          <img :src="itemBosList.img_main" alt="" />
                        </div>
                        <div
                          class="
                            int-box-frame-content-player-blind-mainUl-li-back-main
                          "
                        >
                          <img :src="itemBosList.img_active" alt="" />
                        </div>
                      </div>
                    </div>
                    <!-- 价值 -->
                    <div
                      class="int-box-frame-content-player-blind-mainUl-li-value"
                    >
                      <div class="priceBox">
                        <img src="@/assets/images/n7_money.png" alt="" />
                        {{ item.battle_box_sum_price }}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_battle_room_record } from '@/api/index/BlindBattle'
import { formatDate } from '@/utils/date'
export default {
  name: 'AgainstHistorical',
  data() {
    return {
      battleRoomCount: '', // 对战中房间数量
      battleRoomRecord: [], // 对战结束房间列表
      intTime: 'intTime', // 定时器开关
      tabActive: 1
    }
  },
  filters: {
    formatDate(time) {
      var date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd hh:mm:ss')
    }
  },
  props: {
    visible: Boolean
  },
  watch: {
    visible(val) {
      if (val) {
        this.getBattleRoomRecordApi()
        this.intTime = setInterval(() => {
          this.getBattleRoomRecordApi()
        }, 3000)
      }
    }
  },
  created() {},
  methods: {
    // 筛选
    tabActiveClick(index){
      this.tabActive = index
    },
    // 盲盒对战-对战结束房间列表
    getBattleRoomRecordApi() {
      get_battle_room_record({
          size:20
        }).then((ael) => {
          this.battleRoomRecord = ael.data.battle_room
          //console.log(this.battleRoomRecord);
        })
    },
    // 关闭
    off() {
      clearInterval(this.intTime)
      this.$emit('update:visible', false)
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' //出现滚动条
      document.removeEventListener('touchmove', mo, false)
    },
		// 进入房间
		toPlay(id) {
		  this.$router.push({ path: '/battleDetail', query: { id: id } })
		}
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
@import '@/styles/BlindBoxFightShow.scss';
.headImgView{
  margin-right: .1rem;

}
.int-box-frame-content-player-blind-mainUl-li .headImgView:last-child{
  margin-right: 0;
}
.int-box-frame-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  &-head {
    color: #23fffd;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 4;
    margin-bottom: 0.15rem;
    border-bottom: 2px #1ff0ed solid;
    padding-bottom: 0.1rem;
    & div {
      text-align: center;
      flex: 1;
      font-size: 0.3rem;
    }
  }
  // 盲盒
  &-player {
    .headImgBox{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-blind {
      &-mainUl {
        width: 100%;
        overflow-y: auto;
        padding-bottom:0.1rem;
        height:6.5rem;
        /* 滚动条整体部分 */
        &::-webkit-scrollbar {
          width: 0.1rem;
          height: 0.1rem;
          background-color: none;
          border-radius: 0.1rem;
        }
        /* scroll轨道背景 */
        &::-webkit-scrollbar-track {
          // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          border-radius: 0.1rem;
          background-color: rgba(216, 216, 216, 0);
        }
        /* 滚动条中能上下移动的小块 */
        &::-webkit-scrollbar-thumb {
          border-radius: 0.1rem;
          -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0);
          background-color: rgb(119, 117, 117);
        }
        &-li {
          border-bottom: 2px #1ff0ed solid;
          padding-bottom: 0.1rem;
          margin-bottom: 0.1rem;
          width: 8.2rem;
          display: flex;
          flex: 4;
          align-items: center;
          justify-content: space-between;
          // 时间
          &-time {
            font-size: 0.3rem;
            flex: 1;
          }
          // 玩家
          &-player {
            flex: 1;
            font-size: 0.3rem;
            .headImg{
              width: .5rem;
              height: .5rem;
              border-radius: 50%;
            }
          }
          // 盲盒
          &-back {
            flex: 1;
            // width: 150px;
            display: flex;
            justify-content: space-evenly;
            .backBox {
              position: relative;
            }
            &-box {
              // position: absolute;
              // top: 50%;
              // left: 50%;
              // transform: translate(-50%, -50%);
              & > img {
                width: 1rem;
              }
            }
            &-main {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              & > img {
                width: .8rem;
              }
            }
          }
          // 价值
          &-value {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: normal;
            font-size: 0.3rem;
            .priceBox {
              display: flex;
              justify-content: space-evenly;
              & > img {
                margin-right: 0.1rem;
                width: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}
.backBoxCount{
  width: .3rem;
  height: .3rem;
  background: #ffc400;
  text-align: center;
  line-height: .3rem;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: -5px;
  color: #000;
  z-index: 2;
}
.int-box-frame-content-tab{
  display: flex;
  margin-bottom: 20px;
  .int-box-frame-content-tab-li{
    width: 290px ;
    height: 130px;
    background: url("@/assets/images/fightImages/fight-tab.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    line-height: 130px;
    //align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #000000;
    opacity: 0.5;
  }
  .int-box-frame-content-tab-active{
    opacity: 1;
  }
}
</style>
