import request from '@/api/request'
// 登录
export const postLogin = (data) => {
  return request({
    url: '/api/login/login',
    method: 'POST',
    data
  })
}
//退出登录
export const signOut = (data) => {
  return request({
    url: '/api/login/sign_out',
    method: 'POST',
    data
  })
}
// 注册
export const postRegister = (data) => {
  return request({
    url: '/api/register/register',
    method: 'POST',
    data
  })
}
// 忘记密码—重置
export const postReset_password = (data) => {
  return request({
    url: '/api/login/reset_password',
    method: 'POST',
    data
  })
}
// 忘记密码--获取短信验证码
export const postLoginGet_code = (data) => {
  return request({
    url: '/api/login/get_code',
    method: 'POST',
    data
  })
}
// 获取短信验证码
export const postRegisterGet_code = (data) => {
  return request({
    url: '/api/register/get_code',
    method: 'POST',
    data
  })
}


// 短信登录
export const logincode_login = (data) => {
  return request({
    url: '/api/login/code_login',
    method: 'POST',
    data
  })
}
// 短信登录--获取短信验证码
export const get_login_code = (data) => {
  return request({
    url: '/api/login/get_login_code',
    method: 'POST',
    data
  })
}