<!-- 站内信息 -->
<template>
  <div class="stationInformation">
    <div class="index_title">站内信息</div>
    <div class="station-title">
      <div class="DeleteRead" :style="VPointer()" @click="del_player_mailApi">
        返回
      </div>
    </div>
    <ul>
      <li
      >
        <div class="player-title">
          <div class="player-name">
            {{ data.send_name }}
          </div>
          <div class="player-time">{{ data.update_time }}</div>
        </div>
        <div class="player-content" v-html="data.content"></div>
      </li>
    </ul>

    <!-- <div class="loadMore">
      <button class="btn" :style="VPointer()" @click="handleCurrentChange">
        上一页
      </button>
      <button class="btn_num">{{ page }}</button>
      <button class="btn" :style="VPointer()" @click="handleCurrentChangeTwo">
        下一页
      </button>
    </div> -->
  </div>
</template>

<script>
import {
  player_mail,
  get_player_mail,
  del_player_mail
} from '@/api/index/personalCenter'
export default {
  name: 'stationInformation',
  data() {
    return {
      data:{}
    }
  },
  mounted() {
    get_player_mail({
        mail_id: this.$route.query.id
      }).then((res) => {
        //console.log(res)
        if (res.status === 1) {
          this.data=res.data
			    this.$store.dispatch('getPlayerApi')
        }
      })
  },
  methods: {
    // 站内信息-删除已读
    del_player_mailApi() {
      this.$router.back()
    },
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
// @import '';
@import '@/styles/variables.scss';
.loadMore {
  padding-top: 0.3rem;
  width: 6rem;
  margin: auto;
  .btn {
    background-color: #1ef0ed;
    height: 0.7rem;
    width: 1.4rem;
    border-radius: 0.1rem;
    font-size: 0.3rem;
    border: none;
    margin: 0 0.2rem;
    color: #fff;
  }
  .btn_num {
    width: 0.7rem;
    height: 0.7rem;
    background-color: #1ef0ed;
    border-radius: 0.1rem;
    color: #fff;
    font-weight: 700;
    border: none;
    font-size: 0.3rem;
  }
}
.stationInformation {
  // height: 620px;
  position: relative;
  padding: 0.2rem;
  .station-title {
    display: flex;
    justify-content: space-between;
    .DeleteRead {
      border: 2px $theme solid;
      font-size: .3rem;
      padding: 0.1rem;
      &:hover {
        color: $theme;
      }
    }
  }
  ul {
    // background-color: blue;
    // height: 570px;
    // overflow-y: auto;
    padding-bottom: 0.2rem;
    li {
      font-size: 0.3rem;
      margin-top: 0.2rem;
      padding: 0.2rem;
      width: 9.6rem;
      // background: url('@/assets/images/personalCenter/站内信息back.png')
      //   no-repeat;
      border-bottom:0.05rem solid #1ef0ed;
      background-size: 100% 100%;
      .player-title {
        display: flex;
        justify-content: space-between;
        .hint {
          width: 0.2rem;
          height: 0.2rem;
          border-radius: 50%;
          background-color: red;
          display: inline-block;
        }
      }
      .player-content {
        margin-top: 0.1rem;
      }
    }
  }
  // ul::-webkit-scrollbar {
  //   width: 8px;
  // }
  // ul::-webkit-scrollbar-thumb {
  //   border-radius: 10px;
  //   -webkit-box-shadow: inset 0 0 5px #1ef0ed;
  //   background: rgba(0, 0, 0, 0.2);
  // }
  // ul::-webkit-scrollbar-track {
  //   -webkit-box-shadow: inset 0 0 5px #1ef0ec65;
  //   border-radius: 0;
  //   background: rgba(0, 0, 0, 0.1);
  // }
  .pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
</style>
