<!-- SC:GO盲盒 -->
<template>
	<div class="home">
		<Alert />
		<Open />
		<Banner />
		<List />
	</div>
</template>

<script>
// postNotice
// import { postNotice } from "../api/index.js";
import Alert from '../../components/home/Home_alert.vue';
import Open from '@/components/home/Boxshow_history.vue';
import Banner from '@/components/home/Banner.vue';
import List from '@/components/home/Listarr.vue';
export default {
	components: {
		Alert,
		Open,
		Banner,
		List
	},
	data (){
		return{
			selfScrollTop:0
		}

	},
	created() {
		if (sessionStorage.getItem('n7_token')) {
			this.$store.dispatch('getPlayerApi');
		}
	},
	beforeRouteLeave(to, from, next) {
		//离开页面时记住原来的滚动条高度
		this.selfScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
		sessionStorage.setItem('selfScrollTop', this.selfScrollTop)
		console.log(this.selfScrollTop, '缓存离开页面的滚动距离')
		next();
	}
};
</script>
<style lang="scss" scoped></style>
