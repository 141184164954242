import axios from 'axios'
import store from '@/store'
import { Message } from 'element-ui'
// import store from '@/store'
// 创建了 axios 的实例 服务器上用的配置
const service = axios.create({
	baseURL: process.env.NODE_ENV === 'development' ? '' : 'https://api.n7buff.com',
  // baseURL: '',
	timeout: 50000,
	withCredentials: true,
	//crossDomain: true,
	headers: {
		post: {
		'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
		}
	}
  //headers: {'Access-Control-Allow-Credentials': true},

  //baseURL: process.env.VUE_APP_BASE_API
  //baseURL: 'http://n7buff.com' //process.env.VUE_APP_BASE_API
  // timeout: 5000 // 请求超时
})

//本地调试时打开的配置
/* const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API
  // timeout: 5000 // 请求超时
}) */


// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 在发送请求之前做一些事情

    if (store.state.token) {
      // 把Token 放入请求头里
      config.headers.Authorization = `${store.state.token}`
    }
    return config
  },

  (error) => {
    // 处理请求错误
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.status === 4444) {
      console.log(4444)
			store.dispatch('logout')
			localStorage.clear()
      store.dispatch('setLogSignRetrieve',true)
    }
    if (res.status !== 4444 && res.status !== 1) {
      Message({
        message: res.msg,
        type: 'error',
        duration: 3 * 1000
      })
    }
    return res
  },
  (error) => {
    console.log('err' + error)
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
