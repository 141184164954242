<!-- 幸运饰品开奖 -->
<template>
	<div class="app ornaOpen">
		<div :class="[{ showBack: openAnim }, { showBack: results }]"></div>
		<div class="oran-warp">
			<!-- <div class="oran-top">
        <div class="orantop-left">幸运饰品</div>
        <div class="orantop-right">获得饰品历史</div>
      </div> -->
			<div class="dd">
				<BoxShowBodyOpenTake ref="BoxShowBodyOpenTake" @changes="changes" />
			</div>
			<div class="oran-sel">
				<div class="open">
					<div class="open-box">
						<div class="box-one">
							<img src="@/assets/images/LuckyOrnament/left.png" />
						</div>
						<div class="box-two">
							<span>随机物品</span>
							<img src="@/assets/images/LuckyOrnament/box1.png" />
						</div>
						<div class="box-three">{{ value3 }}%</div>
						<div class="box-four">
							<div class="four-top">幸运饰品</div>
							<div class="four-bot">
								<div class="img">
									<img :src="skinList.image_url" />
								</div>
								<div class="name">{{ skinList.item_name }}</div>
								<img class="por" src="@/assets/images/LuckyOrnament/top.png" />
							</div>
						</div>
						<div class="box-five">
							<img src="@/assets/images/LuckyOrnament/right.png" />
						</div>
					</div>

					<!-- <div class="open-text">
            你会随机获得一件饰品，并有 {{ value3 }}%
            的机会额外获得一件珍稀的饰品
          </div> -->

					<div class="open-pro">
						<div class="pro-img">
							<img src="@/assets/images/LuckyOrnament/yuan1.png" />
							<div class="peo-num peo-num1">
								<div class="peo-num1-h5">{{ value3 }}%</div>
								<div>成功</div>
							</div>
						</div>
						<div class="pro-con">
							<el-slider v-model="value3" :show-tooltip="false" :min="5" :max="75" @change="changeNum">
							</el-slider>
						</div>
						<div class="pro-img">
							<img src="@/assets/images/LuckyOrnament/yuan2.png" />
							<div class="peo-num peo-num2">
								<div>{{ 100 - value3 }}%</div>
								<div>失败</div>
							</div>
						</div>
					</div>

					<div class="open-btn" :style="VPointer()">
						<el-button :disabled="disabled" class="open-btnwarp"
							:style="{ 'background-color': loading ? '#949493' : '' }" @click="upgrade">
							<i v-if="loading" class="el-icon-loading"></i>
							<span>花费</span>
							<div class="img" src="@/assets/images/n7_money.png" />
							<span>{{ impPri }}试试运气</span>
						</el-button>
					</div>

					<!-- 动画开关 -->
					<div
					  class="OrnamentOpen-showAnimation"
					  :style="VPointer()"
					  :class="
					    showAnimations
					      ? 'OrnamentOpen-showAnimationTrue'
					      : 'OrnamentOpen-showAnimationFalse'
					  "
					  @click="showAnimation(1)"
					>
					  <!-- 动画图标 -->
					  <div class="OrnamentOpen-showAnimationTrueIcon">
					    <svg
					      v-if="showAnimations"
					      xmlns="http://www.w3.org/2000/svg"
					      width="30"
					      height="30"
					      fill="currentColor"
					      class="bi bi-camera-video-fill"
					      viewBox="0 -3 16 16"
					    >
					      <path
					        fill-rule="evenodd"
					        d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"
					      />
					    </svg>
					    <svg
					      v-if="!showAnimations"
					      xmlns="http://www.w3.org/2000/svg"
					      width="28"
					      height="28"
					      fill="currentColor"
					      class="bi bi-camera-video-off-fill"
					      viewBox="0 -2 16 16"
					    >
					      <path
					        fill-rule="evenodd"
					        d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l6.69 9.365zm-10.114-9A2.001 2.001 0 0 0 0 5v6a2 2 0 0 0 2 2h5.728L.847 3.366zm9.746 11.925-10-14 .814-.58 10 14-.814.58z"
					      />
					    </svg>
					  </div>
					  <!-- {{ showAnimations ? '动画已开启' : '动画已关闭' }} -->
					</div>



					<!-- 动画 -->
					<div class="bg" v-show="openAnim">
						<div class="open-animBox">
							<div class="open-anim">
								<div class="open-line" ref="openLine"></div>
								<div class="open-anim-warp" :style="'transform: translateX(' + translateX + 'px);'">
									<div class="z" ref="animation_X"></div>
									<ul >
										<li ref="openLi" v-for="(item, index) in newList" :key="index" :style="{
                      backgroundImage: 'url(' + item.img + ')  no-repeat'
                    }">
											<img :src="item.image_url" />
										</li>
									</ul>
									<ul >
										<li v-for="(item, index) in newList" :key="index" :style="{
                      backgroundImage: 'url(' + item.img + ')  no-repeat'
                    }">
											<img :src="item.image_url" />
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<!-- 中奖结果 -->
					<div class="winningResults" v-if="results">
						<img :class="{ introducedno: !status }" class="winningResults-backImg"
							src="https://oss.n7buff.com/mobile/assets/images/LuckyOrnament/luncky.png" alt="" />
						<div class="goods">
							<div class="item">
								<div class="close" :style="VPointer()" @click="on">
									<img :class="{ introducedno: !status }"
										src="../../../assets/images/BoxShowPopup/close.png" alt="" />
								</div>
								<div class="subject">
									<img v-if="this.status" :src="skinList.image_url" alt="" />
									<img v-if="!this.status" src="../../../assets/images/LuckyOrnament/moneyback.png"
										alt="" />
								</div>
								<div v-if="this.status" class="name" :title="skinList.item_name">
									{{ skinList.item_name }}
								</div>
								<div class="KO" v-if="!this.status">
									<img src="../../../assets/images/n7_money.png" alt="" />
									<div class="KO-name">0.01</div>
								</div>
								<div class="price" v-if="this.status">
									<img src="../../../assets/images/n7_money.png" alt="" />
									<div>{{ skinList.price }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import Utils from "./../assets/js/util.js";
	import BoxShowBodyOpenTake from '@/views/LuckyOrnament/components/OrnamentList.vue'
	import {
		opne_luncky_skin,
		get_skin_detail
	} from '@/api/index/LuckyAccessories'
	export default {
		data() {
			return {
				openAnim: false, //动画弹窗
				openAnimOn: false,
				showAnimations: true, // 动画
				openWidth: 0,
				translateX: 0,
				Width: 0,
				openAnimList: [],
				disabled: false, // 按钮是否禁用
				results: false, // 中奖结果弹窗
				loading: false,
				status: null, // 是否中奖
				pirce1: '',
				pirce2: '',
				search: '',
				value3: 25, // 概率
				winState: false,
				price: '',
				obj: '',
				valueState: false,
				impPri: null, // 价格估算
				noWin: {
					name: '赠品',
					price: '0.01',
					image_url: require('../../../assets/images/LuckyOrnament/moneyback.png'),
					imageUrl: require('../../../assets/images/LuckyOrnament/moneyback.png'),
					class: 'img-class',
					back: require('../../../assets/images/LuckyOrnament/box-skins-white.png')
				},
				newList: [],
				skinList: {}, // 幸运饰品
				process_audio: new Audio('https://oss.n7buff.com/mobile/audio/roll9.mp3'), //过程声音
				show_audio: new Audio('https://oss.n7buff.com/mobile/audio/Shoot.mp3'), //出枪
			}
		},
		components: {
			BoxShowBodyOpenTake
		},
		watch: {
			openAnim(val) {
				if (val) {
					this.$nextTick(()=>{
						this.Width = this.$refs.animation_X.getBoundingClientRect().width
						console.log(this.Width )
						console.log(this.newList.length)
					})


					console.log(1)
				}
			},
			value3() {
				this.imputedPrice()
			}
		},
		created() {
							if (sessionStorage.getItem('n7_token')) {
								this.$store.dispatch('getPlayerApi')
							}
			this.getSkinDetailApi()
		},
		mounted() {},
		methods: {
			changes(e) {
				this.$router.push({
					path: '/OrnamentOpen',
					query: {
						id: e
					}
				})
				this.getSkinDetailApi()
			},
			// 获取饰品详情
			getSkinDetailApi() {
				get_skin_detail({
					all_skins_id: this.$route.query.id
				}).then((res) => {
					this.skinList = res.data.skin
					this.$forceUpdate()
					this.imputedPrice()
				})
			},
			// 动画开关
			showAnimation() {
				if (this.showAnimations) {
					this.showAnimations = false
				} else {
					this.showAnimations = true
				}
			},
			// 开启
			open() {
				opne_luncky_skin({
					skin_id: this.skinList.id,
					probability: this.value3
				}).then((res) => {
					if (res.status === 1) {
						if (this.showAnimations) {
							this.process_audio.play()
						}
						console.log('openIf')

						this.status = res.data.status
						this.$store.dispatch('getPlayerApi')
						this.$refs.BoxShowBodyOpenTake.getList()
						this.disruptArr()
						this.translateX = -(
							this.Width * (this.newList.length - 3)
						)
						this.touchmove()

						if (this.showAnimations) {
							// this.disruptArr()

							this.translateX = -(
							this.Width * (this.newList.length - 3)
						)
							console.log(this.translateX)
							this.touchmove()
							setTimeout(() => {
								this.winningResults()
							}, 6600)

						} else {
							setTimeout(() => {
								this.winningResults()
							}, 600)
						}
					} else {
						this.openAnim = false
						this.translateX = 0
						this.disabled = false
						this.loading = false
						this.results = false
						this.newList = []
						var mo = function(e) {
							e.preventDefault()
						}
						document.body.style.overflow = '' //出现滚动条
						document.removeEventListener('touchmove', mo, false)
						return this.$message.error(res.msg)
					}
				})
			},
			upgrade() {
				// 判断用户是否登录
				if (!sessionStorage.getItem('n7_token')) {
					 this.$store.dispatch('setLogSignRetrieve', true)
					 return
				}
				if (this.showAnimations) {
					//动画开启
					this.open()
					this.loading = true
					this.disabled = true
					this.openAnim = true
				} else {
					this.open()
				}
			},
			//禁止页面滑动
			touchmove() {
				var mo = function(e) {
					e.preventDefault()
				}
				document.body.style.overflow = 'hidden'
				document.addEventListener('touchmove', mo, false) //禁止页面滑动
			},
			//拼接并打乱数组
			disruptArr() {
				for (let i = 0; i < (100 - this.value3) / 2; i++) {
					this.newList.push(this.noWin)
				}
				for (let j = 0; j < this.value3 / 2; j++) {
					this.newList.push(this.skinList)
				}
				// console.log(this.newList.length)
				// eslint-disable-next-line no-inner-declarations
				let n = this.newList.length
				while (n--) {
					var index = Math.floor(Math.random() * n)
					var temp = this.newList[index]
					this.newList[index] = this.newList[n]
					this.newList[n] = temp
				}
				console.log(this.newList)
				//插入
				if (this.status) {
					this.newList.push(this.skinList)
				} else {
					this.newList.push(this.noWin)
				}
			},
			// 中奖结果
			winningResults() {
				this.process_audio.pause()
				if( this.process_audio!= null ) {
					this.process_audio.pause();
					this.process_audio = null;
				}
				// //然后再new Audio创建新的音频
				this.process_audio= new Audio('https://oss.n7buff.com/mobile/audio/roll9.mp3');
				// this.audio.play();
				this.show_audio.play()
				setTimeout(()=>{
					this.openAnim = false
					this.translateX = 0
					this.disabled = false
					this.loading = false
					this.results = true
				},800)
			},
			//拖动滑动条 鼠标松开
			changeNum() {
				this.disruptArr()
			},
			// 计算价格
			imputedPrice() {
				let pri = this.skinList.price * (this.value3 / 100)
				let impPri = pri.toFixed(2)
				this.impPri = impPri
			},

			winX() {
				//改变动画
				this.winState = false
			},
			// 关闭按钮
			on() {
				this.results = false
				this.newList = []
				var mo = function(e) {
					e.preventDefault()
				}
				document.body.style.overflow = '' //出现滚动条
				document.removeEventListener('touchmove', mo, false)
			}
		}
	}
</script>

<style lang="less" scoped>
	::v-deep .el-slider__button-wrapper {
		z-index: 80;
	}

	.dd {
		margin-top: -1.2rem;
	}

	.showBack {
		width: 1600px;
		height: 100%;
		position: fixed;
		top: 0;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 90;
		transition: all 0.15s linear;
	}

	.ornaOpen {
		overflow: hidden;

		//动画
		.bg {
			width: 1500px;
			height: 480px;
			background: url('@/assets/images/showImages/bak.png') no-repeat center;
			background-size: contain;
			position: fixed;
			top: 40%;
			z-index: 90;
		}

		.open-animBox {
			// width: 1490px;
			width: 90%;
			margin: .6rem 5%;
			height: 1.8rem;
			//border: 10px #383856 solid;
			border-radius: 10px;

			.open-anim {
				// padding: 10px;
				// margin-top: 30px;
				background-color: #383856;
				position: relative;
				overflow: hidden;

				.open-line {
					position: absolute;
					height: 100%;
					width: 3px;
					background-color: #ffbb00;
					top: 0;
					left: 50%; //950px 50%
					z-index: 66;
				}

				.open-line::before {
					content: '';
					background-image: url('@/assets/images/LuckyOrnament/pointer.png');
					width: 38px;
					height: 19px;
					position: absolute;
					top: -1px;
					left: 2px;
					transform: translateX(-50%) rotate(180deg);
					background-size: 100% 100%;
				}

				.open-line::after {
					content: '';
					background-image: url('@/assets/images/LuckyOrnament/pointer.png');
					width: 38px;
					height: 19px;
					position: absolute;
					bottom: -1px;
					left: -18px;
					// transform: translateX(-50%) rotate(180deg);
					background-size: 100% 100%;
				}

				.open-anim-warp {
					transition: 6s cubic-bezier(0.2, 0.29, 0.15, 0.91);
					// overflow: hidden;
					width: 8610px; // ~"calc(-77vh * 10)" 8610px
					// height: 155px;
					display: flex;
					position: relative;

					.z {
						position: absolute;
						// width: 1.8rem;
						width: 1.75rem;
						// height: 150px;
					}
				}

				ul {
					// width: 100%;
					position: relative;
					left: 0;
					display: flex;

					li {
						background: url('https://oss.n7buff.com/mobile/assets/images/box-skins-blue.jpg') no-repeat;
						background-size: cover;
						// width: 1.8rem;
						width: 1.75rem;
						// height: 150px;
						height: 1.8rem;

						// margin-right: 5px;
						border: 2px solid #92a9b6;
						border-radius: 5px;

						img {
							width: 1.8rem;
							height: 1.8rem;
						}
					}

					.li1 {
						border: 2px solid #c9a167;
					}
				}

				.ul1 {
					animation: run11 6s;
					animation-timing-function: ease; //动画慢 快 慢
					animation-iteration-count: 1; //播放几次动画
					animation-delay: 0s; //动画运行前等待时间
					animation-fill-mode: forwards; //动画结束 是否保持

					@keyframes run11 {
						0% {
							left: 0;
						}

						100% {
							left: -100%; //~"calc(-77vh * 10)"
						}
					}
				}
			}
		}

		.oran-warp {
			padding: 0.2rem 0;
			margin-top: 1rem;
			padding-bottom: 4rem;
		}

		.oran-top {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 20px 0;

			.orantop-left {
				color: #c3c3e2;
				font-size: 20px;
			}

			.orantop-right {
				padding: 12px 22px;
				border: 2px #1ef0ed solid;
				border-radius: 5px;
				color: #fff;
				font-size: 15px;
				font-weight: 600;
			}

			.orantop-right:hover {
				cursor: pointer;
				color: #212126;
				background-color: #1ef0ed;
			}
		}

		.oran-sel {
			z-index: -80;

			.sel-top {
				ul {
					margin: 0 -1px;

					li {
						float: left;
						width: 11.11%;

						.seltop-warp {
							background-color: #24252f;
							margin: 0 1px;
							display: flex;
							flex-direction: column;
							align-items: center;
							border-top-left-radius: 5px;
							border-top-right-radius: 5px;

							img {
								width: 60%;
								height: auto;
							}

							span {
								padding-bottom: 10px;
								font-size: 14px;
								color: #848492;
							}
						}

						.seltop-warp1 {
							background-color: #2b2c37;

							span {
								color: #e9b10e;
							}
						}
					}

					li:hover {
						cursor: pointer;
					}
				}
			}

			.sel-bot {
				background-color: #2b2c37;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 26px 16px;

				.selbot-left {
					font-size: 14px;
					color: #e9b10e;
					font-weight: 600;
				}

				.selbot-left:hover {
					cursor: pointer;
					text-decoration: underline;
				}

				.selbot-right {
					display: flex;
					align-items: center;

					.span {
						margin: 0 8px;
						color: #848492;
					}

					.pirec-btn {
						margin: 0 10px;
						background-color: #333542;
						border-radius: 5px;
						color: #848492;
						font-size: 15px;
						font-weight: 600;
						padding: 10px 26px;
					}

					.pirec-btn:hover {
						cursor: pointer;
						background-color: #3a3f50;
					}

					.input {
						width: 120px;

						img {
							width: auto;
							height: 18px;
						}
					}

					.input /deep/ .el-input__prefix,
					.input /deep/ .el-input__suffix {
						top: 11px;
					}

					.input1 {
						width: 200px;
					}

					.input1-i:hover {
						cursor: pointer;
					}
				}

				.selbot-right /deep/ .el-input__inner {
					background-color: #2b2c37;
					border: 1px solid #848492;
					color: #c3c3e2;
				}
			}
		}

		.open {
			// margin-bottom: 100px; //后修改
			margin-top: 16px;
			// background-color: #2b2c3775;
			border-radius: 5px;
			padding: 16px;
			position: relative;

			.open-hint {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.open-hint1 {
					font-size: 20px;
					color: #c3c3e2;
				}

				.open-hint2 {
					display: flex;
					align-items: center;

					span {
						margin-right: 5px;
						font-size: 14px;
						color: #848492;
					}

					i {
						font-size: 14px;
						color: #848492;
					}

					i:hover {
						cursor: pointer;
					}
				}

				.open-hint3 {
					img {
						width: 30px;
						height: 30px;
					}

					img:hover {
						cursor: pointer;
					}
				}
			}

			.open-box {
				// margin-top: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				height: 3rem;
				margin: 0 auto;
				width: 90%;

				.box-one {
					position: absolute;
					left: 0;
					top: 0.5rem;
					height: 3rem;
					display: flex;
					justify-content: center;

					img {
						margin-left: .2rem;
						height: 80%;
						width: 100%;
					}
				}

				.box-two {
					height: 100%;
					position: relative;

					img {
						height: 100%;
					}

					span {
						position: absolute;
						top: 0;
						// left: 50%;
						left: 0;
						right: 0;
						margin: auto;
						color: #c3c3e2;
						font-size: 0.3rem;
					}
				}

				.box-three {
					width: 1.5rem;
					height: 100%;
					color: #c3c3e2;
					line-height: 200px;
					font-weight: 600;
					font-size: 0.5rem;
					display: flex;
					align-items: center;
				}

				.box-four {
					height: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;

					.four-top {
						color: #e9b10e;
						font-size: 0.3rem;
					}

					.four-bot {
						position: relative;
						width: 2.5rem;
						padding: 0 20px;
						margin-top: 25px;

						.img {
							background-image: url('https://oss.n7buff.com/mobile/assets/images/box-skins-blue.jpg');
							background-size: 100% 100%;
							border-bottom: 2px solid #e9b10e;

							img {

								width: 80%;
								margin-left: 10%;
							}
						}

						.name {
							padding: 0.1rem;
							font-size: 0.3rem;
							color: #c3c3e2;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
							background-color: #24252f;
						}

						.por {
							position: absolute;
							left: 0;
							top: -25%;
							width: 100%;
						}
					}
				}

				.box-five {
					position: absolute;
					right: 0;
					height: 3rem;
					top: 0.5rem;
					display: flex;
					justify-content: center;

					img {
						height: 80%;
						margin-right: .2rem;
					}
				}
			}

			.open-text {
				// margin-top: 30px;
				text-align: center;
				color: #c3c3e2;
				font-size: 16px;
			}

			.open-pro {
				// margin-top: 50px;
				width: 80%;
				// margin-left: 25%;
				margin: auto;
				margin-top: 0.5rem;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.pro-img {
					position: relative;

					img {
						width: 2.4rem;
						height: 2.4rem;
					}

					.peo-num {
						width: 1rem;
						height: 1rem;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						right: 0;
						margin: auto;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
					}

					.peo-num1 {
						div:first-child {
							color: #50e3c2;
							font-size: 0.5rem;
							font-weight: 400;
						}

						div:last-child {
							color: #50e3c2;
							font-size: 0.3rem;
							font-weight: 400;
						}
					}

					.peo-num2 {
						div:first-child {
							color: #fd492c;
							font-size: 0.5rem;
							font-weight: 400;
						}

						div:last-child {
							color: #fd492c;
							font-size: 0.3rem;
							font-weight: 400;
						}
					}
				}

				.pro-con {
					width: 60%;
				}
			}

			.open-btn {
				display: flex;
				margin-top: .6rem;
				justify-content: center;

				.open-btnwarp {
					// background-color: #e9b20e00;
					// border: 2px #1ef0ed solid;
					background: #1ef0ed;
					display: flex;
					align-items: center;
					justify-content: center;
					padding: 10px 80px;
					border-radius: 5px;
					width: 5rem;
					height: 1rem;

					span {
						font-size: 0.36rem;
						font-weight: 600;
						height: 0.4rem;
						// line-height: 27px;
						color: #606266;
					}

					.img {
						background: url('@/assets/images/n7_money.png') no-repeat 0px 2px;
						background-size: 100%;
						width: 0.4rem;
						height: 0.4rem;
						margin: 0 0.1rem;
						position: relative;
						display: inline-block;
					}
				}

				// .open-btnwarp:hover {
				//   cursor: pointer;
				//   background-color: #1ef0ed;
				// }
				// .open-btnwarp:hover span {
				//   cursor: pointer;
				//   color: #fff;
				// }
				// .open-btnwarp:hover .img {
				//   cursor: pointer;
				//   background: url('@/assets/images/K5币反.png') no-repeat 0px 2px;
				//   background-size: 25px;
				// }
				// .open-btnwarp:active {
				//   cursor: pointer;
				//   background-color: #949493;
				// }
			}
		}
	}

	/deep/ .el-button {
		border: none;
	}

	/deep/ .el-slider__button {
		margin-top: -0.15rem;
		border: 0.15rem solid #a0eccc;
	}

	/deep/.el-slider__bar {
		background-color: #a0eccc;
	}

	/deep/ .el-slider__runway {
		background-color: #e4b3a5;
	}

	.el-button {
		color: #1ef0ed;
		font-size: 0.4rem;
	}
</style>
<style lang="scss" scoped>
	@import '@/styles/variables.scss';

	// 中奖结果
	.introducedno {
		// filter: brightness(40%);
	}

	.winningResults-backImg {
		width: 7rem;
		height: 7rem;
		position: absolute;
		//top: 50%;
		left: 0%;
		transform: translate(-50%, -50%);
		z-index: -2;
		-webkit-transition-property: -webkit-transform;
		-webkit-transition-duration: 1s;
		-moz-transition-property: -moz-transform;
		-moz-transition-duration: 1s;
		-webkit-animation: rotate 3s linear infinite;
		-moz-animation: rotate 3s linear infinite;
		-o-animation: rotate 3s linear infinite;
		animation: rotate 3s linear infinite;

	}

	@-webkit-keyframes rotate {
		from {
			-webkit-transform: rotate(0deg)
		}

		to {
			-webkit-transform: rotate(360deg)
		}
	}

	@-moz-keyframes rotate {
		from {
			-moz-transform: rotate(0deg)
		}

		to {
			-moz-transform: rotate(359deg)
		}
	}

	@-o-keyframes rotate {
		from {
			-o-transform: rotate(0deg)
		}

		to {
			-o-transform: rotate(359deg)
		}
	}

	@keyframes rotate {
		from {
			transform: rotate(0deg)
		}

		to {
			transform: rotate(359deg)
		}
	}

	// 动画开关
	.OrnamentOpen-showAnimation {
	  width: 0.8rem;
	  height: 0.8rem;
	  font-size: 0.3rem;
	  font-weight: 700;
	  line-height: 0.6rem;
	  color: #fff;
	  border-radius: 0.4rem;
	  border: 3px #fff solid;
	  position: absolute;
	  top: 6rem;
	  right: 0.5rem;
	  display: flex;
	  align-self: center;
	  justify-content: center;
	}

	.OrnamentOpen-showAnimationTrue {
	  background-color: #76e58c;
	}

	.OrnamentOpen-showAnimationFalse {
	  background-color: rgba($color: #000000, $alpha: 0);
	}

	.OrnamentOpen-showAnimationTrueIcon {
	  width: 0.6rem;
	  height: 0.6rem;
	  border-radius: 50%;
	  border: 1px #fff solid;
	  margin: auto;

	  & svg {
	    width: 0.4rem;
	    height: 0.4rem;
	    // margin-top: 0.05rem;
	  }
	}
	.winningResults {
		z-index: 90;
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 7rem;
		height: 7rem;

		// position: relative;
		.goods {
			display: flex;
			justify-content: space-evenly;
			width: 7rem;
			height: 7rem;

			.item {
				width: 6rem;
				height: 6rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				position: relative;

				.close {
					position: absolute;
					top: .5rem;
					right: 0rem;

					img {
						width: 1rem;
					}
				}

				.subject {
					img {
						margin-top: 2rem;
						width: 4rem;
					}
				}

				.name {
					// width: 300px;
					// height: 150px;
					padding: 2px 2px 7px 2px;
					font-size: 0.4rem;
					font-weight: 700;
					color: #1ef0ed;
					// border: 2px $theme solid;
					// border-radius: 0 0 50% 50%;
					// width: 150px;
					// white-space: nowrap;
					// overflow: hidden;
					// text-overflow: ellipsis;
				}

				.KO {
					margin-top: .2rem;
					display: flex;
					align-items: center;

					&-name {
						margin-left: 10px;
						font-size: .5rem;
						font-weight: 700;
						color: #1ef0ed;
					}

					img {
						width: .6rem;
						margin-right: 10px;
					}
				}

				.price {
					margin-top: .2rem;
					font-size: 0.5rem;
					font-weight: 700;
					display: flex;
					align-items: center;
					color: #1ef0ed;

					img {
						width: .6rem;
						margin-right: 10px;
					}
				}
			}
		}
	}
</style>
