<template>
	<div class="header">
		<div class="imgbox" @click="click_left"><img src="../assets/imgs/收起菜单.png" alt="" /></div>
		<div class="logo">
			<!-- 动态切换 12.17 -->
			<img src="../assets/images/logo.png" alt="" />
		</div>
		<!-- 登陆前 -->
		<div class="log-methods">
			<div class="btn" v-if="!this.$store.state.token">
				<button class="l" @click="logIn">登录</button>
				<button class="r" @click="sigIn">注册</button>
			</div>
		</div>

		<!-- 登陆后 -->
		<div v-if="this.$store.state.token" class="head-user" @click="ce">
			<div class="name_pice hnk">
				<div class="name">{{ $store.state.userInfo.nike_name }}</div>
				<div class="many">
					<img src="@/assets/images/n7_money.png" alt="" />
					{{ $store.state.userInfo.account_balance ? $store.state.userInfo.account_balance : 0.0 }}
				</div>
			</div>
			<div class="imgbox hnk ">
				<img v-if="!hed_imgurl" class="head-img" :src="$store.state.userInfo.head_img" alt="" />
				<img v-else class="head-img" :src="hed_imgurl" alt="" />
        <img class="headPortraitAround" :src="$store.state.userInfo.grade_img" alt="">
				<div class="shows" v-if="$store.state.userInfo.no_read_num"></div>
			</div>
		</div>

		<div class="userspace" @click="ce2">
			<ul>
				<li class="user-topUp"><img @click="topUp" src="@/assets/images/fightImages/Recharge.png" alt="" /></li>
				<li :class="checked == 6 ? 'checkeds' : ''" @click="$router.push('/personalUser')">
					<img src="../assets/icons/Roll.png" alt="" />
					个人中心
				</li>
				<li :class="checked == 7 ? 'checkeds' : ''" @click="$router.push('/myBack')">
					<img src="../assets/icons/Roll.png" alt="" />
					我的背包
				</li>
				<li :class="checked == 8 ? 'checkeds' : ''" @click="$router.push('/MyLog')">
					<img src="../assets/icons/Roll.png" alt="" />
					我的日志
				</li>
				<li :class="checked == 9 ? 'checkeds' : ''" @click="$router.push('/MyPromotion')">
					<img src="../assets/icons/Roll.png" alt="" />
					我的推广
				</li>
				<li class="dis" :class="checked == 10 ? 'checkeds' : ''" @click="$router.push('/stationInformation')">
					<img src="../assets/icons/Roll.png" alt="" />
					站内信息
					<span class="wd" v-if="$store.state.userInfo.no_read_num">{{ $store.state.userInfo.no_read_num }}</span>
				</li>
				<!-- <li @click="logOut()">退出登录</li> -->
				<!-- <div class="logBox">
        <el-button class="logOut" type="warning" @click="logOut()">退出登录</el-button>
        </div> -->
			</ul>
		</div>

		<div class="list" :style="[{ left: flag ? 0 + 'px' : -10 + 'rem' }]" @click="hiden">
			<ul>
				<li :class="checked == 1 ? 'checkeds' : ''" @click="tiao('/')">
					<img src="../assets/icons/box.png" alt="" />
					CS:GO盲盒
				</li>
				<li :class="checked == 2 ? 'checkeds' : ''" @click="tiao('/FreeSkin')">
					<img src="../assets/icons/Roll.png" alt="" />
					ROLL房
				</li>
				<li :class="checked == 3 ? 'checkeds' : ''" @click="tiao('/BlindBoxFight')">
					<img src="../assets/icons/box_battle.png" alt="" />
					盲盒对战
				</li>
				<li v-if="false" :class="checked == 4 ? 'checkeds' : ''" @click="tiao('/LuckyOrnament')">
					<img src="../assets/icons/luncky.png" alt="" />
					幸运饰品
				</li>
        <li :class="checked == 4 ? 'checkeds' : ''" @click="tiao('/GradeBox')">
          <img src="../assets/icons/lv.png" alt="" />
          免费箱子
        </li>
				<li :class="checked == 5 ? 'checkeds' : ''" @click="tiao('/OrnamentStore')">
					<img src="../assets/icons/shop.png" alt="" />
					饰品商城
				</li>
				<li :class="checked == 16 ? 'checkeds' : 'star-color'"  @click="tiao('/StarStore')">
					<img src="https://oss.N7buff.com/star_store.gif" alt="" />
					星空商城
				</li>
<!--        <li  @click="tiao('/cardList')">-->
<!--          <img src="@/assets/icons/dzxy.png" alt="" />-->
<!--          斗转星移-->
<!--        </li>-->
			</ul>
		</div>
		<!-- 弹框 -->
		<LogIn class="logIn" v-show="this.$store.state.logSignRetrieve" :homeLogin="LogIn" />
		<!-- v-show="topUpRetrieve"  -->
		<TopUp class="logIn" v-show="topUpRetrieve" :visible.sync="topUpRetrieve" />
	</div>
</template>

<script>
import LogIn from '@/views/User/logIn';
import TopUp from '@/views/User/topUp.vue';
import { get_player } from '@/api/index/personalCenter';
import { set } from 'vue';
export default {
	name: 'HomeHead',
	components: {
		LogIn,
		TopUp
	},
	props: {
		checked: {
			default: null
		}
	},
	data() {
		return {
			flag: false,
			homeHead: this.$route.name,
			audio: new Audio('https://oss.n7buff.com/mobile/audio/mens.mp3'), //过渡声音
			logSignRetrieve: false, // 登录注册弹出层
			topUpRetrieve: false, // 充值弹出层
			LogIn: 'logIn',
			RetrievePassword: false,
			hed_imgurl: '',
			timer: null,
			num: 0
		};
	},
	watch: {
		$route(to, from) {
			// this.getPlayer()
		}
	},
	created() {
		/* 	if (sessionStorage.getItem('n7_token')) {
					this.$store.dispatch('getPlayerApi')
				} */
		// setInterval(()=>{
		// this.getPlayer()
		// },10000)
	},
	methods: {
		ce() {
			document.querySelector('.userspace').style.right = 0 + 'rem';
			this.hiden();
		},
		ce2() {
			document.querySelector('.userspace').style.right = -10 + 'rem';
		},
		click_left() {
			document.querySelector('.userspace').style.right = -10 + 'rem';
			this.flag = !this.flag;
		},
		hiden() {
			this.flag = false;
		},
		tiao(e) {
			if (this.$store.state.Audio) {
				this.audio.play();
			}
			// console.log(123)
			this.$router.push(e);
			this.homeHead = this.$route.name;
		},
		closes() {
			this.$emit('closes', true);
		},
		getPlayer() {
			get_player({}).then(res => {
				//console.log(res)
				this.num = res.data.no_read_num;
			});
		},
		// 登录
		logIn() {
			this.$store.dispatch('setLogSignRetrieve', true);
			this.LogIn = 'logIn';
			var mo = function(e) {
				e.preventDefault();
			};
			document.body.style.overflow = 'hidden';
			document.addEventListener('touchmove', mo, false); //禁止页面滑动
		},
		// 注册
		sigIn() {
			this.$store.dispatch('setLogSignRetrieve', true);
			this.LogIn = 'sigIn';
			var mo = function(e) {
				e.preventDefault();
			};
			document.body.style.overflow = 'hidden';
			document.addEventListener('touchmove', mo, false); //禁止页面滑动
		},
		// 充值
		topUp() {
			this.topUpRetrieve = true;
			var mo = function(e) {
				e.preventDefault();
			};
			document.body.style.overflow = 'hidden';
			document.addEventListener('touchmove', mo, false); //禁止页面滑动
		},
		// 退出登录
		logOut() {
			this.$confirm('是否退出登录?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
				//type: 'warning'
			})
				.then(() => {
					this.$store.dispatch('logout');
					// localStorage.clear()
					this.$message({
						type: 'success',
						message: '退出成功!'
					});
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消退出'
					});
				});
		}
		// down() {
		// 	console.log(1);
		// 	return;
		// }
	}
};
</script>

<style lang="scss" scoped>
@import '../styles/HomeHead.scss';
.wd {
	height: 0.4rem;
	width: 0.8rem !important;
	font-size: 0.3rem;
	color: #fff;
	background: #ff0000;
	border-radius: 0.3rem;
	margin-left: 0.3rem;
	display: flex;
	justify-content: center;
	align-items: center;
}
.dis {
	display: flex;
	align-items: center;
	// position: relative;
}
.po {
	position: relative;
}
.star-color{
	color:#FFA500 !important;
	font-size: 16px;
	font-weight: 700;
}
</style>
