<!-- 开箱 箱内物品 -->
<template>
  <div class="InBox free">
    <div class="InBox-title">
      <span>箱子包含</span>
    </div>
    <!-- 箱内物品 -->
    <div class="InBox-res" v-show="tab_id == 1">
      <ul class="InBox-res-ul">
        <li class="InBox-res-ul-li" v-for="item in skinsList" :key="item.id">
          <img class="InBox-res-ul-li-back" :src="item.rarity_thumb" alt="" />
          <img class="InBox-res-ul-li-main" :src="item.image_url" alt="" />
          <img
            class="InBox-res-ul-li-shadow"
            src="@/assets/images/showImages/shadow.png"
            alt=""
          />

          <el-tooltip placement="bottom">
            <div slot="content">{{ item.item_name }}</div>
            <div class="InBox-res-ul-li-name InBox-res-ul-li-li">
              {{ item.item_name }}
            </div>
          </el-tooltip>
          <div class="InBox-res-ul-li-currency InBox-res-ul-li-li">
            <div class="InBox-res-ul-li-currency-yuan">
              <img src="@/assets/images/n7_money.png" alt="" />
              <div>{{ item.price }}</div>
            </div>
            <div class="bai">{{ item.probability }}%</div>
          </div>
        </li>
      </ul>
    </div>
    <!-- 最近掉落 -->
    <div class="user_get" v-show="tab_id == 2">
      <ul>
        <li v-for="(item, index) in recordList" :key="index">
          <div class="img_box">
            <img :src="item.player_head_img" alt="" />
          </div>
          <div class="name">{{ item.price }}</div>
          <div class="qiang_name">{{ item.item_name }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
	get_open_box_record,
 get_drop_record
 } from '@/api/index/boxCorrelation'
import bus from '@/utils/bus.js'
export default {
  name: 'BoxShowBodyIn',
  data() {
    return {
      tab_id: 1,
	    show_audio: new Audio('https://oss.n7buff.com/mobile/audio/Shoot.mp3'), //过程声音
      recordList: [],
      timer: null
    }
  },
  props: { skinsList: Array },
  created() {
    if (this.$route.name == 'BoxShow') {
      this.timerr()
    } else {
      this.timer = null
    }
    bus.$on('colseInterval',e=>{
      clearInterval(this.timer)
    })
    bus.$on('openInterval',e=>{
       this.timer = setInterval(() => {
          this.timerr()
        }, 3000)
      })

  },
  beforeDestroy(){
    bus.$off('colseInterval')
    bus.$off('openInterval')
    clearInterval(this.timer)
  },
  methods: {
    tab(e) {
      this.tab_id = e
      if (this.tab_id == 2) {
        this.timer = setInterval(() => {
          this.timerr()
        }, 3000)
      }else{
        clearInterval(this.timer)
      }
      // console.log(e);
    },
    timerr() {


      get_drop_record({
					box_id: this.$route.query.id,
					size: 20
				}).then((res) => {
        // console.log(res.data)
        this.recordList = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
@import '@/styles/BoxShowBodyIn.scss';
.InBox{
  margin-top: 200px;
}
.InBox-title{
  width: 771px;
  height: 97px;
  background: url("@/assets/images/GradeBox/box-title.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  text-align: left;
  align-items: center;
  font-size: 25px;
  padding-left: 98px;
  border-bottom: 0;
}
</style>
