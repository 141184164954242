<template>
  <div id="room">
    <div class="room_container">
      <div
        class="room_data"
        :class="[
          { conduct: room_Status == 2 },
          { win: room_Status == 3 && bill_data.win_status != 2 },
          { fail: room_Status == 3 && bill_data.win_status == 2 }
        ]"
      >
        <img src="@/assets/images/fightImages/惜败.png" v-if=" room_Status == 3 && bill_data.win_status == 2" class="failIcon" >

        <!-- 胜负背景class win/fail 进行中class conduct -->
        <!-- 用户信息区域 -->
        <div class="user_data"  :style="[{width:wid==3?(5.8-wid+'rem'):(6.2-wid+'rem'),'border-bottom-color':(room_Status == 3?'transparent':'#555559')},{'background-image':backgroundImage}]" >
          <div class="user_portrait">
            <!-- <div
              class="crown"
              v-show="room_Status == 3 && bill_data.win_status != 2"
            >
              <img src="@/assets/images/Battle/battleDetail/crown.png" alt="" />
            </div> -->
            <!-- 胜利icon -->
            <!-- <img
              v-show="bill_data.player_head_img"
              :class="{ win: room_Status == 3 && bill_data.win_status != 2 }"
              :src="bill_data.player_head_img"
              alt=""
            /> -->
            <CommenHeadPortrait
              v-show="bill_data.player_head_img"
              classNumber="normal"
              :userModel="bill_data"
              :class="[
                { win: room_Status == 3 && bill_data.win_status != 2 },
                { iconUrl: bill_data.player_head_img == null }
              ]"
            />
            <!-- v-show="room_Status == 3 && bill_data.win_status != 2" -->
            <div
              class="winnerBg"
              v-show="room_Status == 3 && bill_data.win_status != 2"
            ></div>
            <img
              v-show="!bill_data.player_head_img && ranks == 'buld'"
              class="add"
              src="@/assets/images/fightImages/Inviter.png"
              alt=""
              @click="join_Room(bill_data.index)"
            />
            <img
              v-show="!bill_data.player_head_img && ranks == 'red'"
              class="add"
              src="@/assets/images/fightImages/Inviter-red.png"
              alt=""
              @click="join_Room(bill_data.index)"
            />
          </div>

          <div class="user_name">
            <p v-if="bill_data.player_nike_name">
              <!-- {{ bill_data.player_nike_name | Prohibited_words }} -->
              {{ bill_data.player_nike_name }}
            </p>
            <p v-if="!bill_data.player_nike_name">等待玩家进入</p>
          </div>

          <div class="user_info">
            <div class="user_price" style="margin-right: 10px">
              <img
                src="@/assets/images/n7_money.png"
                alt=""
              />
              <countTo
                :startVal="used_price"
                :endVal="user_price"
                :duration="800"
                :decimals="2"
              >
              </countTo>
            </div>
            <!-- <div class="user_price" style="margin-left: 10px; color: #03d6ff">
              <img
                src="https://game-aesy.c5game.com/steam/730/674e6509767fc9c6e27b74bc339bc5e5.png?x-oss-process=image/resize,w_480,h_360"
                alt=""
              />
              <countTo
                :startVal="used_Ammunition"
                :endVal="user_Ammunition"
                :duration="800"
                :decimals="2"
              ></countTo>
            </div> -->
          </div>
        </div>

        <!-- 房间核心 -->
        <div class="room_core">
          <!-- 房间状态区域 -->
          <div class="room_state" v-show="Apparent && room_Status == 1">
            <div class="wait_state">
              <p v-show="bill_data.player_head_img" style="color: #ffc400">
                已准备
              </p>
              <p v-show="!bill_data.player_head_img">等待中</p>
            </div>
          </div>

          <div class="room_state" v-show="Apparent && room_Status != 1">
            <div class="wait_state">
              <p style="color: #ffc400">
                准备中
                <!-- <img
                  style="margin-left: 4px"
                  src="https://game-aesy.c5game.com/steam/730/674e6509767fc9c6e27b74bc339bc5e5.png?x-oss-process=image/resize,w_480,h_360"
                  alt=""
                /> -->
              </p>
            </div>
          </div>

          <div class="room_state" v-show="!Apparent && room_Status == 3">
            <div class="conduct">
              <img
                class="fail"
                v-show="bill_data.win_status == 2"
                src="@/assets/images/Battle/battleDetail/fail.png"
                alt=""
              />
              <img
                class="win"
                v-show="bill_data.win_status == 1 || bill_data.win_status == 3"
                src="@/assets/images/Battle/battleDetail/win.png"
                alt=""
              />
            </div>
          </div>

          <!-- 滚动获得区域 -->
          <action
            :wid="wid"
            v-show="!Apparent && room_Status == 2"
            :battleBoxList="battleBoxList"
            :GoodsList="bill_data.skin_info"
            :roomCount="roomCount"
            :start="start"
            @endData="endData"
            :check_data="bill_data.check_data"
            @slideEnd="slideEnd"
            @complete="complete"
            @toApparent="toApparent"
          ></action>
        </div>
      </div>

      <!-- 用户奖品区域 -->
      <div
        class="room_prize_box"
        :class="{ win: room_Status == 3 && bill_data.win_status != 2 }"
      >
        <div class="room_prize" v-show="room_Status != 1">
          <transition-group name="animation">
            <div
              class="room_prize_item"
              v-for="(item, index) in room_list"
              :key="index"
              :style="item.rarity_thumb | innerBack"
            >
              <!-- :style="item.imageData | innerBack" -->
              <div class="pic"><img :src="item.image_url" alt="" /></div>
              <el-tooltip
                class="item"
                effect="dark"
                :disabled="false"
                :content="item.item_name"
                placement="bottom"
              >
                <!-- <p class="hide" :style="item.imageData | colorCode"> -->
                <p class="hide">
                  {{ item.item_name }}
                </p>
              </el-tooltip>
              <div class="price">
                <img src="@/assets/images/n7_money.png" alt="" />{{ item.price }}
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import { joinRoom } from "@/network/api.js";
import {
  join_battle_room,
} from '@/api/index/BlindBattle'
import action from './action.vue'
import countTo from 'vue-count-to'
import CommenHeadPortrait from './CommenHeadPortrait.vue'
export default {
  name: 'ROoom',
  props: [
    'start',
    'bill_data',
    'roomStatus',
    'battleBoxSumPrice',
    'battleBoxList',
    'roomCount',
    'Draw_prize',
    'roomPrice',
    'wid',
    'ranks',
    'battle_station'
  ],
  data() {
    return {
      imgurl:require('@/assets/images/n7_money.png'),
      Apparent: true, //等待||结束/进行中
      used_price: 0, //初始价格
      user_price: 0, //开箱获得价格

      used_Ammunition: 0, //初始弹药
      user_Ammunition: 0, //开箱获得弹药

      room_list: [], //开箱获得
      room_Status: 1 //房间状态(单独控制) 1等待 2进行 3结束
    }
  },
  components: {
    action,
    countTo,
    CommenHeadPortrait
  },
  computed:{
    backgroundImage() {
      if (this.room_Status ==1){
        if (this.ranks == 'red'){
          return 'linear-gradient(0deg,red,transparent)'
        }else {
          return 'linear-gradient(0deg,#1ef0ed,transparent)'
        }
      }

    }
  },
  watch: {
    roomStatus(v) {
      this.room_Status = v
      if (v == 3) {
        this.open_prize()
      } else if (
        this.room_Status == 2 &&
        this.roomCount != 0 &&
        this.roomCount != -1
      ) {
        this.toApparent()
      }
    },

    Draw_prize(v) {
      if (v) {
        this.open_prize()
      }
    }
  },
  created() {
    this.room_Status = this.roomStatus
    if (this.room_Status == 3) {
      this.open_prize()
    } else if (
      this.room_Status == 2 &&
      this.roomCount != 0 &&
      this.roomCount != -1
    ) {
      this.toApparent()
      this.handle_prize()
    }
  },

  methods: {
    //等待||结束/进行
    toApparent() {
      this.Apparent = false
    },

    //加入房间
    join_Room(battle_station) {
      console.log(battle_station,this.bill_data ,'{{ battle_station }}')
      this.$confirm(
        `消耗<img style="width:0.4rem" src="${this.imgurl}">${this.battleBoxSumPrice}加入房间<br/>确定加入房间吗?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          dangerouslyUseHTMLString: true
        }
      )
        .then(() => {
          join_battle_room({ battle_room_id: this.$route.query.id, battle_station: battle_station }).then(
            (res) => {
              if (res.status == 1) {
                this.$message.success('加入成功')
                this.$emit('Refresh_room')
                this.$emit('join_Room')
              } else {
                this.$message.error(res.msg)
              }
            }
          )
        })
        .catch(() => {})
    },

    //接收子组件本次过渡信息
    endData(res) {
      this.$emit('moveData', res) //告知父组件本次过渡信息
    },

    //子组件动画结束
    slideEnd() {
      //结束后=>渲染金币=>获得饰品列表unshift
      this.$emit('slideEnd')
      setTimeout(() => {
        this.room_list.unshift(this.bill_data.skin_info[this.roomCount])
        this.user_price += this.bill_data.skin_info[this.roomCount].price * 1
        this.user_Ammunition +=
          this.bill_data.skin_info[this.roomCount].price * 1
      }, 300)
      setTimeout(() => {
        this.used_price = this.user_price
        this.used_Ammunition = this.user_Ammunition
      }, 500)
    },

    //动画组件单次动画处理完毕(可以开始)
    complete() {
      this.$emit('complete')
    },

    //开奖
    open_prize() {
      this.room_Status = 3
      this.Apparent = false
      this.room_list = this.bill_data.win_info
      //console.log("this.bill_data.win_info  " + this.bill_data.win_info)
      //获取弹药
      //this.getammo()
      if (this.bill_data.win_price * 1 || this.bill_data.win_price * 1 != 0.0) {
        let price = 0
        let Ammunition = 0
        if (this.bill_data.win_info) {
          for (let i = 0; i < this.bill_data.win_info.length; i++) {
            price += this.bill_data.win_info[i].price * 1
          }
          for (let i = 0; i < this.bill_data.skin_info.length; i++) {
            Ammunition += this.bill_data.win_info[i].price * 1
          }
        }
        this.user_price = price + this.bill_data.win_price * 1
        this.user_Ammunition = Ammunition
      } else {
        let price = 0
        let Ammunition = 0
        if (this.bill_data.win_info) {
          for (let i = 0; i < this.bill_data.win_info.length; i++) {
            price += this.bill_data.win_info[i].price * 1
          }
          for (let i = 0; i < this.bill_data.skin_info.length; i++) {
            Ammunition += this.bill_data.win_info[i].price * 1
          }
        }
        this.user_price = price
        this.user_Ammunition = Ammunition
      }
      if (this.room_list != null && this.room_list.length > 0  ){
        this.room_list.push({
          image_url:'https://oss.N7buff.com/n7buff/img//5.png',
          item_name:' ',
          price: this.bill_data.win_price,
          rarity_thumb: "https://oss.n7buff.com/n7buff/img//b.png"
        })
      }
    },

    //观战时奖品处理
    handle_prize() {
      let arr = this.bill_data.skin_info.concat()
      //console.log("***********arr*********",this.roomCount)
      this.room_list = arr.splice(0, this.roomCount).reverse()
      //console.log("***********room_list*********",this.room_list)
      let price = 0
      let Ammunition = 0
      for (let i = 0; i < this.room_list.length; i++) {
        // console.log(i);
        price += this.room_list[i].price * 1
        Ammunition += this.room_list[i].price * 1
      }
      this.used_price = price
      this.user_price = price
      this.used_Ammunition = Ammunition
      this.user_Ammunition = Ammunition
    }
  }
}
</script>
<style lang="scss">
@import '@/styles/variables.scss';
.failIcon{
  position: absolute;
  top: 2.2rem;
  width: 1.3rem;
  left: 50%;
  transform: translateX(-50%)
}
#room {
  // width: 320px;
  // background-color: red;
  .headPortrait-normal {
    width: 0.7rem;
    height: 0.7rem;
    position: relative;
    transform: translateX(7px);
    z-index: 1;
    .headPortrait {
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
    }
    // .headPortraitAround {
    //   display: block;
    //   width: 60px;
    //   height: 60px;
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -66%);
    // }
  }
  .room_container {
    // width: 3rem;
    background-color: rgba($color: #000000, $alpha: 0.2);
    .room_data {
      width: 100%;
      position: relative;
      // height: 2.5rem;
      // background: url('@/assets/images/n7_money.png') no-repeat center;
      // background-size: contain;
      // 进行中
      &.conduct {
        background: url('@/assets/images/fightImages/bottom.png') no-repeat center
        center;

        background-size: 100% 100%;
      }
      // 胜利
      &.win {
        border-top: 0;
        border-bottom: 2px $theme solid;
        background: url('@/assets/images/fightImages/victory.png') no-repeat
        center;
        background-size: contain;
        background-color: rgb(255 135 18 / 4%);
      }
      // 失败
      &.fail {
        background: url('@/assets/images/fightImages/lightning.png') no-repeat center
        0px;
        background-size: contain;
        border-top: 0;
        border-bottom: 2px $theme solid;
      }

      // 房间内用户信息
      .user_data {
        width: 100%;
        height: 2.4rem;
        padding-top: 0.2rem;
        box-sizing: border-box;
        border-top: 2px $theme solid;
        border-bottom: 2px #555559 solid;
        // background: rgba($color: #000000, $alpha: 0.2);

        .user_portrait {
          // width: 57px;
          // height: 57px;
          width: 0.7rem;
          height: 0.7rem;
          margin: 0 auto;
          position: relative;
          img:nth-child(1) {
            z-index: 4;
            position: relative;
          }
          img:nth-child(2) {
            z-index: 8;
            position: absolute;
          }
          @keyframes winner {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(720deg);
            }
          }
          .winnerBg {
            background: url('@/assets//images/Battle/lighting.png')
            no-repeat center center;
            background-size: 160% 160%;
            width: 1rem;
            height: 1rem;
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            left: -0.15rem;
            animation: winner 4s linear forwards infinite;
          }

          // .crown {
          //   position: absolute;
          //   width: 24px;
          //   height: 21px;
          //   right: 0;
          //   top: -12.5px;
          //   z-index: 1;

          //   img {
          //     //   width: 100%;
          //     //   height: 100%;
          //     border: none;
          //   }
          // }

          img {
            // width: 90%;
            // height: 90%;
            border-radius: 50%;
            // overflow: hidden;
            box-sizing: border-box;
            // position: relative;
            z-index: 2;
            &.win {
              border: 2px solid $theme;
            }

            &.add {
              border-color: transparent;
              width: 0.7rem;
              height: 0.7rem;
            }
            &.iconUrl {
              border: 2px solid $theme;
            }
          }
        }

        .user_name {
          text-align: center;
          margin-top: 0.1rem;
          font-size:0.3rem;
          p{
            margin: 0 !important;
          }
        }

        .user_info {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 0.1rem;

          .user_price {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.3rem;
            font-weight: bold;
            color: $theme;

            img {
              width: 0.4rem;
              height: 0.4rem;
              margin-right: 0.2rem;
            }
          }
        }
      }

      .room_core {

        .room_state {
          width: 100%;
          height: 205px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 40px 0 0 10px;
          box-sizing: border-box;
          img {
            max-width: 100%;
          }
          .wait_state {
            font-size:0.3rem;
            font-family: 'newfont';
          }
        }
      }
    }

    .room_prize_box {
      width: 100%;
      //min-height: 6rem;
      height: 6.4rem;
      background: rgba($color: #1d1d23, $alpha: 0.2);
      // max-height: 12rem;
      overflow-y: auto;
      overflow-x: hidden;
      *{
        margin: 0;
      }
      /*样式改变代码-----------------------------------------------------*/
      /* 滚动条整体部分 */
      &::-webkit-scrollbar {
        width: 4px;
        height: 10px;
        background-color: none;
        border-radius: 10px;
      }

      /* scroll轨道背景 */
      &::-webkit-scrollbar-track {
        // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 10px;
        background-color: none;
      }

      /* 滚动条中能上下移动的小块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #555559;
      }

      &.win {
        background: rgba($color: $theme, $alpha: 0.06);
      }

      .room_prize {
        // width: 320px;
        // padding: 10px 10px;
        // box-sizing: border-box;
        height: 6.4rem;
        text-align: center;
        & span {
          //display: flex;
          //flex-wrap: wrap;
        }
        // text-align: center;
        .room_prize_item {
          display: inline-block;
          transition: all 0.3s;
          width: 1.7rem;
          height: 1.7rem;
          // margin: auto;

          //padding: 0 0.1rem 0.15rem;
          box-sizing: border-box;
          background-repeat: no-repeat;
          //background-position: center top;
          background-position: 50%;
          //background: url('@/assets/images/fightImages/框青.png') no-repeat center !important;
          background-size:100% 100% !important;
          .pic {
            width: 100%;
            height: 0.7rem;
            // margin-top: 20px;
            text-align: center;
            // line-height: 60px;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }
          .item {
            width: 100%;
          }
          p {
            // padding: 0 10px;
            font-weight: 700;
            box-sizing: border-box;
            text-align: center;
            font-size: 0.3rem;
            color: #ffffff;
            // margin-top: 4px;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .price {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.3rem;
            font-weight: bold;
            color: $theme;
            // margin-top: 6px;

            img {
              width: 0.4rem;
              height: 0.4rem;
              // margin-top: 2px;
              // margin-right: 4px;
            }
          }
        }
      }
    }
  }

  .animation-enter,
  .animation-leave-to {
    opacity: 0;
    transform: translateY(-30px);
  }

  .animation-leave-active {
    position: absolute;
  }
}
// @import "@/assets/media/media.scss";
</style>
