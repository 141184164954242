<template>
	<div class="oplist">
		<ul class="list_ul">
			<li v-for="(item, index) in list" :key="index" @click="go(item.box_id)">
				<div class="box">
					<img :src="item.rarity_thumb" alt="" />
					<div class="imgbox">
						<div class="image"><img :src="item.image_url" alt="" /></div>
						<div class="user">
							<div class="imgs hnk"><img :src="item.player_head_img" alt="" /></div>
							<div class="text hnk">{{ item.player_nike_name }}</div>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
import { get_open_box_record } from '@/api/index/home.js';
export default {
	data() {
		return {
			list: []
		};
	},
	created() {
		this.$nextTick(()=>{
			get_open_box_record().then(res => {
			this.list = res.data;
			document.querySelector('.list_ul').style.width = (0.3 + 3.3) * res.data.length - 1 + 'rem';
		});
		})
		
	},
	methods: {
		go(e) {
			this.$router.push('/BoxShow?id=' + e);
			//  this.$router.push({ path: '/OrnamentOpen', query: { id: e} })
			this.$emit('changes', e);
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@/styles/home/Boxshow.scss';
</style>
