import request from '@/api/request'
// 等级盲盒列表
export const get_grade_box_list = (data) => {
  return request({
    url: '/api/grade_box/get_grade_box_list',
    method: 'POST',
    data
  })
}
// 等级盲盒箱内饰品
export const get_grade_box_data = (data) => {
  return request({
    url: '/api/grade_box/get_grade_box_data',
    method: 'POST',
    data
  })
}
// 获取等级盲盒可开启次数
export const get_open_box_num = (data) => {
  return request({
    url: '/api/grade_box/get_open_box_num',
    method: 'POST',
    data
  })
}
// 获取等级列表信息
export const get_player_grade_list = (data) => {
  return request({
    url: '/api/player_grade/get_player_grade_list',
    method: 'POST',
    data
  })
}
// 开启等级盲盒
export const open_grade_box = (data) => {
  return request({
    url: '/api/grade_box/open_grade_box',
    method: 'POST',
    data
  })
}

// 等级盲盒玩法介绍
export const get_play_introduction = (data) => {
  return request({
    url: '/api/grade_box/get_play_introduction',
    method: 'POST',
    data
  })
}
