<template>
	<div class="listBox">
		<div class="lists" v-for="item in listArr" :key="item.box_category_name">
			<div class="title">
				<img src="../../assets/images/title.png" alt="" />
				<p>
					<i>{{ item.box_category_name }}</i>
				</p>
			</div>
			<ul>
				<li class="hnk" v-for="item1 in item.box_list" :key="item1.id" @click="boxShow(item1.id)">
					<div class="imgBox" @mouseover="mouseover"  v-lazy:background-image="item1.img_main" >
						<!-- <img :src="item1.img_main" alt="" /> -->
						<img class="imgtwo" v-lazy="item1.img_active" alt="" />
					</div>
					<p>
						<img src="../../assets/images/n7_money.png" alt="" />
						{{ item1.price }}
					</p>
					<div class="name">{{ item1.name }}</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { get_box_list } from '@/api/index/home.js';
export default {
	data() {
		return {
			listArr: [],
			active: ''
		};
	},
	created() {
		get_box_list().then(res => {
			// console.log(res.data.data);
			this.listArr = res.data;
			this.$nextTick(()=>{
				document.documentElement.scrollTop= sessionStorage.getItem('selfScrollTop')-0
			})

		});
	},
	methods: {
		boxShow(id) {
			this.$router.push({ path: '/BoxShow', query: { id: id } });
			// this.audio.pause()
		},
		mouseover() {}
	},
};
</script>

<style lang="scss" scoped>
@import '@/styles/home/Listarr.scss';
</style>
