<!-- 充值 -->
<template>
  <div
    class="topUp"
    :visible="visible"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="closebut" @click="closebut">
      <img src="@/assets/images/userWidget/close.png" alt=""/>
    </div>
    <div class="topUpBody">
      <div class="left">
        <div class="WeChat">
          <div
            class="aisle"
            v-for="item in playerList"
            :key="item.id"
            @click="selectAisle(item.id,item.mode)"

          >
            <img
              v-show="pay_type_id !== item.id"
              class="aisleImg"
              src="@/assets/images/topUpImg/passageway.png"
              alt=""
            />
            <img
              v-show="pay_type_id === item.id"
              class="aisleImg"
              src="@/assets/images/topUpImg/passageway2.png"
              alt=""
            />
            <div
              class="aisleText"
              :style="
                pay_type_id === item.id ? 'color: #eece3a' : 'color: #22fffc'
              "
            >
              {{ item.title }}
            </div>
            <img
              v-if="item.mode === 'weixin'"
              class="WeChatIco"
              src="@/assets/images/topUpImg/wechat.png"
              alt=""
            />
            <img
              v-else
              class="WeChatIco"
              src="@/assets/images/topUpImg/alipay.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="centre">
        <div class="centreTitle">
          如遇到任何问题请联系客服解决，竭诚为您服务
        </div>
        <div class="centreBody">
          <div class="recharge">
            <ul>
              <li
                :class="recharge_id === item.id ? 'liBack' : 'liBackS'"
                v-for="(item, i) in rechargeList"
                :key="item.id"

                @click="
                  rechargeListApi(
                    item.pay_recharge_amount,
                    item.show_recharge_amount,
                    item.show_give_amount,
                    item.id,
                    i
                  )
                "
              >
                <div class="rechargeImg">
                  <img :src="item.image_url" alt=""/>
                </div>
                <div class="rechargeText">{{ item.show_recharge_amount }}</div>
                <div class="giveAmount" v-if="item.show_give_amount > 0">
                  <img src="@/assets/images/topUpImg/Gift box.png" alt=""/>
                  <div>送 {{ item.show_give_amount }}</div>
                </div>
              </li>
            </ul>
          </div>
          <div class="right">
            <div class="money">
              <div>
                实付金额: <span> {{ pay }}</span>
              </div>
              <div>
                到账金额:<span class="tow"> {{ show + showGiveAmount }} </span>
              </div>
            </div>
            <div class="agreement">
              <el-checkbox v-model="checked"></el-checkbox>
              本人已本人已满18周岁且具备完全行为能力，充值即代表同意
              <span :style="VPointer()" @click="goHelp(3)">《用户协议》</span>
              、
              <span :style="VPointer()" @click="goHelp(2)">《隐私政策》</span>
              。盲盒娱乐请理性消费，切勿上头
            </div>
            <div class="atTopUp" @click="rechargeApi"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="winningResults" v-if="paymentCode">
      <div class="paymentCode">
        <div class="paymentCode-title">
          <img
            class="pay_type_img"
            :src="pay_type_img"
            alt=""
          />
          <span>{{ activeName }}</span>
        </div>
        <div class="closebutCode" :style="VPointer()" @click="closebutCode">
          <img src="@/assets/images/userWidget/close.png" alt=""/>
        </div>
        <div id="qrcode" ref="qrcode"></div>
        <div class="save-qrcode">
          <!-- 请使用另一部手机扫码支付 -->
          <el-button type="warning" v-if="pay_mode == 'alipay'">请截图保存
            <!-- </br> 或复制链接支付 -->
          </el-button>
          <el-button type="warning" v-else>请用其它手机扫码支付
            <!-- </br> 或复制链接支付 -->
          </el-button>
        </div>
        <!-- <div class="paymentCode-ticp">您的二维码将在 {{times}} 秒后失效</div> -->
      </div>
    </div>

  </div>
</template>

<script>
import {get_player, get_recharge_list, recharge} from '@/api/index/TopUp'
import QRCode from 'qrcodejs2'

export default {
  name: 'topUp',
  data() {
    return {
      rechargeIndex: '',
      paymentCode: false, // 支付二维码
      modePayment: 1, // 选择通道
      checked: false, // 协议选择
      playerList: [], //充值通道列表
      rechargeList: [], //充值通道下可充值额度列表
      pay_type_id: null, //  选中充值通道列表id
      recharge_id: null, // 选中充值额度id
      pay_mode: '',//类型：alipay，weixin'
      wechat_img: require('@/assets/images/topUpImg/wechat.png'),
      alipay_img: require('@/assets/images/topUpImg/alipay.png'),
      pay_type_img: '',
      pay: 0, // 实际支付金额
      show: 0, //	显示到账金额
      showGiveAmount: 0, //赠送金额
      code_url: '',// 充值二维码
      poneType: '',
      activeName: "",
      times: 30,
      timer: null
    }
  },
  props: {
    visible: Boolean
  },
  watch: {
    visible(val) {
      if (val) {
        this.getPlayerApi()
        this.getRechargeListApi()
        this.getRechargeList()
      }
    }
  },
  created() {
  },
  mounted() {
    this.finishActivity()
  },
  methods: {
    goHelp(e) {
      this.$router.push('/Helpcenter?id=' + e)
      this.$parent.topUpRetrieve = false
    },
    // 获取充值通道列表
    getPlayerApi() {
      get_player().then((res) => {
        //console.log(res.data);
        this.playerList = res.data
        this.pay_type_id = res.data[0].id
        console.log(this.pay_mode)
        this.pay_mode = res.data[0].mode
      })
    },
    // 选中充值通道
    selectAisle(id, mode) {
      console.log('选中', id)
      this.pay_type_id = id
      this.pay_mode = mode
      this.getRechargeListApi(id)
    },

    getRechargeListApi() {
      // 获取充值通道下可充值额度列表
      get_recharge_list({pay_type_id: this.pay_type_id}).then((res) => {
        this.rechargeList = res.data
      })
    },
    // 默认选中充值额度
    getRechargeList() {
      get_recharge_list({pay_type_id: this.pay_type_id}).then((res) => {
        this.pay = Number(res.data[0].pay_recharge_amount)
        this.show = Number(res.data[0].show_recharge_amount)
        this.recharge_id = res.data[0].id
      })
    },
    // 选择充值的额度
    rechargeListApi(pay, show, showGiveAmount, id, index) {
      this.pay = Number(pay)
      this.show = Number(show)
      this.showGiveAmount = Number(showGiveAmount)
      this.recharge_id = id
      this.rechargeIndex = index
    },
    // 充值
    rechargeApi() {
      if (this.checked) {
        recharge({
          pay_type_id: this.pay_type_id,
          recharge_id: this.recharge_id
        }).then((res) => {
          console.log(res)
          if (res.status === 1) {
            this.activeName = this.playerList.filter(e => e.id == this.pay_type_id)[0].title
            if (this.playerList.filter(e => e.id == this.pay_type_id)[0].mode == 'alipay') {
              this.pay_type_img = this.alipay_img
            } else if (this.playerList.filter(e => e.id == this.pay_type_id)[0].mode == 'weixin') {
              this.pay_type_img = this.wechat_img
            }
            this.code_url = res.data.code_url
            console.log(this.pay_mode, this.poneType)
            // console.log(sessionStorage.getItem('app'),'是不是app啊')

            if (this.pay_mode == 'alipay') {
              if (this.poneType == 'ios') {
                /*console.log(res.data.code_url)
                window.location.href = res.data.code_url
                return*/
              } else {
                if (navigator.userAgent.indexOf('Html5Plus') == -1) {
                  // alert("浏览器");
                  window.location.href = res.data.code_url
                  return
                } else {
                  // alert("app");
                }
              }
            }


            this.$message({
              message: res.msg,
              type: 'success'
            })

            if (res.data.code_open == 1) {

              //window.location.href = 	res.data.code_ur;
              /* window.location.href =
                        "alipays://platformapi/startapp?saId=20000067&url=" +
                        encodeURIComponent(res.data.code_url); */
              //location.replace("alipays://platformapi/startapp?saId=20000067&url=".encodeURIComponent(res.data.code_url));
            }

            /* if(this.pay_mode == 'alipay'){
              if (this.poneType == 'ios') { //苹果支付宝支付
                if (sessionStorage.getItem('app')) {
                  plus.runtime.openWeb('alipay://platformapi/startapp?appId=20000067&url=' +
                    encodeURIComponent(this.code_ur), '_self')
                  // plus.runtime.openURL(urlStr)
                } else {
                  window.open('alipay://platformapi/startapp?appId=20000067&url=' +
                    encodeURIComponent(this.code_ur), '_self')
                }
              } else { //安卓支付宝支付
                if (sessionStorage.getItem('app')) {
                  plus.runtime.openWeb(
                    "https://render.alipay.com/p/s/i/?alipay://platformapi/startapp?appId=20000067&url=" +
                    encodeURIComponent(this.code_ur), '_self')
                  // plus.runtime.openURL(urlStr)
                } else {
                  window.open(
                    "https://render.alipay.com/p/s/i/?alipay://platformapi/startapp?appId=20000067&url=" +
                    encodeURIComponent(this.code_ur), '_self')
                }
              }
            }else{

            }	 */
            this.times = 30
            this.rechargeS()

          } else {
            this.$message.error(res.msg)
          }
          //console.log(res)
        })
      } else {
        this.$message.error('请阅读以下协议并勾选同意')
      }
    },
    rechargeS() {
      this.paymentCode = true
      //this.$refs.qrcode.innerHTML = ""
      //this.getCoed()
      this.$nextTick(() => {
        const qrCode = new QRCode("qrcode", {
          width: 150,
          height: 150,
          text: this.code_url,
        });
      });


      /* setTimeout(() => {
        this.getCoed()
       this.$nextTick(()=>{
        this.timer=setInterval(() => {
          this.times--
          if(this.times<=0){
          clearInterval(this.timer)
          this.closebutCode()
          }
        },1000);
        })
      }, 0) */
    },
    //  生成支付二维码
    getCoed() {
      var qrcode = new QRCode(this.$refs.qrCodeUrl, {
        width: 150, // 宽
        height: 150, // 高
        text: this.code_url
      })
    },
    // 关闭二维码
    closebutCode() {
      this.paymentCode = false
      if (sessionStorage.getItem('n7_token')) {
        this.$store.dispatch('getPlayerApi')
      }
      this.$refs.qrcode.innerHTML = "";
      setTimeout(() => {
        this.$store.dispatch('getPlayerApi')
      }, 1000)
    },
    // 判断手机类型 从而调用不同的方法
    finishActivity() {
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      if (isiOS) {
        this.poneType = 'ios'
      } else {
        this.poneType = 'Android'
      }
    },
    saveImg() {
      var canvasData = this.$refs.qrcode.getElementsByTagName("canvas");
      var a = document.createElement("a");
      var event = new MouseEvent("click");
      a.href = canvasData[0].toDataURL("image/png");
      a.download = "支付二维码";
      a.dispatchEvent(event);
    },

    // 关闭
    closebut() {
      if (sessionStorage.getItem('n7_token')) {
        this.$store.dispatch('getPlayerApi')
      }
      this.checked = false
      this.paymentCode = false
      clearInterval(this.timer)
      this.$emit('update:visible', false)
      var mo = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = '' //出现滚动条
      document.removeEventListener('touchmove', mo, false)
    }
  }
}
</script>
<style lang="scss" scoped>
// 引入css类
// @import '';
@import '@/styles/variables';

.winningResults {
  z-index: 90;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}

.el-button--warning {
  color: #fff;
  background-color: #E6A23C;
  border-color: #E6A23C;
  font-size: .36rem;
}

.save-qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

.paymentCode {
  width: 4.5rem;
  height: 6rem;
  position: fixed;
  text-align: center;
  padding: 10px;
  border-radius: 3px;
  background: #333542;
  margin-top: 5.3rem;
  margin-left: 3rem;
  border-radius: 0.1rem;
}

.pay_type_img {
  height: 0.6rem;
  width: 0.6rem;
  float: left;
  margin-left: 1.3rem;

}

.paymentCode-ticp {
  text-align: center;
  color: #000;
  margin-top: 5px;
}

.paymentCode-title {
  span {
    align-items: center;
    text-align: center;
    font-weight: bold;
    color: #ffffff;
    display: flex;
    margin-bottom: 10px;
    height: 0.6rem;
    line-height: 0.6rem;
    font-size: 0.3rem;
  }

}

.closebutCode {
  position: absolute;
  top: -5%;
  right: -5%;
  z-index: 2;

  img {
    width: 0.8rem;
    height: 0.8rem;
  }
}

::v-deep #qrcode {

  img {
    width: 4rem;
    height: 4rem;
    //display: inline-block !important;
  }
}

.topUp {
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 9rem;
  // height: 7rem;
  left: 0;
  right: 0;
  top: 3rem;
  margin: auto;
  // 关闭按钮
  .closebut {
    position: absolute;
    top: -0.5rem;
    right: -0.3rem;

    img {
      width: 1rem;
    }
  }

  .topUpBody {
    width: 100%;
    height: 100%;
    background: url('@/assets/images/fightImages/rectangle.png') no-repeat;
    background-size: 100% 100%;
    padding: 20px 50px;
    // display: flex;
    .left {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-around;

      .WeChat {
        width: 100%;
        overflow: auto;
        // height: 500px;
        // width: 2rem;
        // height: 1rem;
        // background-color: red;
        display: flex;
        // flex-direction: column;
        // align-items: flex-end;
        // justify-content: space-evenly;
        justify-content: space-between;

        .aisle {
          margin-top: 0.3rem;
          position: relative;
          height: 1rem;
          margin: 0.2rem;

          .aisleImg {
            width: 3rem;
            height: 100%;
          }

          .aisleText {
            font-size: 0.3rem;
            position: absolute;
            top: 45%;
            left: 60%;
            transform: translate(-50%, -50%);
            color: #eece3a;
            width: 2rem;
            font-weight: 700;
          }

          .WeChatIco {
            width: 0.5rem;
            position: absolute;
            left: 15%;
            top: 20%;
          }
        }
      }
    }

    // .rightImg {
    //   img {
    //     height: 550px;
    //   }
    // }
    .centre {
      // width: 850px;
      font-size: 0.3rem;
      margin: 0.1rem 0px 0 0.1rem;
      // .centreTitle {
      //   // margin-bottom: 30px;
      // }
      .centreBody {
        margin-top: 0.3rem;
        // height: 500px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .recharge {
          ul {
            // width: 900px;
            height: 6rem;
            display: flex;
            flex-wrap: wrap;
            overflow: auto;
            .liBack {
              width: 2.5rem;
              height: 3.2rem;
              background: url('@/assets/images/topUpImg/check.png') no-repeat !important;
              background-size: 100% 100% !important;
              position: relative;
              // margin-right: 20px;
              // margin-top: 20px;
            }

            .liBackS {
              width: 2.5rem;
              height: 3.2rem;
              background: url('@/assets/images/topUpImg/Recharge box.png') no-repeat !important;
              background-size: 100% 100% !important;
              position: relative;
              // margin-right: 20px;
              // margin-top: 20px;
            }

            li {
              width: 120px;
              height: 160px;
              // background-size: 120px;
              position: relative;
              margin-right: 0.2rem;
              margin-top: 0.2rem;

              .rechargeImg {
                position: absolute;
                top: 49%;
                left: 50%;
                transform: translate(-50%, -50%);

                img {
                  width: 0.8rem;
                }
              }

              .rechargeText {
                position: absolute;
                top: 85%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 0.3rem;
                font-weight: 700;
                color: #eece3a;
              }

              .giveAmount {
                position: absolute;
                width: 100%;
                top: 5%;
                left: -1%;

                & > img {
                  width: 87%;
                  // height: 30px;
                }

                & > div {
                  font-size: 0.3rem;
                  color: $hoverText;
                  font-weight: 700;
                  position: absolute;
                  width: 100%;
                  // line-height: 30px;
                  top: 0;
                  left: 0;
                }
              }

              &:nth-child(6) {
                margin-right: 0;
              }
            }
          }
        }

        .right {
          // margin-top: 50px;
          margin-right: 0.3rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          // position: absolute;
          .money {
            display: flex;
            font-size: 0.40rem;
            padding: .2rem 0;

            span {
              color: #eece3a;
              font-weight: 700;
            }

            div {
              &:nth-child(2) {
                margin-left: 0.5rem;
              }
            }
          }

          .agreement {
            font-size: 0.3rem;
            // margin: 20px 0;
            // width: 820px;
            span {
              color: $theme;
            }
          }

          .atTopUp {
            margin-top: 0.1rem;
            width: 4rem;
            height: 1.5rem;
            background: url('@/assets/images/topUpImg/Recharge-immediately.png') no-repeat;
            background-size: 100%;

          }
        }
      }
    }
  }
}
</style>
<style>
.el-checkbox {
  color: #fff;
  font-size: 0.3rem;
  width: 0.3rem;
  height: 0.3rem;

}

.el-checkbox__input {
  width: 100% !important;
  height: 100% !important;
}

.el-checkbox__inner {
  width: 100% !important;
  height: 100% !important;
}
</style>
