<template>
  <div id="action">
    <div
      class="action_container"
      ref="action_container"
      @transitionend="Transitionend"
    >
      <div
        class="action_item" :style="{width:wid==3?(5.8-wid+'rem'):(6.2-wid+'rem')}"
        v-for="(item, index) in volutionList"
        :key="index"
        
      >
        <div class="pic">
          <img :src="item.image_url" :style="{width:wid==3?(5-wid+'rem'):(5.4-wid+'rem')}"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AaAction',
  props: ['start', 'check_data', 'battleBoxList', 'GoodsList', 'roomCount','wid'],
  data() {
    return {
      data_list: [], //处理
      volutionList: [], //处理后数组
      //过渡class
      soundStyleArr: [
        {
          name: 'action_container soundone'
        },
        {
          name: 'action_container soundtwo'
        },
        {
          name: 'action_container soundthree'
        },
        {
          name: 'action_container soundfour'
        },
        {
          name: 'action_container soundfive'
        },
        {
          name: 'action_container soundsix'
        },
        {
          name: 'action_container soundseven'
        },
        {
          name: 'action_container soundeight'
        },
        {
          name: 'action_container soundnine'
        },
        {
          name: 'action_container soundten'
        }
      ]
    }
  },

  created() {
    if (this.roomCount != -1) {
		
      this.Arraysplicing()
    }
  },

  methods: {
	  
    //拼接并打乱数组
    Arraysplicing() {
		
      this.data_list = []
      let expect = 20 - this.battleBoxList[this.roomCount].skin_list.length
      let num = 2
      if (expect > 0) {
        num =
          Math.floor(expect / this.battleBoxList[this.roomCount].skin_list.length) + 2
      }
      let A_Arr = []
      for (let i = 0; i < num; i++) {
        A_Arr = A_Arr.concat(this.battleBoxList[this.roomCount].skin_list)
      }
      //打乱
      function randSort(A_Arr) {
        for (let i = 0, len = A_Arr.length; i < len; i++) {
          let rand = parseInt(Math.random() * len)
          let temp = A_Arr[rand]
          A_Arr[rand] = A_Arr[i]
          A_Arr[i] = temp
        }
        return A_Arr
      }

      this.data_list = randSort(A_Arr)
      this.data_list[0] = this.GoodsList[this.roomCount]
      this.$emit('complete')
    },

    //过渡
    Post_transition() {
      this.$refs.action_container.className = 'action_container none'
      if (this.roomCount == 0) {
        this.$emit('toApparent')
      }
      setTimeout(() => {
        this.$nextTick(() => {
          this.volutionList = this.data_list
          this.$forceUpdate()
          this.$refs.action_container.className =
          //this.soundStyleArr[this.order[this.roomCount]].name + ' working' //加过渡类名
		  this.soundStyleArr[this.check_data[this.roomCount]].name + ' working' //加过渡类名
          this.$emit('endData', 'yes') //告知父组件本次过渡信息
        })
      }, 100)
    },

    //过渡结束
    Transitionend() {
      this.$emit('slideEnd')
    }
  },

  watch: {
    start: {
      deep: true,
      handler: function (newV) {
        if (newV.manage) {
          this.Post_transition()
        }
      }
    },

    roomCount() {
      this.Arraysplicing()
    }
  }
}
</script>
<style lang="scss">
#action {
  // width: 320px;
  height: 1.5rem;
  overflow: hidden;
  background: rgba($color: #000000, $alpha: 0.2);
  .action_container {
    transform: translateY(-3895px);

    &.soundone {
      transition: 3s cubic-bezier(0.2, 0.27, 0.13, 0.86);
    }

    &.soundtwo {
      transition: 3s cubic-bezier(0.2, 0.27, 0.13, 0.88);
    }

    &.soundthree {
      transition: 3.4s cubic-bezier(0.2, 0.32, 0.16, 0.84);
    }

    &.soundfour {
      transition: 3.8s cubic-bezier(0.2, 0.24, 0.13, 0.9);
    }

    &.soundfive {
      transition: 4.2s cubic-bezier(0.2, 0.36, 0.19, 0.87);
    }

    &.soundsix {
      transition: 4.6s cubic-bezier(0.2, 0.3, 0.15, 0.8);
    }

    &.soundseven {
      transition: 5s cubic-bezier(0.2, 0.2, 0.3, 0.89);
    }

    &.soundeight {
      transition: 5.4s cubic-bezier(0.2, 0.31, 0.15, 0.82);
    }

    &.soundnine {
      transition: 5.6s cubic-bezier(0.2, 0.3, 0.14, 0.96);
    }

    &.soundten {
      transition: 6s cubic-bezier(0.2, 0.29, 0.14, 0.92);
    }

    &.none {
      transition: none !important;
    }

    &.working {
      transform: translateY(0);
    }

    .action_item {
      // width: 320px;
      // height: 205px;
      //background-image: url(../../../assets/images/Battle/battleDetail/lan.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .pic {
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      &:nth-child(2n) {
        //background-image: url(../../../assets/images/Battle/battleDetail/huang.png);
      }
    }
  }
}
// @import "@/assets/media/media.scss";
</style>
