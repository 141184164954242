<!-- 开箱 -->
<template>
  <div class="body">
    <div :class="[{ introduced: AnimationsPopup }]"></div>
    <BodyBackground />
    <HomeBodyAlert class="body-alert" />
    <div class="body-background">
      <img
      class="body-background-img"
      src="https://oss.n7buff.com/mobile/assets/images/showImages/top_bak.png"
      alt=""
    />
    </div>

    <div class="body-main">
      <!-- 主体物 -->
      <div class="body-main-openBox">
        <div class="body-main-openBox-titOpen">
          <div class="body-main-openBox-titOpen-but">
            <img src="https://oss.n7buff.com/mobile/assets/images/showImages/n7open1.png" alt="" />
            <div class="body-main-openBox-titOpen-but-tit">{{ boxData.name }}</div>
          </div>
        </div>
        <!-- <img
          class="body-main-openBox-light"
          src="@/assets/images/showImages/光.png"
          alt=""
        /> -->
        <img class="body-main-openBox-box" :src="boxData.img_main" alt="" />
        <img class="body-main-openBox-res" :src="boxData.img_active" alt="" />
      </div>
      <!-- 最新掉落 -->
      <!-- <div class="body-main-openBox-side">
        <img
          class="body-main-openBox-side-sideImg"
          src="@/assets/images/showImages/最新掉落.png"
          alt=""
        />
        <ul class="body-main-openBox-side-sideImg-ul">
          <li
            class="body-main-openBox-side-sideImg-ul-li"
            v-for="item in recordList"
            :key="item.id"
          >
            <img
              class="body-main-openBox-side-sideImg-ul-li-sideIcon"
              :src="item.player_head_img"
              alt=""
            />
            <div class="body-main-openBox-side-sideImg-ul-li-sidePrice">
              {{ item.player_nike_name }}
            </div>
            <div
              :title="item.item_name"
              class="body-main-openBox-side-sideImg-ul-li-sideName"
            >
              {{ item.item_name }}
            </div>
          </li>
        </ul>
      </div> -->
      <!-- 声音开关 -->
      <!-- <div
        class="body-main-openBox-showSound"
        :style="VPointer()"
        :class="
          showSounds
            ? 'body-main-openBox-showSoundTrue'
            : 'body-main-openBox-showSoundFalse'
        "
        @click="showSound"
      > -->
        <!-- 声音图标 -->
        <!-- <div class="body-main-openBox-showSoundTrueIcon">
          <svg
            v-if="this.$store.state.Audio"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            class="bi bi-volume-up-fill"
            viewBox="0 -2 16 16"
          >
            <path
              d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"
            />
            <path
              d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"
            />
            <path
              d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z"
            />
          </svg>
          <svg
            v-if="!this.$store.state.Audio"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            class="bi bi-volume-mute-fill"
            viewBox="0 -2 16 16"
          >
            <path
              d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708L12.207 8l1.647 1.646a.5.5 0 0 1-.708.708L11.5 8.707l-1.646 1.647a.5.5 0 0 1-.708-.708L10.793 8 9.146 6.354a.5.5 0 1 1 .708-.708L11.5 7.293l1.646-1.647a.5.5 0 0 1 .708 0z"
            />
          </svg>
        </div>
        {{ this.$store.state.Audio ? '声音已开启' : '声音已关闭' }}
      </div>-->
      <!-- 动画开关 -->
      <div
        class="body-main-openBox-showAnimation"
        :style="VPointer()"
        :class="
          showAnimations
            ? 'body-main-openBox-showAnimationTrue'
            : 'body-main-openBox-showAnimationFalse'
        "
        @click="showAnimation(1)"
      >
        <!-- 动画图标 -->
        <div class="body-main-openBox-showAnimationTrueIcon">
          <svg
            v-if="showAnimations"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            class="bi bi-camera-video-fill"
            viewBox="0 -3 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2V5z"
            />
          </svg>
          <svg
            v-if="!showAnimations"
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            fill="currentColor"
            class="bi bi-camera-video-off-fill"
            viewBox="0 -2 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M10.961 12.365a1.99 1.99 0 0 0 .522-1.103l3.11 1.382A1 1 0 0 0 16 11.731V4.269a1 1 0 0 0-1.406-.913l-3.111 1.382A2 2 0 0 0 9.5 3H4.272l6.69 9.365zm-10.114-9A2.001 2.001 0 0 0 0 5v6a2 2 0 0 0 2 2h5.728L.847 3.366zm9.746 11.925-10-14 .814-.58 10 14-.814.58z"
            />
          </svg>
        </div>
        <!-- {{ showAnimations ? '动画已开启' : '动画已关闭' }} -->
      </div>
      <!-- 数量 -->
      <div class="body-main-count">
        <img
          v-if="count !== 1"
          @click="countImg(1)"
          src="@/assets/images/showImages/1.png"
          alt=""
          :style="VPointer()"
        />
        <img
          v-if="count === 1"
          @click="countImg(1)"
          src="@/assets/images/showImages/1_copy.png"
          alt=""
          :style="VPointer()"
        />
        <img
          v-show="count !== 2"
          @click="countImg(2)"
          src="@/assets/images/showImages/2.png"
          alt=""
          :style="VPointer()"
        />
        <img
          v-show="count === 2"
          @click="countImg(2)"
          src="@/assets/images/showImages/2_copy.png"
          alt=""
          :style="VPointer()"
        />
        <img
          v-show="count !== 3"
          @click="countImg(3)"
          src="@/assets/images/showImages/3.png"
          alt=""
          :style="VPointer()"
        />
        <img
          v-show="count === 3"
          @click="countImg(3)"
          src="@/assets/images/showImages/3_copy.png"
          alt=""
          :style="VPointer()"
        />
        <img
          v-show="count !== 4"
          @click="countImg(4)"
          src="@/assets/images/showImages/4.png"
          alt=""
          :style="VPointer()"
        />
        <img
          v-show="count === 4"
          @click="countImg(4)"
          src="@/assets/images/showImages/4_copy.png"
          alt=""
          :style="VPointer()"
        />
        <img
          v-show="count !== 5"
          @click="countImg(5)"
          src="@/assets/images/showImages/5.png"
          alt=""
          :style="VPointer()"
        />
        <img
          v-show="count === 5"
          @click="countImg(5)"
          src="@/assets/images/showImages/5_copy.png"
          alt=""
          :style="VPointer()"
        />
      </div>
      <!-- 开启按钮 -->
      <img
        @click="open"
        class="body-main-openBut"
        src="@/assets/images/showImages/open.png"
        alt=""
        :style="VPointer()"
      />
      <!-- 货币 -->
      <div class="body-main-count-currency">
        <img src="@/assets/images/n7_money.png" alt="" />
        <span class="body-main-count-currency-tit">{{
          (boxData.price * count).toFixed(2)
        }}</span>
      </div>
      <!-- v-show="AnimationsPopup && skinsList" -->
   <BoxShowPopup
        class="BoxShowBodyOpenTake"
        v-show="AnimationsPopup && skinsList"
        :visible.sync="AnimationsPopup"
        :count="count"
        :resultList="resultList"
        :show_prize="show_prize.show"
      />

	<!-- 单开 -->
	<transverse v-if="showAnimations" :goodsList="boxData.skins" :prize_data="prize_data" @end="end" ref="transverse" @finishCallback="finishCallback"></transverse>

	<!-- 多开 -->
	<portrait v-if="showAnimations" :goodsList="boxData.skins" :prize_data="prize_data" @end="end" @finishCallback="finishCallback" ref="portrait"></portrait>

    </div>
  </div>
</template>

<script>
// import BoxShowPopup from '../../components/boxShow/BoxShowPopup.vue'
import transverse from './transverse.vue'
import portrait from './portrait.vue'

import BoxShowPopup from '../../components/boxShow/BoxShowPopup.vue'
import BodyBackground from '../../components/BodyBackground.vue'
import HomeBodyAlert from '../../components/home/Home_alert.vue'
import { open_box, get_open_box_record } from '@/api/index/boxCorrelation'
import bus from '@/utils/bus.js'
export default {
  name: 'BoxShowBody',
  components: {
	  transverse,
	  portrait,
    BoxShowPopup,
    BodyBackground,
    HomeBodyAlert
  },
  data() {
    return {
      count: 1, // 箱子个数
      showAnimations: true, // 动画
      AnimationsPopup: false, // 弹出层
      //audio: new Audio(require('@/assets/audio/滚动出枪.mp3')), //过渡声音
	  current: new Audio('https://oss.n7buff.com/mobile/audio/roll9.mp3'), //过程声音
	  show_audio: new Audio('https://oss.n7buff.com/mobile/audio/Shoot.mp3'), //过程声音
	  prize_data: {
	    //奖品信息
	    show: '',
	    list: []
	  },
	  show_prize: {
	    //奖品信息(展示所用，别问什么写两个)
	    show: false,
	    list: []
	  },
      recordList: [], // 开箱历史
      resultList: [], // 结果
      skinsList: [] ,// 动画总数据,
      showSounds:true
    }
  },
  props: { boxData: Object },
  watch: {
    // 监听弹出层的变化
    AnimationsPopup() {

      if (!this.AnimationsPopup) {
        //this.audio.pause()
      }
    }
  },
  beforeDestroy(){
          bus.$off('colseInterval')
          bus.$off('openInterval')
  },
  created() {
    // this.getOpenBoxRecordApi()
  /*   open_box({
        box_id: this.$route.query.id,
        total: this.count
      }).then((res) => {
        this.resultList = res.data
      }) */
  },
  methods: {
    // 开箱动画数据
    // async getBoxDataApi() {
    //   const res = await get_box_data({ id: this.$route.query.id })
    //   this.skinsList = res.data.skins
    //   console.log(this.skinsList)
    // },
    // 开箱记录
    // getOpenBoxRecordApi() {
    //   get_open_box_record().then((res) => {
    //     this.recordList = res.data
    //   })
    // },
    // 点击开启按钮
    open() {
	  //console.log("动画开启 "+this.showAnimations)
		// 判断用户是否登录
		if (!sessionStorage.getItem('n7_token')) {
			 this.$store.dispatch('setLogSignRetrieve', true)
			 return
		}
		/* clearInterval(this.timer)
		return */
      open_box({
        box_id: this.$route.query.id,
        total: this.count
      }).then((res) => {
        // console.log(res.data);
        if (res.status === 1) {
					this.$store.dispatch('getPlayerApi')
			    this.resultList = res.data
          if (this.showAnimations) {
            //动画开启
            this.Dijah = true
            bus.$emit('colseInterval')
           // this.current.play()
            if (this.resultList.length > 1) {
              this.$refs.portrait.Arraysplicing(this.resultList, 'portrait')
            } else {
              this.$refs.transverse.Arraysplicing(this.resultList, 'transverse')
            }
          } else {
            //动画关闭
			      this.show_audio.play()
            this.$nextTick(() => {
              this.show_prize = {
                show: true,
                list: this.resultList
              }
          	this.AnimationsPopup = true
            })
          }
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 数量设置
    countImg(count) {
      this.count = count
    },
    // 声音开关
    showSound() {
      this.showSounds=!this.showSounds
    },
    // 动画开关
    showAnimation(status) {
      if (this.showAnimations) {
        this.showAnimations = false
			if(!status){
				this.end()
			}

      } else {
        this.showAnimations = true
      }
    },
	//子组件动画结束
	end() {
		this.show_audio.play()
	  //this.show_audio.play()
	  //this.showAnimations = false
	  this.AnimationsPopup = true
	  //this.Dijah = false //电池光效复原
	  this.show_prize = {
	    show: true,
	    list: this.prize_data.list
	  }
	  this.prize_data = {
	    show: '',
	    list: []
	  }
            bus.$emit('openInterval')

	},
	finishCallback(data, str) {
	  //console.log('data, str: ', data, str)
	  setTimeout(() => {
	    this.$nextTick(() => {
	      this.prize_data = {
	        show: str,
	        list: data
	      }
	    })
	  }, 550)
	},
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/BoxShowBody.scss';
.introduced {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 90;
  transition: all 0.15s linear;
}
.BoxShowBodyOpenTake {
  position: fixed;
  z-index: 91;
  // background-color: aqua;
}
</style>
